import React, { useState, useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../component/navbar.tsx';
import { Box, Button, Typography, Paper } from '@mui/material';
import Spinner from '../component/spinner.tsx';

function FileUploadPage() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null); // Ajout d'une référence au champ input
  const apiUrl = process.env.REACT_APP_API_URL;

  

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadStatus('');
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setUploadStatus('Veuillez sélectionner un fichier.');
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/csv/post`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        setUploadStatus('Le fichier a été téléversé et traité avec succès.');
      } else {
        setUploadStatus('Erreur lors du téléversement du fichier.');
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi du fichier:', error);
      setUploadStatus('Erreur lors du téléversement du fichier.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }
  }, [navigate]);



  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setSelectedFile(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
    }
  };


  return (
    <div>
      <Navbar />
      {isLoading && <Spinner />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2,
          backgroundColor: '#f4f6f8',
          filter: isLoading ? 'blur(3px)' : 'none',
          marginTop: '20px', // Ajustement pour la marge supérieure
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ color: '#106c18' }}>
          Téléversement de fichier CSV
        </Typography>
        <Paper
           variant="outlined"
           sx={{
             p: 2,
             width: '100%',
             maxWidth: 500,
             textAlign: 'center',
             cursor: 'pointer',
             backgroundColor: '#ffffff',
             borderStyle: 'dashed',
             borderColor: '#106c18',
             borderWidth: '2px',
             '&:hover': {
               backgroundColor: '#7cbf83',
             },
           }}
           onDragOver={(event) => event.preventDefault()} // Empêcher le comportement par défaut
           onDrop={handleDrop} // Gérer le dépôt de fichier
         >
           {selectedFile ? (
             <>
               <img src="/CSV_Logo.png" alt="CSV Logo" style={{ width: 50, height: 50 }} />
               <Typography>
                 Fichier sélectionné : {selectedFile.name}
               </Typography>
             </>
           ) : (
             <Typography>
               Glissez et déposez votre fichier ici ou cliquez pour sélectionner un fichier
             </Typography>
           )}
           <input
             type="file"
             accept=".csv"
             onChange={handleFileChange}
             style={{ display: 'none' }}
             ref={fileInputRef}
             id="contained-button-file"
           />
           <label htmlFor="contained-button-file">
             <Button variant="contained" component="span" sx={{ mt: 2, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#43a047' } }} >
               Choisissez un fichier
             </Button>
           </label>
         </Paper>
         <Button
           variant="contained"
           disabled={isLoading} 
           onClick={handleFileUpload}
           sx={{
             mt: 2,
             backgroundColor: '#4caf50',
             '&:hover': {
               backgroundColor: '#43a047',
             },
           }}
         >
           Envoyer le fichier
         </Button>
         {uploadStatus && (
           <Typography color="success.main" sx={{ mt: 2 }}>
             {uploadStatus}
           </Typography>
         )}
       </Box>
     </div>
   );
 }

export default FileUploadPage;
