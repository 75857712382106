import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, CssBaseline, Box, Typography, TextField, Button, InputAdornment, IconButton, Snackbar } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ResetPassword() {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const [passwordError, setPasswordError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;


    const handleResetPassword = async (e) => {
        e.preventDefault(); // Prevent form from refreshing the page on submit
        let hasError = false
        if (!newPassword) {
            setPasswordError('Veuillez entrer un mot de passe.');
            hasError = true;
        } else if (newPassword.length < 8) {
            setPasswordError('Le mot de passe doit contenir au moins 8 caractères.');
            hasError = true;
            // Validation du mot de passe avec au moins un chiffre, une lettre en majuscule, une lettre en minuscule et un caractère spécial
        } else if (!/^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,}$/.test(newPassword)) {
            setPasswordError('Le mot de passe doit contenir au moins un chiffre, une lettre en majuscule, une lettre en minuscule et un caractère spécial.');
            hasError = true;
        }
        else {
            setPasswordError('');
        }
        if (hasError) {
            return;
        }
        try {
            const response = await fetch(`${apiUrl}/user/resetPassword/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password: newPassword }),
            });

            if (response.ok) {
                setOpenSnackbar(true); // Pour afficher le snackbar de succès
                setTimeout(() => navigate('/login'), 3000); // Rediriger après 3 secondes
            } else {
                alert('Le lien de réinitialisation est invalide ou a expiré.');
            }
        } catch (error) {
            console.error('Erreur lors de la réinitialisation du mot de passe:', error);
            setOpenErrorSnackbar(true); // Affiche la Snackbar d'échec
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        if (openSnackbar) {
            setOpenErrorSnackbar(false); // Ferme la Snackbar d'échec lorsque la Snackbar de succès s'ouvre
        }
    }, [openSnackbar]);


    return (
        <div style={{
            backgroundImage: 'url("/fond_mdp.jpeg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: 3,
                        borderRadius: 2,
                        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src="/logo.png" alt="Logo" style={{ width: '100px', height: 'auto' }} />
                    <Typography component="h1" variant="h5" sx={{ color: 'green', margin: '20px 0' }}>
                        Réinitialiser le mot de passe
                    </Typography>
                    <Box component="form" onSubmit={handleResetPassword} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="newPassword"
                            label="Nouveau mot de passe"
                            type={showPassword ? 'text' : 'password'}
                            id="new-password"
                            autoComplete="new-password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            error={!!passwordError}
                            helperText={passwordError}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ bgcolor: 'white', borderRadius: 1 }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, bgcolor: 'green', '&:hover': { bgcolor: 'darkgreen' } }}
                        >
                            Réinitialiser le mot de passe
                        </Button>
                        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                            <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                                Mot de passe réinitialisé avec succès.
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                            <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error" sx={{ width: '100%' }}>
                                Échec dans la réinitialisation du mot de passe.
                            </Alert>
                        </Snackbar>
                    </Box>
                </Box>
            </Container>
        </div>
    );
}

export default ResetPassword;
