import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';

function BottomPageCSP() {
  const [inputValue, setInputValue] = useState('');
  const [rows, setRows] = useState(4);

  useEffect(() => {
    // Texte défini en dur
    const hardcodedText = "** Se référer au guide des bonnes pratiques d'utilisation des produits phytopharmaceutiques remis lors de la signature de la convention.";

    setInputValue(hardcodedText);

    // Ajuster le nombre de lignes en fonction de la longueur du texte
    const lineCount = hardcodedText?.split('\n').length;
    setRows(Math.max(4, lineCount));
  }, []);

  return (
    <div style={{ margin: '10px 0', width: '100%' }}>
      <TextField
        multiline
        fullWidth
        variant="outlined"
        value={inputValue}
        rows={rows}
        style={{ width: '100%' }}
        InputProps={{
          readOnly: true,
        }}
      />
    </div>
  );
}

export default BottomPageCSP;
