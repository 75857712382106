import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';

function BottomPage() {
  const [inputValue, setInputValue] = useState('');
  const [rows, setRows] = useState(4);

  useEffect(() => {
    // Texte défini en dur
    const hardcodedText = "* Avant toute utilisation de produits phytopharmaceutiques, se référer à l'étiquette du produits, se protéger selon les recommandations de celle-ci. Toute utilisation de produits phyto pharmaceutiques doit se faire dans le respect de la règlmentation en vigueur.";

    setInputValue(hardcodedText);

    // Ajuster le nombre de lignes en fonction de la longueur du texte
    const lineCount = hardcodedText?.split('\n').length;
    setRows(Math.max(4, lineCount));
  }, []);

  return (
    <div style={{ margin: '10px 0', width: '100%' }}>
      <TextField
        multiline
        fullWidth
        variant="outlined"
        value={inputValue}
        rows={rows}
        style={{ width: '100%' }}
        InputProps={{
          readOnly: true,
        }}
      />
    </div>
  );
}

export default BottomPage;
