import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Navbar from "../component/navbar.tsx";
import '../App.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


export default function CstAdmin() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [searchByPhone, setSearchByPhone] = useState('');
  const [searchByEmail, setSearchByEmail] = useState('');
  const [searchByAddress, setSearchByAddress] = useState('');
  const [searchByPostalCode, setSearchByPostalCode] = useState('');
  const [searchByRaisonSociale, setSearchByRaisonSociale] = useState('');
  const [searchByVille, setSearchByVille] = useState('');
  const [filterActif, setFilterActif] = useState('tous');
  const [allUsers, setAllUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [strategicDetails, setStrategicDetails] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    fetch(`${apiUrl}/user/users`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        // Filtrez les utilisateurs ayant conseilStrategique à true
        const filteredUsers = data.filter(user => user.conseilStrategique);
        setUsers(filteredUsers);
        setAllUsers(filteredUsers);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des utilisateurs:', error);
      });
  }, [navigate]);

  const handleUserClick = (event, user) => {
    setSelectedUser(user);
    setAnchorEl(event.currentTarget);
    fetch(`${apiUrl}/strategicDate/${user.idUser}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
    })
      .then(response => response.json())
      .then(data => {
        setStrategicDetails(prevState => ({ ...prevState, [user.idUser]: data }));
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données de conseil:', error);
      });
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewStrategic = () => {
    const existingIdCst = strategicDetails[selectedUser?.idUser]?.map(strategic => strategic.idCst) ?? [];
    const lastIdCst = existingIdCst.length > 0 ? Math.max(...existingIdCst) : 0;
    const newIdCst = lastIdCst > 0 ? lastIdCst + 1 : 1;
    navigate(`/${selectedUser.idUser}/rapport/${newIdCst}`);
    handleClose();
  };


  const handleOldStrategic = (idCst) => {
    navigate(`/${selectedUser.idUser}/rapport/${idCst}`);
    handleClose();
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Non définie";
    const [year, month, day] = dateString.split('-');
    if (day === undefined || month === undefined) {
      return `${year}`
    }
    return `${day}/${month}/${year}`;
  };

  const filteredStrategic = strategicDetails[selectedUser?.idUser]?.filter(strategic => {
    const strategicDateFormatted = formatDate(strategic.strategicDateOnly);
    return strategic.idCst.toString().startsWith(searchQuery) || strategicDateFormatted.startsWith(searchQuery);
  });

  useEffect(() => {
    const filteredUsers = allUsers.filter(user => {
      const filters = [
        searchByPhone, searchByEmail, searchByAddress,
        searchByPostalCode, searchByRaisonSociale, searchByVille
      ];
      const activeFilters = filters.filter(filter => filter !== '');
      const isActive = filterActif === 'actif' ? user.active : true;
      const isInactive = filterActif === 'inactif' ? !user.active : true;
      const isAll = filterActif === 'tous';
      if (activeFilters.length === 0 && isAll) {
        return true;
      }
      const filterByPhone = searchByPhone === '' || user.telephone.toLowerCase().includes(searchByPhone.toLowerCase());
      const filterByEmail = searchByEmail === '' || user.email.toLowerCase().includes(searchByEmail.toLowerCase());
      const filterByAddress = searchByAddress === '' || user.adresse.toLowerCase().includes(searchByAddress.toLowerCase());
      const filterByPostalCode = searchByPostalCode === '' || user.postalCode.toLowerCase().includes(searchByPostalCode.toLowerCase());
      const filterByRaisonSociale = searchByRaisonSociale === '' || user.raisonSociale.toLowerCase().includes(searchByRaisonSociale.toLowerCase());
      const filterByVille = searchByVille === '' || user.ville.toLowerCase().includes(searchByVille.toLowerCase());
      return (isActive || isInactive) && filterByPhone && filterByEmail && filterByAddress && filterByPostalCode && filterByRaisonSociale && filterByVille;
    });

    setUsers(filteredUsers);
  }, [searchByPhone, searchByEmail, searchByAddress, searchByPostalCode, searchByRaisonSociale, searchByVille, filterActif, allUsers]);

  return (
    <>
      <Navbar />
      <div style={{
        backgroundImage: 'url("/fond_login.jpeg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh', // S'assurer que le fond couvre toute la hauteur de la vue
        paddingTop: '64px', // Ajuster selon la hauteur de votre Navbar pour que le contenu commence en dessous
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column', // Organise le contenu en colonne
          alignItems: 'center', // Centre le contenu horizontalement
          justifyContent: 'flex-start', // Commence le contenu en haut du conteneur
          minHeight: 'calc(100vh - 64px)', // Ajuster la hauteur en fonction de la Navbar
          width: '100%', // Utiliser toute la largeur disponible
          maxWidth: '1200px', // Ajuster la largeur maximale selon vos besoins
          margin: '0 auto', // Centrer le conteneur dans la page
          overflowY: 'auto', // Permettre le défilement vertical si nécessaire
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // Appliquer un fond blanc semi-transparent
          padding: '20px', // Ajouter un espace à l'intérieur du conteneur
          borderRadius: '10px', // Arrondir les coins du conteneur
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Ajouter une ombre pour le relief
        }}>
          <div className="user-list-container" style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around', // Assure une répartition égale de l'espace autour des éléments
            gap: '20px', // Ajoute un espace entre les éléments
          }}>
            <Typography variant="h4" style={{ textAlign: 'center' }}>
              Liste des utilisateurs - Conseil stratégique
            </Typography>
            <div className="filters-container">
              <input
                type="text"
                value={searchByRaisonSociale}
                onChange={(e) => setSearchByRaisonSociale(e.target.value)}
                placeholder="Raison sociale"
              />
              <input
                type="text"
                value={searchByEmail}
                onChange={(e) => setSearchByEmail(e.target.value)}
                placeholder="Email"
              />
              <input
                type="text"
                value={searchByPhone}
                onChange={(e) => setSearchByPhone(e.target.value)}
                placeholder="Téléphone"
              />
              <input
                type="text"
                value={searchByAddress}
                onChange={(e) => setSearchByAddress(e.target.value)}
                placeholder="Adresse"
              />
              <input
                type="text"
                value={searchByVille}
                onChange={(e) => setSearchByVille(e.target.value)}
                placeholder="Ville"
              />
            </div>
            <div className="user-cards-container" style={{
              display: 'flex',
              flexDirection: 'row', // Gardez vos éléments en ligne autant que possible
              flexWrap: 'wrap', // Permettre l'enroulement pour plusieurs utilisateurs
              justifyContent: users.length === 1 ? 'center' : 'space-around', // Centrer la carte si un seul utilisateur
              width: '100%', // Utiliser toute la largeur disponible pour centrer avec justifyContent
            }}>
              {users.map((user, index) => (
                <div key={index} className="user-card" style={{
                  width: 'calc(50% - 10px)', // Prend la moitié de l'espace disponible, en soustrayant la marge
                  backgroundColor: 'white', // Fond blanc pour les cartes
                  padding: '10px', // Espace intérieur pour le contenu de la carte
                  borderRadius: '5px', // Bords arrondis
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Ombre douce pour un effet de profondeur
                }}>
                  <h3 className="user-name">
                    <button className="user-name-button" onClick={(e) => handleUserClick(e, user)}>
                      {user.raisonSociale}
                    </button>
                  </h3>
                  <p className="user-email">Email : {user.email}</p>
                  <p className="user-phone">Téléphone : {user.telephone}</p>
                  <p className="user-address">Adresse : {user.adresse}</p>
                  <p className="user-city">Ville : {user.ville}</p>
                </div>
              ))}
            </div>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem sx={{ py: 0.5 }}> {/* Ajuster l'espacement vertical du MenuItem */}
              <TextField
                fullWidth
                label="Rechercher par numéro ou date" // Mettre à jour le libellé
                variant="outlined"
                size="small" // Utiliser la taille "small" pour le TextField
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    fontSize: '0.875rem', // Réduire la taille de la police
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: '0.875rem', // Réduire la taille de la police du label
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: '1rem', // Réduire la taille des icônes
                  },
                  mb: 1, // Ajuster la marge basse pour séparer du bouton
                }}
              />
            </MenuItem>
            <MenuItem onClick={handleNewStrategic} sx={{ my: 0.5, justifyContent: 'center' }}> {/* Réduire l'espacement vertical pour le bouton */}
              <Button
                sx={{
                  fontSize: '0.875rem',
                  padding: '4px 10px', // Réduire le padding pour diminuer la hauteur du bouton
                  lineHeight: 1, // Ajuster la hauteur de ligne si nécessaire
                  textTransform: 'none', // Optionnel, pour enlever la transformation en majuscules
                }}
              >
                + Créer Nouvelle Fiche
              </Button>
            </MenuItem>
            {filteredStrategic
              ?.sort((a, b) => b.idCst - a.idCst)
              .map((strategic, index) => (
                <MenuItem key={index} onClick={() => handleOldStrategic(strategic.idCst)} sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.875rem', py: 0.5 }}>
                  {`Fiche ${strategic.idCst} - ${formatDate(strategic.strategicDateOnly)}`}
                </MenuItem>
              ))}

          </Menu>
        </div>
      </div>
    </>
  );
}