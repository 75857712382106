import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';

function Commentary({ onDataChange, commentaryData, title, placeholder, titleStyle, editableAdmin = false }) {
  const [inputValue, setInputValue] = useState('');
  const [rows, setRows] = useState(4);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const tokenBody = token.split('.')[1];
      if (tokenBody) {
        const decodedToken = JSON.parse(atob(tokenBody));
        setIsAdmin(decodedToken.admin);
      }
    }

    let commentaryStr = typeof commentaryData === 'string' ? commentaryData : JSON.stringify(commentaryData, null, 2);
    setInputValue(commentaryStr);

    const lineCount = commentaryStr?.split('\n').length;
    setRows(Math.max(4, lineCount || 0));
  }, [commentaryData]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    onDataChange(e.target.value);

    const lineCount = e.target.value?.split('\n').length;
    setRows(Math.max(4, lineCount || 0));
  };

  // Déterminez si le champ doit être éditable
  const isFieldEditable = !editableAdmin || isAdmin;

  return (
    <div style={{ margin: '10px 0', width: '100%' }}>
      {title && <h2 style={{ ...titleStyle, marginBottom: '10px', fontSize: '1.2em' }}>{title}</h2>}
      <TextField
        multiline
        fullWidth
        variant="outlined"
        placeholder={placeholder || 'Entrez du texte ici'}
        value={inputValue}
        onChange={handleInputChange}
        minRows={rows}
        style={{ width: '100%' }}
        InputProps={{
          readOnly: !isFieldEditable, // Rend le champ en lecture seule si l'utilisateur n'est pas autorisé à éditer
        }}
      />
    </div>
  );
}

export default Commentary;
