import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Box } from '@mui/material';

const TabBar = ({ tabs }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Cette hook permet de savoir quel chemin est actuellement actif
  const [selectedIndex, setSelectedIndex] = useState(tabs.findIndex(tab => tab.path === location.pathname));

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      p: 2,
      gap: '10px', // espace entre les boutons
      borderBottom: '1px solid #e0e0e0', // ligne en dessous de la barre de navigation
    }}>
      {tabs.map((tab, index) => (
        <Button
          key={tab.path}
          onClick={() => {
            navigate(tab.path);
            setSelectedIndex(index);
          }}
          sx={{
            color: selectedIndex === index ? 'red' : 'black', // Vert sapin foncé pour l'onglet actif
            fontWeight: 'normal',
            fontSize: '0.875rem',
            textTransform: 'none', // Retire la transformation en majuscules
            bgcolor: 'transparent', // Fond transparent
            '&:hover': {
              bgcolor: 'grey.200', // Gris au survol
            },
            '&:not(:last-of-type)': {
              borderRight: '1px solid #e0e0e0', // Séparateur entre les boutons
            }
          }}
        >
          {tab.label}
        </Button>
      ))}
    </Box>
  );
};

export default TabBar;
