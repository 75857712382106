import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import DynamicTable from "../component/dynamicTable.tsx";
import Navbar from "../component/navbar.tsx";
import { Buffer } from "buffer";
import Header from "../component/header.tsx";
import ArrowBack from "../component/flecheBack.tsx";
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import Commentary from "../component/commentaire.tsx";
import BottomPage from "../component/bottomPageCSP.tsx";
import PageTitle from "../component/pageTitle.tsx";
import '../App.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Title from "../component/title.tsx";
import BottomPageCSP from "../component/bottomPageCSP2.tsx";


export default function ConseilSpecifique() {
  const [isPrinting, setIsPrinting] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [adviceDetails, setAdviceDetails] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);
  const [adviceDate, setAdviceDate] = useState("");
  const [adviceDateFormated, setAdviceDateFormated] = useState(adviceDate);
  const [dataTabComments, setDataTabComments] = useState([{
    col_1: { type: "dropdown", value: "", options: ["Maladies", "Ravageurs", "Adventices", "Opérations culturales"] },
    col_2: "",
    col_3: "",
    col_4: ""
  }]);
  const [dataTabDiagnosis, setDataTabDiagnosis] = useState([{ col_1: "Culture", col_2: "" }, { col_1: "Variétés", col_2: "" }, { col_1: "Parcelles", col_2: "" }, { col_1: "Précédents culturaux", col_2: "" }]);
  const [dataTabFarmingId, setDataTabFarmingId] = useState([]);
  const [dataTabRecommendation, setDataTabRecommendation] = useState([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "" }]);
  const [commentaryPlotSpecificity, setPlotSpecificity] = useState("Spécificités Parcelles (Cours d'eau, Zone riverain, Charte, haies etc…)");
  const [commentaryAlternative, setAlternative] = useState("Méthodes alternatives disponibles/Moyens de lutte intégrée : ");
  const [commentaryCepp, setCepp] = useState("CEPP Compatibles : ");
  const [commentaryRelated, setRelated] = useState("Documents Associés: (BSV,OAD, Conseil spé précédents, Conseil Stra etc...)");
  const [commentaryRemarks, setRemarks] = useState("Autres remarques : ");
  const [commentarySpecifications, setSpecifications] = useState("Cahier des charges/Référentiels culture : ");
  const token = localStorage.getItem('token');
  const { id, idCsp } = useParams();
  const [needsSaveRecommendation, setNeedsSaveRecommendation] = useState(false);
  const [needsSaveComments, setNeedsSaveComments] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;


  let idUser = null;
  let isAdmin = false;

  if (token) {
    const tokenBody = token.split('.')[1];
    if (tokenBody) {
      const decodedToken = JSON.parse(Buffer.from(tokenBody, 'base64').toString());
      idUser = decodedToken.id;
      isAdmin = decodedToken.admin;
    }
  }

  useEffect(() => {


    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }
  }, [navigate]);

  const formatDate = (dateString) => {
    if (!dateString) return "Non définie";
    const [year, month, day] = dateString.split('-');
    if (day === undefined || month === undefined) {
      return `${year}`
    }
    return `${day}/${month}/${year}`;
  };

  // ------------------------ START POST ARRAY -----------------------------
  const saveDataToAdviceDate = async () => {
    if (!isAdmin) {
      return;
    }
    try {
      const userIdToUse = id || idUser;
      const formattedDate = adviceDate && adviceDate !== "" ? formatToYYYYMMDD(adviceDate) : null;
      const bodyData = {
        idUser: userIdToUse,
        idCsp: idCsp,
        adviceDateOnly: formattedDate
      };
      const response = await fetch(`${apiUrl}/adviceDate/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(bodyData)
      });

      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };



  const saveDataToComments = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/comments/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCsp: idCsp })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToDiagnosis = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/diagnosis/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCsp: idCsp })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToFarmingId = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/farmingID/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCsp: idCsp })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToRecommendation = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/recommendation/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCsp: idCsp })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const savePlotSpecificity = async (newCommentaryData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/plotSpecificity/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, commentaryData: newCommentaryData, idCsp: idCsp })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du commentaire');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveAlternative = async (newCommentaryData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/alternative/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, commentaryData: newCommentaryData, idCsp: idCsp })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du commentaire');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveCepp = async (newCommentaryData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/cepp/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, commentaryData: newCommentaryData, idCsp: idCsp })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du commentaire');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveRelated = async (newCommentaryData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/related/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, commentaryData: newCommentaryData, idCsp: idCsp })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du commentaire');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveRemarks = async (newCommentaryData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/remarks/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, commentaryData: newCommentaryData, idCsp: idCsp })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du commentaire');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveSpecifications = async (newCommentaryData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/specifications/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, commentaryData: newCommentaryData, idCsp: idCsp })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du commentaire');
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (needsSaveComments) {
      // Procéder à la sauvegarde des données
      saveDataToComments(dataTabComments)
        .then(() => setNeedsSaveComments(false)) // Réinitialiser l'indicateur après la sauvegarde
        .catch(error => {
          console.error(error);
          // Gérer l'erreur de sauvegarde si nécessaire
        });
    }
  }, [needsSaveComments]);

  useEffect(() => {
    if (needsSaveRecommendation) {
      // Procéder à la sauvegarde des données
      saveDataToRecommendation(dataTabRecommendation)
        .then(() => setNeedsSaveRecommendation(false)) // Réinitialiser l'indicateur après la sauvegarde
        .catch(error => {
          console.error(error);
          // Gérer l'erreur de sauvegarde si nécessaire
        });
    }
  }, [needsSaveRecommendation]);

  // ----------------- HANDLE CHANGE FOR ARRAY -----------------------------

  const handleDataChangeComments = useCallback((newData) => {
    if (!isEqual(dataTabComments, newData)) {
      setDataTabComments(newData);
      saveDataToComments(newData);
    }
  }, [dataTabComments, saveDataToComments]);


  const handleDataChangeDiagnosis = useCallback((newData) => {
    if (!isEqual(dataTabDiagnosis, newData)) {
      setDataTabDiagnosis(newData);
      saveDataToDiagnosis(newData);
    }
  }, [dataTabDiagnosis, saveDataToDiagnosis]);

  const handleDataChangeFarmingId = useCallback((newData) => {
    if (!isEqual(dataTabFarmingId, newData)) {
      setDataTabFarmingId(newData);
      saveDataToFarmingId(newData);
    }
  }, [dataTabFarmingId, saveDataToFarmingId]);

  const handleDataChangeRecommendation = useCallback((newData) => {
    if (!isEqual(dataTabRecommendation, newData)) {
      setDataTabRecommendation(newData);
      saveDataToRecommendation(newData);
    }
  }, [dataTabRecommendation, saveDataToRecommendation]);

  const handleDataChangePlotSpecificity = useCallback((newCommentaryData) => {
    if (!isEqual(commentaryPlotSpecificity, newCommentaryData)) {
      setPlotSpecificity(newCommentaryData);
      savePlotSpecificity(newCommentaryData);
    }
  }, [commentaryPlotSpecificity, savePlotSpecificity]);

  const handleDataChangeAlternative = useCallback((newCommentaryData) => {
    if (!isEqual(commentaryAlternative, newCommentaryData)) {
      setAlternative(newCommentaryData);
      saveAlternative(newCommentaryData);
    }
  }, [commentaryAlternative, saveAlternative]);

  const handleDataChangeCepp = useCallback((newCommentaryData) => {
    if (!isEqual(commentaryCepp, newCommentaryData)) {
      setCepp(newCommentaryData);
      saveCepp(newCommentaryData);
    }
  }, [commentaryCepp, saveCepp]);

  const handleDataChangeRelated = useCallback((newCommentaryData) => {
    if (!isEqual(commentaryRelated, newCommentaryData)) {
      setRelated(newCommentaryData);
      saveRelated(newCommentaryData);
    }
  }, [commentaryRelated, saveRelated]);

  const handleDataChangeRemarks = useCallback((newCommentaryData) => {
    if (!isEqual(commentaryRemarks, newCommentaryData)) {
      setRemarks(newCommentaryData);
      saveRemarks(newCommentaryData);
    }
  }, [commentaryRemarks, saveRemarks]);

  const handleDataChangeSpecifications = useCallback((newCommentaryData) => {
    if (!isEqual(commentarySpecifications, newCommentaryData)) {
      setSpecifications(newCommentaryData);
      saveSpecifications(newCommentaryData);
    }
  }, [commentarySpecifications, saveSpecifications]);

  const handleAdviceDateChange = (newDate) => {
    const formattedDate = formatToYYYYMMDD(newDate);
    setAdviceDate(formattedDate);
    setAdviceDateFormated(newDate);
  };

  const handleUserClick = (event, user) => {
    setSelectedUser(user);
    setAnchorEl(event.currentTarget);
    fetch(`${apiUrl}/adviceDate/${user.idUser}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
    })
      .then(response => response.json())
      .then(data => {
        setAdviceDetails(prevState => ({ ...prevState, [user.idUser]: data }));
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données de conseil:', error);
      });
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewAdvice = () => {
    const today = new Date().toISOString().split('T')[0];
    const lastIdCsp = Math.max(...adviceDetails[selectedUser?.idUser]?.map(advice => advice.idCsp) ?? [0]);
    const newIdCsp = lastIdCsp + 1;
    navigate(`/${selectedUser.idUser}/conseilSpecifique/${newIdCsp}`);
    //setAdviceDate(new Date().toISOString().split('T')[0]); 
    setAdviceDate(today);
    setAdviceDateFormated(today);
    saveDataToAdviceDate();
    setDataTabComments([{
      col_1: { type: "dropdown", value: "", options: ["Maladies", "Ravageurs", "Adventices", "Opérations culturales"] },
      col_2: "",
      col_3: "",
      col_4: ""
    }]);
    setDataTabDiagnosis([{ col_1: "Culture", col_2: "" }, { col_1: "Variétés", col_2: "" }, { col_1: "Parcelles", col_2: "" }, { col_1: "Précédents culturaux", col_2: "" }]);
    setDataTabRecommendation([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "" }]);
    setPlotSpecificity("Spécificités Parcelles (Cours d'eau, Zone riverain, Charte, haies etc…)");
    setAlternative("Méthodes alternatives disponibles/Moyens de lutte intégrée : ");
    setCepp("CEPP Compatibles : ");
    setRelated("Documents Associés: (BSV,OAD, Conseil spé précédents, Conseil Stra etc...)");
    setRemarks("Autres remarques : ");
    setSpecifications("Cahier des charges/Référentiels culture : ");
    setAdviceDetails({});
    handleClose();
  };


  const handleOldAdvice = (idCspBis) => {
    var isTrue = false;
    if (isEqual(idCspBis, idCsp)) {
      isTrue = true;
    }
    navigate(`/${selectedUser.idUser}/conseilSpecifique/${idCspBis}`);
    if (isTrue) {
      setAdviceDate("");
      setAdviceDateFormated("");
      setDataTabComments([{
        col_1: { type: "dropdown", value: "", options: ["Maladies", "Ravageurs", "Adventices", "Opérations culturales"] },
        col_2: "",
        col_3: "",
        col_4: ""
      }]);
      setDataTabDiagnosis([{ col_1: "Culture", col_2: "" }, { col_1: "Variétés", col_2: "" }, { col_1: "Parcelles", col_2: "" }, { col_1: "Précédents culturaux", col_2: "" }]);
      setDataTabRecommendation([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "" }]);
      setPlotSpecificity("Spécificités Parcelles (Cours d'eau, Zone riverain, Charte, haies etc…)");
      setAlternative("Méthodes alternatives disponibles/Moyens de lutte intégrée : ");
      setCepp("CEPP Compatibles : ");
      setRelated("Documents Associés: (BSV,OAD, Conseil spé précédents, Conseil Stra etc...)");
      setRemarks("Autres remarques : ");
      setSpecifications("Cahier des charges/Référentiels culture : ");
      saveDataToAdviceDate();
    }
    handleClose();
  };

  const filteredAdvices = adviceDetails[selectedUser?.idUser]?.filter(advice => {
    const adviceDateFormatted = advice.adviceDateOnly ? formatDate(advice.adviceDateOnly) : "Date à renseigner";
    return searchQuery === "" || advice.idCsp.toString().startsWith(searchQuery) || adviceDateFormatted.startsWith(searchQuery);
  });


  useEffect(() => {
    if (adviceDate) {
      saveDataToAdviceDate();
    }
  }, [adviceDate]);

  const formatToYYYYMMDD = (dateString) => {
    if (!dateString) return "";

    // Parse the date string to avoid timezone issues
    const [year, month, day] = dateString.split('T')[0].split('-');

    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };



  const addNewCommentsRow = () => {
    const newRow = {
      col_1: { type: "dropdown", value: "", options: ["Maladies", "Ravageurs", "Adventices", "Opérations culturales"] },
      col_2: "",
      col_3: "",
      col_4: "",
    };

    // Assurez-vous que dataTabComments est un tableau avant de tenter de l'ajouter avec newRow
    if (!Array.isArray(dataTabComments)) {
      setDataTabComments([newRow, newRow]);
      setNeedsSaveComments(true);
    } else {
      setDataTabComments([...dataTabComments, newRow]);
      setNeedsSaveComments(true);
    }
  };

  const addNewRecommendationRow = () => {
    const newRow = {
      col_1: "",
      col_2: "",
      col_3: "",
      col_4: "",
      col_5: "",
      col_6: "",
      col_7: "",
      col_8: ""
    };
    if (!Array.isArray(dataTabRecommendation)) {
      setDataTabRecommendation([newRow, newRow]);
      setNeedsSaveRecommendation(true);
    } else {
      setDataTabRecommendation([...dataTabRecommendation, newRow]);
      setNeedsSaveRecommendation(true);
    }

  };




  useEffect(() => {

    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }
  }, [navigate]);


  // ----------------- START GET ---------------------

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${apiUrl}/user/${userIdToUse}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données utilisateur');
        }

        const userData = await response.json();

        // Mappage des données utilisateur sur dataTabFarmingAll
        const newDataTabFarmingId = [
          { col_1: "Raison Sociale : ", col_2: userData.raisonSociale },
          { col_1: "Nom, Prénom : ", col_2: `${userData.firstName} ${userData.lastName}` },
          { col_1: "Adresse : ", col_2: userData.adresse },
          { col_1: "Code Postal : ", col_2: userData.postalCode },
          { col_1: "Email : ", col_2: userData.email },
          { col_1: "Téléphone : ", col_2: userData.telephone },
          { col_1: "Ville : ", col_2: userData.ville },
        ];

        setDataTabFarmingId(newDataTabFarmingId);

      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, [id, idUser]);

  useEffect(() => {

    const userIdToUse = id || idUser;
    const fetchAdviceDate = async () => {
      try {
        const response = await fetch(`${apiUrl}/adviceDate/${userIdToUse}/${idCsp}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setAdviceDate(data.adviceDateOnly || new Date().toISOString().split('T')[0]);
        setAdviceDateFormated(data.adviceDateOnly || new Date().toISOString().split('T')[0]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAdviceDate();
  }, [token, idUser, id, idCsp]);


  useEffect(() => {

    const userIdToUse = id || idUser;
    const fetchComments = async () => {
      try {
        const response = await fetch(`${apiUrl}/comments/${userIdToUse}/${idCsp}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabComments(data.dataTab || []);
        if (!isEqual(dataTabComments, data)) {
          setDataTabComments(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchComments();
  }, [token, idUser, id, idCsp]);

  // ----------------------------------------

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchDiagnosis = async () => {
      try {
        const response = await fetch(`${apiUrl}/diagnosis/${userIdToUse}/${idCsp}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabDiagnosis(data.dataTab || []);
        if (!isEqual(dataTabDiagnosis, data)) {
          setDataTabDiagnosis(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchDiagnosis();
  }, [token, idUser, id, idCsp]);

  // ----------------------------------------

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchFarmingId = async () => {
      try {
        const response = await fetch(`${apiUrl}/farmingId/${userIdToUse}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabFarmingId(data.dataTab || []);
        if (!isEqual(dataTabFarmingId, data)) {
          setDataTabFarmingId(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchFarmingId();
  }, [token, idUser, id]);

  // ----------------------------------------

  useEffect(() => {

    const userIdToUse = id || idUser;
    const fetchRecommendation = async () => {
      try {
        const response = await fetch(`${apiUrl}/recommendation/${userIdToUse}/${idCsp}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();

        setDataTabRecommendation(data.dataTab || []);
        if (!isEqual(dataTabRecommendation, data)) {
          setDataTabRecommendation(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchRecommendation();
  }, [token, idUser, id, idCsp]);

  // -------------------- COMMENTARY --------------------

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchPlotSpecificity = async () => {
      try {
        const response = await fetch(`${apiUrl}/plotSpecificity/${userIdToUse}/${idCsp}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setPlotSpecificity(data.commentaryData || "");
        if (!isEqual(commentaryPlotSpecificity, data)) {
          setPlotSpecificity(data.commentaryData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchPlotSpecificity();
  }, [token, idUser, id, idCsp]);

  // ----------------------------------------

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchRemarks = async () => {
      try {
        const response = await fetch(`${apiUrl}/remarks/${userIdToUse}/${idCsp}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setRemarks(data.commentaryData || "");
        if (!isEqual(commentaryRemarks, data)) {
          setRemarks(data.commentaryData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchRemarks();
  }, [token, idUser, id, idCsp]);

  // ----------------------------------------

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchCepp = async () => {
      try {
        const response = await fetch(`${apiUrl}/cepp/${userIdToUse}/${idCsp}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setCepp(data.commentaryData || "");
        if (!isEqual(commentaryCepp, data)) {
          setCepp(data.commentaryData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchCepp();
  }, [token, idUser, id, idCsp]);

  // ----------------------------------------

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchRelated = async () => {
      try {
        const response = await fetch(`${apiUrl}/related/${userIdToUse}/${idCsp}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setRelated(data.commentaryData || "");
        if (!isEqual(commentaryRelated, data)) {
          setRelated(data.commentaryData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchRelated();
  }, [token, idUser, id, idCsp]);

  // ----------------------------------------

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchSpecifications = async () => {
      try {
        const response = await fetch(`${apiUrl}/specifications/${userIdToUse}/${idCsp}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setSpecifications(data.commentaryData || "");
        if (!isEqual(commentarySpecifications, data)) {
          setSpecifications(data.commentaryData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchSpecifications();
  }, [token, idUser, id, idCsp]);

  // ----------------------------------------

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchAlternative = async () => {
      try {
        const response = await fetch(`${apiUrl}/alternative/${userIdToUse}/${idCsp}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setAlternative(data.commentaryData || "");
        if (isEqual(data, {})) {
          setAlternative("");
        }
        if (!isEqual(commentaryAlternative, data)) {
          setAlternative(data.commentaryData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchAlternative();
  }, [token, idUser, id, idCsp]);

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchUserById = async () => {
      try {
        const response = await fetch(`${apiUrl}/user/${userIdToUse}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setSelectedUser(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserById();
  }, [token, idUser, id]);


  // --------------------------- END GET ARRAY ------------------------------



  const handlePrint = () => {
    window.print(); // Déclenche l'impression
  };





  return (
    <>
      <style>
        {`
          @media print {
            .print-hide, .MuiButton-root, .user-name-button, .bouton-personnalise, .bouton-personnalise-2, .navbar {
              display: none !important; /* Cacher les boutons et autres éléments non désirés */
            }
          
            .dynamic-table, .dynamic-table-row {
              page-break-inside: avoid; /* Essayer d'éviter la coupure des tableaux */
            }

            .dynamic-table, .commentary {
              margin-bottom: 50px; /* Ajoutez plus d'espacement en bas de chaque tableau/commentaire */
            }

            .date-input-view {
              display: none; /* Masque l'input de date en mode impression */
            }
          
            .date-text-view {
              display: block !important; /* Affiche le texte de la date en mode impression */
              border: none; /* Supprime tout cadre qui pourrait être appliqué */
              background: transparent; /* Assure un fond transparent */
              padding: 0; /* Supprime le padding pour éviter tout espace inutile */
              margin: 0 auto; /* Centrage horizontal */
              font-size: 18px; /* Assure que la taille de la police est appropriée */
            }
          }
          
          /* Styles par défaut (pas en mode impression) */
          .date-input-view {
            display: block; /* Affiche l'input de date normalement */
          }
          
          .date-text-view {
            display: none; /* Masque le texte de la date normalement */
          }

          #tableauAdapte {
            font-size: 10px; /* Réduction de la taille de la police */
            transform: scale(0.8); /* Ajustement de la taille */
            transform-origin: top left; /* Origine de la transformation */
            /* Ajoutez d'autres styles d'adaptation ici */
          }
          
          }
        `}
      </style>

      <Navbar />
      <Header />
      <Button className="user-name-button"
        onClick={(e) => handleUserClick(e, selectedUser)}
        style={{
          float: 'right', marginRight: '40px', border: '1px solid #1E8449 ', padding: '5px 10px', backgroundColor: '#0E6655',
          color: 'white'
        }}>
        Sélectionner archive ▼
      </Button>
      <div style={{ position: 'absolute', top: 0, right: 0, marginTop: '64px', marginRight: '16px' }}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem sx={{ py: 0.5 }}> {/* Ajuster l'espacement vertical du MenuItem */}
            <TextField
              fullWidth
              label="Rechercher par numéro ou date" // Mettre à jour le libellé
              variant="outlined"
              size="small" // Utiliser la taille "small" pour le TextField
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-input": {
                  fontSize: '0.875rem', // Réduire la taille de la police
                },
                "& .MuiInputLabel-root": {
                  fontSize: '0.875rem', // Réduire la taille de la police du label
                },
                "& .MuiSvgIcon-root": {
                  fontSize: '1rem', // Réduire la taille des icônes
                },
                mb: 1, // Ajuster la marge basse pour séparer du bouton
              }}
            />
          </MenuItem>
          {isAdmin && <MenuItem onClick={handleNewAdvice} sx={{ my: 0.5, justifyContent: 'center' }}> {/* Réduire l'espacement vertical pour le bouton */}
            <Button
              sx={{
                fontSize: '0.875rem',
                padding: '4px 10px', // Réduire le padding pour diminuer la hauteur du bouton
                lineHeight: 1, // Ajuster la hauteur de ligne si nécessaire
                textTransform: 'none', // Optionnel, pour enlever la transformation en majuscules
              }}
            >
              + Créer Nouvelle Fiche
            </Button>
          </MenuItem>}
          {filteredAdvices
            ?.sort((a, b) => b.idCsp - a.idCsp)
            .map((advice, index) => (
              <MenuItem key={index} onClick={() => handleOldAdvice(advice.idCsp)} sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.875rem', py: 0.5 }}>
                {`Fiche ${advice.idCsp} - ${formatDate(advice.adviceDateOnly)}`}
              </MenuItem>
            ))}
        </Menu>
      </div>
      <div className="relative">
        <ArrowBack />
        <PageTitle title={`Fiche de préconisation - Conseil spécifique n°${idCsp}`} />
        <div style={{ textAlign: 'center', margin: '20px', fontSize: '20px' }}>
          <span>Date de préconisation : </span>
          {isAdmin ? (
            <input
              type="date"
              value={adviceDateFormated || ""}
              onChange={e => handleAdviceDateChange(e.target.value)}
              style={{ fontSize: '18px', padding: '5px', marginLeft: '10px' }}
            />
          ) : (
            <span>{formatDate(adviceDateFormated)}</span>
          )}
        </div>
        <Title text="Identification de l'exploitation agricole" />
        <DynamicTable onDataChange={handleDataChangeFarmingId} dataTab={dataTabFarmingId} columnNames={[]} title="" titleStyle={{ backgroundColor: '#ABA9AB', textAlign: 'center', padding: '10px' }} editableAdmin />
        <Title text="Diagnostic" />
        <DynamicTable onDataChange={handleDataChangeDiagnosis} dataTab={dataTabDiagnosis} columnNames={[]} title="" titleStyle={{ backgroundColor: '#ABA9AB', textAlign: 'center', padding: '10px' }} editableAdmin />
        <Commentary onDataChange={handleDataChangePlotSpecificity} commentaryData={commentaryPlotSpecificity} title="" placeholder={"Spécificités Parcelles (Cours d'eau, Zone riverain, Charte, haies etc…)"} titleStyle="" editableAdmin />
        <Commentary onDataChange={handleDataChangeSpecifications} commentaryData={commentarySpecifications} title="" titleStyle="" placeholder={"Cahier des charges/Référentiels culture : "} editableAdmin />
        <Title text="Observations" />
        <DynamicTable onDataChange={handleDataChangeComments} showDeleteRow={true} dataTab={dataTabComments} columnNames={["Maladies/Ravageurs/Adventices/Opérations culturales", "Dégats Observés", "Seuil de nuisibilité/infestation", "Commentaires"]} title="" titleStyle={{ backgroundColor: '#ABA9AB', textAlign: 'center', padding: '10px' }} editableAdmin name="comments"/>
        {isAdmin && <button
          onClick={addNewCommentsRow}
          className="bouton-personnalise"
        >
          Ajouter Ligne
        </button>}
        <Commentary onDataChange={handleDataChangeRelated} commentaryData={commentaryRelated} title="" titleStyle="" placeholder={"Documents Associés: (BSV,OAD, Conseil spé précédents, Conseil Stra etc...)"} editableAdmin />
        <Title text="Préconisation" />
        <Commentary onDataChange={handleDataChangeAlternative} commentaryData={commentaryAlternative} title="" placeholder="Méthodes alternatives disponibles/Moyens de lutte intégrée : " titleStyle={{ backgroundColor: '#ABA9AB', textAlign: 'center', padding: '10px' }} editableAdmin />
        <Commentary onDataChange={handleDataChangeCepp} commentaryData={commentaryCepp} title="" titleStyle="" placeholder={"CEPP Compatibles : "} editableAdmin />
        <DynamicTable onDataChange={handleDataChangeRecommendation} showDeleteRow={true} dataTab={dataTabRecommendation} columnNames={["Parcelles Consernées", "Culture concernée", "Opération Culturale", "Cible", "Spé. Commerciale", "SA", "Dose", "Conditions d'emploi particulières"]} title="" titleStyle={{ backgroundColor: '#ABA9AB', textAlign: 'center', padding: '10px' }} editableAdmin name="recommendation"/>
        {isAdmin && <button
          onClick={addNewRecommendationRow}
          className="bouton-personnalise"
        >
          Ajouter Ligne
        </button>}
        <Commentary onDataChange={handleDataChangeRemarks} commentaryData={commentaryRemarks} title="" titleStyle="" placeholder={"Autres remarques : "} editableAdmin />
        <BottomPage />
        <BottomPageCSP />
        <div className="container mx-auto relative z-10 print-hide">
        </div>
        <button
          onClick={handlePrint}
          className="bouton-personnalise-2"
        >
          Imprimer
        </button>
      </div>
    </>
  );
}