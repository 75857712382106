import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from "../component/dynamicTable.tsx";
import Navbar from "../component/navbar.tsx";
import Title from "../component/title.tsx";
import Header from "../component/header.tsx";
import isEqual from 'lodash/isEqual';
import PageTitle from "../component/pageTitle.tsx";
import TabBar from "../component/tapBar.tsx";

export default function Annexe() {
    const navigate = useNavigate();
    const { idAnnexe, id } = useParams<{ idAnnexe: string, id?: string }>();
    const [nextId, setNextId] = useState<number | null>(null);
    const [prevId, setPrevId] = useState<number | null>(null);
    const [dataTabIft, setDataTabIft] = useState([{ col_1: "IFT de Référence Disponible (cocher si oui) : ", col_2: { checked: false }, col_3: "IFT Ref : ", col_4: "" }, { col_1: "IFT Culture : ", col_2: "" }]);
    const [error, setError] = useState(null);
    const [dataTabBioControle, setDataTabBioControle] = useState([{ col_1: "Liste des Biocontrôle utilisés : ", col_2: "" }]);
    const [dataTabTreatment, setDataTabTreatment] = useState([{ col_1: "", col_2: "Nombre de traitement", col_3: "Nombre de substance active" },
    { col_1: "Insecticides", col_2: "", col_3: "" },
    { col_1: "Fongicides", col_2: "", col_3: "" },
    { col_1: "Herbicides", col_2: "", col_3: "" },
    { col_1: "Utilisable en Agriculture Biologique", col_2: "", col_3: "" },
    { col_1: "Total", col_2: "", col_3: "" }]);
    const [dataTabCrop, setDataTabCrop] = useState([]);
    const [dataTabProductCrm, setDataTabProductCrm] = useState([{ col_1: "Produits CMR employé (cocher si oui)", col_2: { checked: false }, col_3: "", col_4: "" }, { col_1: "Si oui : ", col_2: "Spé Commerciale", col_3: "SA", col_4: "Cible" }, { col_1: "", col_2: "", col_3: "", col_4: "" }]);
    const [uniqueColumn2DataLength, setUniqueColumn2DataLength] = useState(0);
    const token = localStorage.getItem('token');
    const { idUser, idCst } = useParams();
    const [needsSaveProductCrm, setNeedsSaveProductCrm] = useState(false);
    const [tabs,setTabs]= useState([
        { label: 'Partie 1', path: `/${id}/rapport/${idCst}` },
        { label: 'Annexe(s)', path: `/${id}/annexe/${idCst}/1` },
        { label: 'Partie 2', path: `/${id}/rapport2/${idCst}` },
        { label: 'Partie 3', path: `/${id}/rapport3/${idCst}` },
    ]);
    const [cropNames, setCropNames] = useState<string[]>([]); 
    const apiUrl = process.env.REACT_APP_API_URL;
 
    let isAdmin = false;

    if (token) {
        const tokenBody = token.split('.')[1];
        if (tokenBody) {
            const decodedToken = JSON.parse(atob(tokenBody));
            isAdmin = decodedToken.admin;
        }
    }

    useEffect(() => {
        if (idAnnexe) {
            const currentIndex = parseInt(idAnnexe, 10) - 1;
            const nextIndex = currentIndex + 1;
            setNextId(nextIndex < uniqueColumn2DataLength ? nextIndex + 1 : null);
            const prevIndex = currentIndex - 1;
            setPrevId(prevIndex >= 0 ? prevIndex + 1 : null);
        }
    }, [idAnnexe, uniqueColumn2DataLength]);

    useEffect(() => {


        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
    }, [navigate]);


    const goToNext = () => {
        console.log("nextId")
        console.log(nextId)
        if (nextId !== null) {
            setDataTabIft([{ col_1: "IFT de Référence Disponible : ", col_2: { checked: false }, col_3: "IFT Ref : ", col_4: "" }, { col_1: "IFT Culture : ", col_2: "" }])
            setDataTabBioControle([{ col_1: "Liste des Biocontrôle utilisés : ", col_2: "" }]);
            setDataTabTreatment([{ col_1: "", col_2: "Nombre de traitement", col_3: "Nombre de substance active" },
            { col_1: "Insecticides", col_2: "", col_3: "" },
            { col_1: "Fongicides", col_2: "", col_3: "" },
            { col_1: "Herbicides", col_2: "", col_3: "" },
            { col_1: "Utilisable en Agriculture Biologique", col_2: "", col_3: "" },
            { col_1: "Total", col_2: "", col_3: "" }]);
            setDataTabProductCrm([{ col_1: "Produits CMR employé", col_2: { checked: false }, col_3: "", col_4: "" }, { col_1: "Si oui : ", col_2: "Spé Commerciale", col_3: "SA", col_4: "Cible" }, { col_1: "", col_2: "", col_3: "", col_4: "" }]);
            navigate(`/${id}/annexe/${nextId}/${idCst}`);
        }
        else{
            navigate(`/${id}/rapport2/${idCst}`)
        }
    };

    const goToPrev = () => {
        if (prevId !== null) {
            setDataTabIft([{ col_1: "IFT de Référence Disponible : ", col_2: { checked: false }, col_3: "IFT Ref : ", col_4: "" }, { col_1: "IFT Culture : ", col_2: "" }])
            setDataTabBioControle([{ col_1: "Liste des Biocontrôle utilisés : ", col_2: "" }]);
            setDataTabTreatment([{ col_1: "", col_2: "Nombre de traitement", col_3: "Nombre de substance active" },
            { col_1: "Insecticides", col_2: "", col_3: "" },
            { col_1: "Fongicides", col_2: "", col_3: "" },
            { col_1: "Herbicides", col_2: "", col_3: "" },
            { col_1: "Utilisable en Agriculture Biologique", col_2: "", col_3: "" },
            { col_1: "Total", col_2: "", col_3: "" }]);
            setDataTabProductCrm([{ col_1: "Produits CMR employé", col_2: { checked: false }, col_3: "", col_4: "" }, { col_1: "Si oui : ", col_2: "Spé Commerciale", col_3: "SA", col_4: "Cible" }, { col_1: "", col_2: "", col_3: "", col_4: "" }]);
            navigate(`/${id}/annexe/${prevId}/${idCst}`);

        } else {
            navigate(`/${id}/rapport/${idCst}`); // Assurez-vous que le chemin est une chaîne de caractères
        }
    };



    useEffect(() => {
        const userIdToUse = id || idUser;
        const fetchCrop = async () => {
            try {
                const response = await fetch(`${apiUrl}/crop/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setDataTabCrop(data.dataTab || []);
                if (!isEqual(data, {})) {
                    // Étape 1 : Extraire toutes les valeurs de la colonne (par exemple, col_4)
                    const allValues = data.dataTab.flatMap((row) => {
                        // Étape 2 : Séparer les valeurs par virgule si elles existent
                        return row.col_4.split(',').map(value => value.trim());
                    });
                    // Étape 3 : Filtrer pour obtenir un ensemble de valeurs uniques
                    const uniqueValues = [...new Set(allValues.filter(value => value !== ""))];
                    setUniqueColumn2DataLength(uniqueValues.length);

                    const names = data.dataTab.flatMap((row: { col_4: string }) => 
                        row.col_4.split(',').map((name: string) => name.trim())
                    );
                    const uniqueNames = Array.from(new Set(names)) as string[];  // Assurez-vous que le résultat est de type string[]
                    setCropNames(uniqueNames);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchCrop();
    }, [token, idUser, id, idCst]);

    // ------------------ POST ----------------

    const saveDataToIft = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/ift/post/${userIdToUse}/${idAnnexe}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idAnnexe: idAnnexe, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveDataToBioControle = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/bioControle/post/${userIdToUse}/${idAnnexe}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idAnnexe: idAnnexe, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };
    const saveDataToTreatment = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/treatment/post/${userIdToUse}/${idAnnexe}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idAnnexe: idAnnexe, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveDataToProductCrm = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/productCrm/post/${userIdToUse}/${idAnnexe}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idAnnexe: idAnnexe, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const updatedDataTabTreatment = dataTabTreatment.map((item, index) => {
        if (index < dataTabTreatment.length - 1) {
            return { ...item };
        }

        if (item.col_1 === "Total") {
            let totalTraitements = 0;
            let totalSubstances = 0;

            dataTabTreatment.forEach((row, idx) => {
                if (idx > 0 && idx < dataTabTreatment.length - 1) { // Ignorer l'en-tête et la ligne de total
                    totalTraitements += parseFloat(row.col_2) || 0;
                    totalSubstances += parseFloat(row.col_3) || 0;
                }
            });

            return { ...item, col_2: totalTraitements.toString(), col_3: totalSubstances.toString() };
        }

        return item;
    });

    useEffect(() => {
        if (needsSaveProductCrm) {
          // Procéder à la sauvegarde des données
          saveDataToProductCrm(dataTabProductCrm)
            .then(() => setNeedsSaveProductCrm(false)) // Réinitialiser l'indicateur après la sauvegarde
            .catch(error => {
              console.error(error);
              // Gérer l'erreur de sauvegarde si nécessaire
            });
        }
      }, [needsSaveProductCrm]);

    // --------------- HANDLE CHANGE -------------

    const handleDataChangeIft = useCallback((newData) => {
        if (!isEqual(dataTabIft, newData)) {
            setDataTabIft(newData);
            saveDataToIft(newData);
        }
    }, [dataTabIft, saveDataToIft]);


    const handleDataChangeBioControle = useCallback((newData) => {
        if (!isEqual(dataTabBioControle, newData)) {
            setDataTabBioControle(newData);
            saveDataToBioControle(newData);
        }
    }, [dataTabBioControle, saveDataToBioControle]);

    const handleDataChangeTreatment = useCallback((newData) => {
        if (!isEqual(dataTabTreatment, newData)) {
            setDataTabTreatment(newData);
            saveDataToTreatment(newData);
        }
    }, [dataTabTreatment, saveDataToTreatment]);

    const handleDataChangeProductCrm = useCallback((newData) => {
        if (!isEqual(dataTabProductCrm, newData)) {
            setDataTabProductCrm(newData);
            saveDataToProductCrm(newData);
        }
    }, [dataTabProductCrm, saveDataToProductCrm]);

    // ------------------ GET -----------------

    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchIft = async () => {
            try {
                const response = await fetch(`${apiUrl}/ift/${userIdToUse}/${idAnnexe}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const result = await response.json();
                setDataTabIft(result.dataTab || []);
            } catch (error) {
                console.error(error);
            }
        };

        fetchIft();
    }, [token, idUser, id, idAnnexe, idCst]);


    useEffect(() => {
        const userIdToUse = id || idUser;
        const fetchBioControle = async () => {
            try {
                const response = await fetch(`${apiUrl}/bioControle/${userIdToUse}/${idAnnexe}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const result = await response.json();
                setDataTabBioControle(result.dataTab || []);
            } catch (error) {
                console.error(error);
            }
        };

        fetchBioControle();
    }, [token, idUser, id, idAnnexe, idCst]);

    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchTreatment = async () => {
            try {
                const response = await fetch(`${apiUrl}/treatment/${userIdToUse}/${idAnnexe}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const result = await response.json();
                setDataTabTreatment(result.dataTab || []);
            } catch (error) {
                console.error(error);
            }
        };

        fetchTreatment();
    }, [token, idUser, id, idAnnexe, idCst]);

    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchProductCrm = async () => {
            try {
                const response = await fetch(`${apiUrl}/productCrm/${userIdToUse}/${idAnnexe}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const result = await response.json();
                setDataTabProductCrm(result.dataTab || []);
            } catch (error) {
                console.error(error);
            }
        };

        fetchProductCrm();
    }, [token, idUser, id, idAnnexe, idCst]);

    const addNewProductCrmRow = () => {
        const oldRow1 = { col_1: "Produits CMR employé", col_2: { checked: false }, col_3: "", col_4: "" };
        const oldRow2 = { col_1: "Si oui : ", col_2: "Spé Commerciale", col_3: "SA", col_4: "Cible" }; 
        const oldRow3 = { col_1: "", col_2: "", col_3: "", col_4: "" };
        const newRow = {col_1: "",col_2: "",col_3: "",col_4: "",};
        if (dataTabProductCrm.length === 0) {
            setDataTabProductCrm([oldRow1, oldRow2, oldRow3, newRow]);
            setNeedsSaveProductCrm(true);
    } else {
            setDataTabProductCrm([...dataTabProductCrm, newRow]);
            setNeedsSaveProductCrm(true);
        }
    };

    const handlePrint = () => {
        window.print(); 
    };

    const annexeIndex = parseInt(idAnnexe ?? "0", 10) - 1; // Zero-based index
    const currentCropName = cropNames.length > annexeIndex && annexeIndex >= 0 ? cropNames[annexeIndex] : 'Non spécifié';

    useEffect(() => {
        // Vérifier si cropNames est prêt et contient les données nécessaires
        if (cropNames.length > 0) {
            // Générer les onglets pour chaque annexe basée sur les noms de cultures
            const annexeTabs = cropNames.map((name, index) => ({
                label: `Annexe ${index + 1}: ${name}`, // Utiliser l'index + 1 pour nommer les onglets
                path: `/${id}/annexe/${index + 1}/${idCst}` // Utiliser l'index + 1 pour les chemins, garantissant une correspondance constante
            }));
    
            const newTabs = [
                { label: 'Partie 1', path: `/${id}/rapport/${idCst}` },
                ...annexeTabs, // Ajouter les onglets d'annexes générés dynamiquement
                { label: 'Partie 2', path: `/${id}/rapport2/${idCst}` },
                { label: 'Partie 3', path: `/${id}/rapport3/${idCst}` },
            ];
    
            setTabs(newTabs); // Mettre à jour l'état des onglets avec la nouvelle configuration
        }
    }, [cropNames, id, idCst]); // Dépendances de l'effet pour réagir aux changements appropriés
    

    return (
        <>
            <style>
                {`
          @media print {
            .print-hide, .MuiButton-root, .user-name-button, .bouton-personnalise, .bouton-personnalise-2, .navbar, .tapBar, .bouton-personnalise-4, .bouton-personnalise-3 {
              display: none !important; /* Cacher les boutons et autres éléments non désirés */
            }
          
            .dynamic-table, .dynamic-table-row {
              page-break-inside: avoid; /* Essayer d'éviter la coupure des tableaux */
            }

            .dynamic-table, .commentary {
              margin-bottom: 50px; /* Ajoutez plus d'espacement en bas de chaque tableau/commentaire */
            }

            .date-input-view {
              display: none; /* Masque l'input de date en mode impression */
            }
          
            .date-text-view {
              display: block !important; /* Affiche le texte de la date en mode impression */
              border: none; /* Supprime tout cadre qui pourrait être appliqué */
              background: transparent; /* Assure un fond transparent */
              padding: 0; /* Supprime le padding pour éviter tout espace inutile */
              margin: 0 auto; /* Centrage horizontal */
              font-size: 18px; /* Assure que la taille de la police est appropriée */
            }
          }
          
          /* Styles par défaut (pas en mode impression) */
          .date-input-view {
            display: block; /* Affiche l'input de date normalement */
          }
          
          .date-text-view {
            display: none; /* Masque le texte de la date normalement */
          }

          #tableauAdapte {
            font-size: 10px; /* Réduction de la taille de la police */
            transform: scale(0.8); /* Ajustement de la taille */
            transform-origin: top left; /* Origine de la transformation */
            /* Ajoutez d'autres styles d'adaptation ici */
          }
          
          }
        `}
            </style>
            <Navbar />
            <Header />
            <PageTitle title={`Rapport conseil stratégique - Annexe n°${idAnnexe}`} />
            <div className="tapBar">
        <TabBar tabs={tabs}/>
        </div>
            <div>
                <Title text={`Bilan phytosanitaire - Culture : ${currentCropName}`} />
                <DynamicTable onDataChange={handleDataChangeIft} showCheckboxes={true} dataTab={dataTabIft} title="" columnNames={[]} editableAdmin />
                <DynamicTable onDataChange={handleDataChangeTreatment} dataTab={updatedDataTabTreatment} title="" columnNames={[]} editableAdmin />
                <DynamicTable onDataChange={handleDataChangeBioControle} dataTab={dataTabBioControle} title="" columnNames={[]} editableAdmin />
                <DynamicTable onDataChange={handleDataChangeProductCrm} showDeleteRow={true} showCheckboxes={true} dataTab={dataTabProductCrm} title="" columnNames={[]} editableAdmin name="productCrm"/>
                {isAdmin && <button
                    onClick={addNewProductCrmRow}
                    className="bouton-personnalise"
                >
                    Ajouter Ligne
                </button>}
            </div>
            <button
                onClick={goToPrev}
                className="print-hide"
                style={{
                    position: 'fixed', // Se fixe dans le coin inférieur gauche de l'écran
                    bottom: '20px', // À 20px du bas
                    left: '20px', // À 20px de la gauche (au lieu de `right`)
                    height: '50px', // Hauteur du bouton
                    width: '50px', // Largeur du bouton (ajustez la taille selon les besoins)
                    borderRadius: '50%', // Forme circulaire
                    backgroundColor: '#00b09b', // Couleur de fond (bleu primaire de Bootstrap par exemple)
                    color: 'white', // Couleur de la flèche
                    fontSize: '24px', // Taille du texte de la flèche/de l'icône
                    lineHeight: '55px', // Centre la flèche verticalement
                    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)', // Ombre optionnelle pour la profondeur
                    border: 'none', // Pas de bordure
                    cursor: 'pointer', // Change le curseur au survol
                }}
                aria-label="Naviguer vers la page suivante"
            >
                ↩
            </button>
            <button
                onClick={goToNext}
                className="print-hide"
                style={{
                    position: 'fixed', // Se fixe dans le coin inférieur droit de l'écran
                    bottom: '20px', // À 20px du bas
                    right: '20px', // À 20px de la droite
                    height: '50px', // Hauteur du bouton
                    width: '50px', // Largeur du bouton (ajustez la taille selon les besoins)
                    borderRadius: '50%', // Forme circulaire
                    backgroundColor: '#00b09b', // Couleur de fond (bleu primaire de Bootstrap par exemple)
                    color: 'white', // Couleur de la flèche
                    fontSize: '30px', // Taille du texte de la flèche/de l'icône
                    lineHeight: '55px', // Centre la flèche verticalement
                    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)', // Ombre optionnelle pour la profondeur
                    border: 'none', // Pas de bordure
                    cursor: 'pointer', // Change le curseur au survol
                }}
                aria-label="Naviguer vers la page suivante"
            >
                ↪
            </button>
            <button
                onClick={handlePrint}
                className="bouton-personnalise-2"
            >
                Imprimer
            </button>
        </>
    );
}
