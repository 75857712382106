import React from 'react';
import { useNavigate } from 'react-router-dom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'; // Import de l'icône


const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <>
      {/* Styles pour cacher le bouton lors de l'impression */}
      <style>
        {`
          @media print {
            .print-hide {
              display: none;
            }
          }
        `}
      </style>

      <button
        onClick={handleLogout}
        className="print-hide"
        style={{
          color: 'white',
          background: '#4caf50',
          border: 'none',
          borderRadius: '5px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          padding: '10px',
          cursor: 'pointer',
          outline: 'none',
          margin: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onMouseOver={e => e.target.style.backgroundColor = '#388e3c'}
        onMouseOut={e => e.target.style.backgroundColor = '#4caf50'}
      >
        <PowerSettingsNewIcon style={{ color: 'white' }} /> {/* Utilisation de l'icône avec style personnalisé */}
      </button>
    </>
  );
};

export const LogoutButton = () => {
  return <Logout />;
};

export default Logout;
