import React, { useState, useEffect, FormEvent } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface SignInProps { }

function SignIn(props: SignInProps) {
  const [identifier, setIdentifier] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [identifierError, setIdentfierError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const [err, setErr] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleClickOpen = () => {
    setOpenForgotPassword(true);
  };

  const handleClose = () => {
    setOpenForgotPassword(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setSnackbarOpen(false);
};

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIdentfierError('');
    setPasswordError('');
    try {
      const response = await fetch(`${apiUrl}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ identifier, password })
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data.accessToken);
        navigate('/home');
      } else {
        setPasswordError('Identifiant ou Mot de passe invalide');
        setIdentfierError('.');
        setSnackbarSeverity('error');
        setSnackbarMessage('Identifiant ou mot de passe invalide.');
        setSnackbarOpen(true);

        throw new Error(data.errors || 'Erreur identification');
      }
    } catch (error: any) {
      console.error(error);
      setErr(error.message);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForgotPasswordSubmit = async () => {
    try {
      const response = await fetch(`${apiUrl}/user/forgotPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: resetEmail }),
      });
      if (response.ok) {
        setSnackbarSeverity('success');
        setSnackbarMessage('Veuillez vérifier votre e-mail pour les instructions de réinitialisation du mot de passe.');
        setSnackbarOpen(true);
        handleClose(); // Fermez le dialogue après la soumission réussie
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage('Impossible de trouver un compte avec cette adresse e-mail.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Une erreur est survenue. Veuillez réessayer plus tard.');
      setSnackbarOpen(true);
      console.error('Erreur de réinitialisation du mot de passe:', error);
    }
  };

  useEffect(() => {
    const nav = document.querySelector('nav');
    if (nav) {
      nav.style.display = 'none';
    }

    return () => {
      if (nav) {
        nav.style.display = 'block';
      }
    };
  }, [navigate]);

  return (
    <div style={{
      backgroundImage: 'url("/fond_login.jpeg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: 3,
            borderRadius: 2,
            boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src="/logo.png" alt="Logo" style={{ width: '100px', height: 'auto' }} />

          <Typography component="h1" variant="h5" sx={{ color: 'green', margin: '20px 0' }}>
            Se connecter
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="identifier"
                  required
                  fullWidth
                  id="identifier"
                  label="Email ou Téléphone"
                  autoFocus
                  onChange={(e) => setIdentifier(e.target.value)}
                  error={!!identifierError}
                  sx={{ bgcolor: 'white', borderRadius: 1, borderColor: 'green' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="password"
                  required
                  fullWidth
                  id="password"
                  label="Mot de Passe"
                  error={!!passwordError}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{ bgcolor: 'white', borderRadius: 1, borderColor: 'green' }}
                  InputProps={{ // Ajoutez cette prop pour le TextField du mot de passe
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* Ajoutez d'autres champs de la même manière */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 4, mb: 2, bgcolor: 'green', '&:hover': { bgcolor: 'darkgreen' } }}
              >
                Se connecter
              </Button>

            </Grid>
            <Grid container>
              <Grid container justifyContent="center">
                <Link href="#" variant="body2" onClick={handleClickOpen}>
                  Mot de passe oublié ?
                </Link>
                <Dialog open={openForgotPassword} onClose={handleClose}>
                  <DialogTitle>Mot de passe oublié</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Pour réinitialiser votre mot de passe, veuillez entrer votre adresse email ici. Nous vous enverrons des instructions pour réinitialiser votre mot de passe.
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="email"
                      label="Adresse Email"
                      type="email"
                      fullWidth
                      variant="standard"
                      value={resetEmail}
                      onChange={(e) => setResetEmail(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button onClick={handleForgotPasswordSubmit}>Envoyer</Button>
                  </DialogActions>
                </Dialog>

              </Grid>
              <Grid container justifyContent="center">
                <Link href="/" variant="body2">
                  {"Pas encore de compte ? Inscrits-toi !"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>

        <Box
          sx={{
            mt: 4, // Marge au-dessus de la Box
            p: 2, // Padding à l'intérieur de la Box
            backgroundColor: 'rgba(235, 235, 235, 0.9)', // Couleur de fond semi-transparente
            borderRadius: '8px', // Bords arrondis
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Ombre pour du relief
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '80%',
            mx: 'auto', // Centre la Box horizontalement
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: '#333',
              textAlign: 'center',
              fontSize: '1.2rem', // Augmente la taille de la police du Typography
              fontWeight: 'bold', // Rend le texte gras

            }}
          >
            Pour plus d'informations, visitez notre site :
          </Typography>
          <Link
            href="https://www.ppgaccompagnement.fr/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              mt: 1,
              typography: 'body1',
              color: 'green',
              '&:hover': { textDecoration: 'underline' },
              fontWeight: 'bold',
              fontSize: '1.1rem', // Augmente également la taille de la police du Link
            }}
          >
            www.ppgaccompagnement.fr
          </Link>
        </Box>

      </Container>
    </div>
  );


}

export default SignIn;
