import React, { CSSProperties } from 'react';

interface TitleProps {
  text: string;
  customStyle?: CSSProperties; // Ajoutez une propriété customStyle optionnelle
}

const Title: React.FC<TitleProps> = ({ text, customStyle }) => {
  const defaultTitleStyle: CSSProperties = {
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#0d401b',
    padding: '10px',
    borderRadius: '5px',
    margin: '10px 0'
  };

  const titleStyle = customStyle
    ? { ...defaultTitleStyle, ...customStyle } // Combine les styles par défaut avec le style personnalisé
    : defaultTitleStyle; // Utilisez le style par défaut si aucun style personnalisé n'est fourni

  return (
    <h2 style={titleStyle}>{text}</h2>
  );
};

export default Title;
