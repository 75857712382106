import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Navbar from "../component/navbar.tsx";
import Logo from "../component/logo.tsx";
import { Box, Typography, Container, List, ListItem, ListItemText, Link } from '@mui/material';


export default function Home() {
    const navigate = useNavigate();

    useEffect(() => {


        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
    }, [navigate]);


    useEffect(() => {
        // Appliquer le style lors du montage
        document.body.style.backgroundImage = "url('/fond_mdp.jpeg')";
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundPosition = 'center';
        document.body.style.backgroundRepeat = 'no-repeat';

        // Nettoyer le style lors du démontage
        return () => {
            document.body.style.backgroundImage = null;
            document.body.style.backgroundSize = null;
            document.body.style.backgroundPosition = null;
            document.body.style.backgroundRepeat = null;
        };
    }, []);

    useEffect(() => {
        document.body.classList.add('blurred-background');
    
        return () => {
            document.body.classList.remove('blurred-background');
        };
    }, []);
    
    return (
        <>
          <Navbar />
          <div style={{marginTop:"10px"}}></div>
          <Logo />
          <Container maxWidth="md" sx={{ mt: 5, mb: 8 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}
            >
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  fontSize: '2rem',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                }}
              >
                Contactez-nous
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontSize: '1.25rem',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                }}
              >
                Vous pouvez nous contacter par :
              </Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Email : contact@ppgaccompagnement.fr"
                    primaryTypographyProps={{
                      sx: {
                        fontSize: '1rem',
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      }
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Téléphone : 06 43 57 85 57"
                    primaryTypographyProps={{
                      sx: {
                        fontSize: '1rem',
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      }
                    }}
                  />
                </ListItem>
                {/* Ajoutez d'autres méthodes de contact si nécessaire */}
              </List>
              <Typography
                variant="body2"
                sx={{
                  color: '#333',
                  textAlign: 'center',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                }}
              >
                Pour plus d'informations, visitez notre site :
              </Typography>
              <Link
                href="https://www.ppgaccompagnement.fr/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  mt: 1,
                  typography: 'body1',
                  color: 'green',
                  '&:hover': { textDecoration: 'underline' },
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                }}
              >
                www.ppgaccompagnement.fr
              </Link>
            </Box>
          </Container>
        </>
      );
    }