import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, TextField, Tooltip } from '@mui/material';

export const EditableField = ({ label, value, onSave, textStyle }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  const [hover, setHover] = useState(false);

  const handleEdit = () => setIsEditable(true);
  const handleChange = (e) => setLocalValue(e.target.value);
  const handleSave = () => {
    onSave(localValue);
    setIsEditable(false);
  };

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  return (
    <div 
      style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip title={value} placement="top" enterDelay={500} leaveDelay={200}>
        <TextField
          label={label}
          value={isEditable ? localValue : value}
          onChange={handleChange}
          disabled={!isEditable}
          fullWidth
          InputProps={{
            style: { 
              ...textStyle,
              transition: 'width 0.3s ease',
            }
          }} // Appliquez le style au texte ici
          margin="normal"
          variant="outlined"
        />
      </Tooltip>
      {isEditable ? (
        <IconButton onClick={handleSave}>
          <SaveIcon />
        </IconButton>
      ) : (
        <IconButton onClick={handleEdit}>
          <EditIcon />
        </IconButton>
      )}
    </div>
  );
};
