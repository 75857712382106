import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from "../component/dynamicTable.tsx";
import Navbar from "../component/navbar.tsx";
import Title from "../component/title.tsx";
import Header from "../component/header.tsx";
import Commentary from "../component/commentaire.tsx";
import { Buffer } from "buffer";
import isEqual from 'lodash/isEqual';
import '../App.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import animationData from '../loading.json';
import Spinner from "../component/spinner.tsx";
import * as XLSX from 'xlsx';

export default function CahierCulture() {
  const navigate = useNavigate();
  const [totals, setTotals] = useState({ totalN: 0, totalP: 0, totalK: 0 });
  const [produitsCsv, setProduitsCsv] = useState([]);
  const productNames = Object.keys(produitsCsv);
  const [error, setError] = useState(null);
  const [cropDropdown, setCropDropdown] = useState<string[]>([]);
  const [commentaryTechnicalManager, setTechnicalManager] = useState("Nom du ou des responsables techniques:");
  const [commentaryOperatorValidation, setOperatorValidation] = useState("Validation par l'exploitant:\n\nLe:\n\nA:\n\nSignature:\n\n\n\n");
  const [dataTabPlantation, setDataTabPlantation] = useState([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "" }]);
  const [dataTabPrevious, setDataTabPrevious] = useState([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "" }]);
  const [dataTabGroundWork, setDataTabGroundWork] = useState([{ col_1: "Date", col_2: "Opération", col_3: "Opérateur", }, { col_1: "", col_2: "", col_3: "", }]);
  const [dataTabFertilization, setDataTabFertilization] = useState([{ col_1: "Opérateur", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }, { col_1: "Date", col_2: "Type d'engrais (Minéral-Organique-Organominéral)", col_3: "Nom commercial", col_4: "N", col_5: "P", col_6: "K", col_7: "Dose/ha en kg ou L", col_8: "Mode d'épandage", }, { col_1: "", col_2: { type: "dropdown", value: "", options: ["Minéral", "Organique", "Orgaminérale", "Foliaire"] }, col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }]);
  const [dataTabRegistration, setDataTabRegistration] = useState([{ col_1: "Responsable technique (detenant certiphyto décideur)", col_2: "" }, { col_1: "Opérateur effectuant les traitements (détenant certiphyto opérateur )", col_2: "" }]);
  const [dataTabClimat, setDataTabClimat] = useState([{ col_1: "Date", col_2: "Météo", col_3: "Vent", col_4: "Matériel et opérateur si plusieurs intervenants sur la culture	", col_5: "Produit commercial", col_6: "Substance active", col_7: "Cible", col_8: "Dose employée", col_9: "DAR", col_10: "Herbicide/fongicide/insecticide …	", col_11: "Justification	", }, { col_1: "", col_2: { type: "dropdown", value: "", options: ["Soleil", "Nuageux", "Nuit"] }, col_3: { type: "dropdown", value: "", options: ["Vent < 5Km/h", "Vent < 10Km/h", "Vent < 19Km/h"] }, col_4: "", col_5: { type: "dropdown", value: "", options: productNames, }, col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", }, { col_1: "Date de récolte", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", }]);
  const [dataTabCulture, setDataTabCulture] = useState([{ col_1: "Campagne :", col_2: "" }, { col_1: "Culture :", col_2: { type: "dropdown", value: "", options: cropDropdown }, }, { col_1: "Surface :", col_2: "" }, { col_1: "Parcelles concernées", col_2: "" }]);
  const token = localStorage.getItem('token');
  const { id, idCulture } = useParams();
  const [dataTabFarmingAll, setDataTabFarmingAll] = useState([{ col_1: "", col_2: "", col_3: "" }])
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [cultureDetails, setCultureDetails] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [isCahierCulture, setIsCahierCulture] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [userDataRaisonSociale, setUserDataRaisonSociale] = useState("");
  const [currentIdCulture, setCurrentIdCulture] = useState(idCulture);
  const [dataTabPlotInventory, setDataTabPlotInventory] = useState([]);
  const [needsSavePrevious, setNeedsSavePrevious] = useState(false);
  const [needsSavePlantation, setNeedsSavePlantation] = useState(false);
  const [needsSaveGroundWork, setNeedsSaveGroundWork] = useState(false);
  const [needsSaveFertilization, setNeedsSaveFertilization] = useState(false);
  const [needsSaveClimat, setNeedsSaveClimat] = useState(false);
  const [selectedCulture, setSelectedCulture] = useState("");
  const [fertilizationDataReady, setFertilizationDataReady] = useState(false);
  const [columnsReady, setColumnsReady] = useState(false);


  const apiUrl = process.env.REACT_APP_API_URL;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  let idUser = null;
  let isAdmin = false;

  if (token) {
    const tokenBody = token.split('.')[1];
    if (tokenBody) {
      const decodedToken = JSON.parse(Buffer.from(tokenBody, 'base64').toString());
      idUser = decodedToken.id;
      isAdmin = decodedToken.admin;
    }
  }



  // ------------------ POST ----------------

  const saveDataToFarmingAll = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/farmingAll/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCulture: idCulture })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToCulture = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/culture/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCulture: idCulture })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToPlantation = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/plantation/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCulture: idCulture })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToPrevious = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/previous/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCulture: idCulture })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToGroundWork = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/groundWork/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCulture: idCulture })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToFertilization = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/fertilization/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCulture: idCulture })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };


  const saveDataToRegistration = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/registration/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCulture: idCulture })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToClimat = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/climat/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCulture: idCulture })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveTechnicalManager = async (newCommentaryData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/technicalManager/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, commentaryData: newCommentaryData, idCulture: idCulture })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveOperatorValidation = async (newCommentaryData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/operatorValidation/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, commentaryData: newCommentaryData, idCulture: idCulture })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (needsSavePrevious) {
      // Procéder à la sauvegarde des données
      saveDataToPrevious(dataTabPrevious)
        .then(() => setNeedsSavePrevious(false)) // Réinitialiser l'indicateur après la sauvegarde
        .catch(error => {
          console.error(error);
          // Gérer l'erreur de sauvegarde si nécessaire
        });
    }
  }, [needsSavePrevious]);

  useEffect(() => {
    if (needsSavePlantation) {
      // Procéder à la sauvegarde des données
      saveDataToPlantation(dataTabPlantation)
        .then(() => setNeedsSavePlantation(false)) // Réinitialiser l'indicateur après la sauvegarde
        .catch(error => {
          console.error(error);
          // Gérer l'erreur de sauvegarde si nécessaire
        });
    }
  }, [needsSavePlantation]);

  useEffect(() => {
    if (needsSaveGroundWork) {
      // Procéder à la sauvegarde des données
      saveDataToGroundWork(dataTabGroundWork)
        .then(() => setNeedsSaveGroundWork(false)) // Réinitialiser l'indicateur après la sauvegarde
        .catch(error => {
          console.error(error);
          // Gérer l'erreur de sauvegarde si nécessaire
        });
    }
  }, [needsSaveGroundWork]);

  useEffect(() => {
    if (needsSaveFertilization) {
      // Procéder à la sauvegarde des données
      saveDataToFertilization(dataTabFertilization)
        .then(() => setNeedsSaveFertilization(false)) // Réinitialiser l'indicateur après la sauvegarde
        .catch(error => {
          console.error(error);
          // Gérer l'erreur de sauvegarde si nécessaire
        });
    }
  }, [needsSaveFertilization]);

  useEffect(() => {
    if (needsSaveClimat) {
      // Procéder à la sauvegarde des données
      saveDataToClimat(dataTabClimat)
        .then(() => setNeedsSaveClimat(false)) // Réinitialiser l'indicateur après la sauvegarde
        .catch(error => {
          console.error(error);
          // Gérer l'erreur de sauvegarde si nécessaire
        });
    }
  }, [needsSaveClimat]);

  // ------------------ GET -----------------

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchPlotInventory = async () => {
      try {
        const response = await fetch(`${apiUrl}/plotInventory/${userIdToUse}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        if (!isEqual(data, {})) {
          const names = data.dataTab.flatMap((row, index) => {
            if (index > 0) {
              return row.col_5.split(',').map(name => name.trim());
            }
            return [];
          });
          const uniqueNames = Array.from(new Set(names));
          setCropDropdown(uniqueNames); // Met à jour le state avec les noms uniques
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPlotInventory();
  }, [token, idUser, id]); // Ajout de token dans les dépendances si nécessaire

  useEffect(() => {
    // Vérifie si dataTabCulture existe déjà et possède des entrées
    if (dataTabCulture && dataTabCulture.length > 0) {
      // Met à jour uniquement les options du dropdown pour 'Culture'
      setDataTabCulture(dataTabCulture.map(item => {
        if (item.col_1 === "Culture :") {
          return { ...item, col_2: { ...item.col_2, options: cropDropdown } };
        }
        return item;
      }));
    } else {
      // Si dataTabCulture est vide, initialisez-le avec des valeurs par défaut
      setDataTabCulture([
        { col_1: "Campagne :", col_2: "" },
        { col_1: "Culture :", col_2: { type: "dropdown", value: "", options: cropDropdown } },
        { col_1: "Surface :", col_2: "" },
        { col_1: "Parcelles concernées", col_2: "" }
      ]);
    }
  }, [cropDropdown]); // Dépendance sur cropDropdown



  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchPlantation = async () => {
      try {
        const response = await fetch(`${apiUrl}/plantation/${userIdToUse}/${idCulture}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabPlantation(data.dataTab || []);
        if (!isEqual(dataTabPlantation, data)) {
          setDataTabPlantation(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPlantation();
  }, [token, idUser, id, idCulture]);


  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchFarmingAll = async () => {
      try {
        const response = await fetch(`${apiUrl}/user/${userIdToUse}}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données utilisateur');
        }

        const userData = await response.json();
        setUserDataRaisonSociale(userData.raisonSociale)
        // Mappage des données utilisateur sur dataTabFarmingAll
        const newDataTabFarmingAll = [
          { col_1: `${userData.lastName} ${userData.firstName} - ${userData.raisonSociale}`, col_2: userData.adresse, col_3: userData.telephone },
        ];
        setDataTabFarmingAll(newDataTabFarmingAll);
      } catch (error) {
        console.error(error);
      }
    };
    fetchFarmingAll();
  }, [token, idUser, id, idCulture]);

  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchFertilization = async () => {
      try {
        const response = await fetch(`${apiUrl}/fertilization/${userIdToUse}/${idCulture}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabFertilization(data.dataTab || []);
        if (!isEqual(dataTabFertilization, data)) {
          setDataTabFertilization(data.dataTab);
          setFertilizationDataReady(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchFertilization();
  }, [token, idUser, id, idCulture]);


  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchCulture = async () => {
      try {
        const response = await fetch(`${apiUrl}/culture/${userIdToUse}/${idCulture}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabCulture(data.dataTab || []);
        if (!isEqual(dataTabCulture, data)) {
          setDataTabCulture(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCulture();
  }, [token, idUser, id, idCulture]);

  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchPrevious = async () => {
      try {
        const response = await fetch(`${apiUrl}/previous/${userIdToUse}/${idCulture}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabPrevious(data.dataTab || []);
        if (!isEqual(dataTabPrevious, data)) {
          setDataTabPrevious(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPrevious();
  }, [token, idUser, id, idCulture]);


  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchGroundWork = async () => {
      try {
        const response = await fetch(`${apiUrl}/groundWork/${userIdToUse}/${idCulture}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabGroundWork(data.dataTab || []);
        if (!isEqual(dataTabGroundWork, data)) {
          setDataTabGroundWork(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchGroundWork();
  }, [token, idUser, id, idCulture]);





  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchRegistration = async () => {
      try {
        const response = await fetch(`${apiUrl}/registration/${userIdToUse}/${idCulture}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabRegistration(data.dataTab || []);
        if (!isEqual(dataTabRegistration, data)) {
          setDataTabRegistration(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchRegistration();
  }, [token, idUser, id, idCulture]);


  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchTechnicalManager = async () => {
      try {
        const response = await fetch(`${apiUrl}/technicalManager/${userIdToUse}/${idCulture}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setTechnicalManager(data.commentaryData || "");
        if (isEqual(data, {})) {
          setTechnicalManager("");
        }
        if (!isEqual(commentaryTechnicalManager, data)) {
          setTechnicalManager(data.commentaryData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchTechnicalManager();
  }, [token, idUser, id, idCulture]);

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchOperatorValidation = async () => {
      try {
        const response = await fetch(`${apiUrl}/operatorValidation/${userIdToUse}/${idCulture}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setOperatorValidation(data.commentaryData || "");
        if (isEqual(data, {})) {
          setOperatorValidation("");
        }
        if (!isEqual(commentaryOperatorValidation, data)) {
          setOperatorValidation(data.commentaryData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchOperatorValidation();
  }, [token, idUser, id, idCulture]);

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchUserById = async () => {
      try {
        const response = await fetch(`${apiUrl}/user/${userIdToUse}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setSelectedUser(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserById();
  }, [token, idUser, id]);

  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchUserByCulture = async () => {
      try {
        const response = await fetch(`${apiUrl}/user/cahierCulture/${userIdToUse}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setIsCahierCulture(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserByCulture();
  }, [token, idUser, id, isCahierCulture]);

  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchPlotInventory = async () => {
      try {
        const response = await fetch(`${apiUrl}/plotInventory/${userIdToUse}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabPlotInventory(data.dataTab || []);
        if (!isEqual(dataTabPlotInventory, data)) {
          setDataTabPlotInventory(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPlotInventory();
  }, [token, idUser, id, idCulture]);


  // --------------- HANDLE CHANGE -------------

  const handleDataChangeFarmingAll = useCallback((newData) => {

    if (!isEqual(dataTabFarmingAll, newData)) {
      setDataTabFarmingAll(newData);
      saveDataToFarmingAll(newData);
    }
  }, [dataTabFarmingAll, saveDataToFarmingAll]);

  const handleDataChangeCulture = useCallback((newData) => {

    if (!isEqual(dataTabCulture, newData)) {
      setDataTabCulture(newData);
      saveDataToCulture(newData);
    }
  }, [dataTabCulture, saveDataToCulture]);

  const handleDataChangePlantation = useCallback((newData) => {

    if (!isEqual(dataTabPlantation, newData)) {
      setDataTabPlantation(newData);
      saveDataToPlantation(newData);
    }
  }, [dataTabPlantation, saveDataToPlantation]);

  const handleDataChangePrevious = useCallback((newData) => {
    if (!isEqual(dataTabPrevious, newData)) {
      setDataTabPrevious(newData);
      saveDataToPrevious(newData);
    }
  }, [dataTabPrevious, saveDataToPrevious]);


  const handleDataChangeGroundWork = useCallback((newData) => {
    if (!isEqual(dataTabGroundWork, newData)) {
      setDataTabGroundWork(newData);
      saveDataToGroundWork(newData);
    }
  }, [dataTabGroundWork, saveDataToGroundWork]);

  const handleDataChangeFertilization = useCallback((newData) => {
    // Vérifier si les colonnes sont prêtes avant de permettre les modifications
    if (!columnsReady) return;

    // Créer un objet pour stocker les totaux par parcelle
    let parcelTotals = {};

    const cultureValue = Array.isArray(dataTabCulture)
      ? dataTabCulture.find(row => row.col_1 && row.col_1.includes("Culture"))?.col_2?.value
      : "";

    const matchingRows = Array.isArray(dataTabPlotInventory)
      ? dataTabPlotInventory.filter(row => row.col_5 === cultureValue)
      : [];


    // Initialiser les totaux pour chaque parcelle
    matchingRows.forEach(row => {
      const parcelNumber = row.col_1.toString();
      parcelTotals[parcelNumber] = { totalN: 0, totalP: 0, totalK: 0 };
    });

    // Calcul des totaux avec les nouvelles données
    newData.forEach((row, rowIndex) => {
      if (rowIndex <= 1 || rowIndex >= newData.length - 3) return; // Ignorer les lignes de titre et les totaux

      Object.keys(row).forEach((colKey) => {
        if (row[colKey]?.checked) {
          const parcelIndex = parseInt(colKey.replace('col_', '')) - 9;
          if (parcelIndex >= 0 && parcelIndex < matchingRows.length) {
            const parcelNumber = matchingRows[parcelIndex].col_1.toString();
            parcelTotals[parcelNumber].totalN += parseFloat(row.col_4) || 0;
            parcelTotals[parcelNumber].totalP += parseFloat(row.col_5) || 0;
            parcelTotals[parcelNumber].totalK += parseFloat(row.col_6) || 0;
          }
        }
      });
    });

    // Mettre à jour les lignes Totaux N, P, K
    Object.keys(parcelTotals).forEach((parcelNumber, i) => {
      const colKey = `col_${9 + i}`;
      newData[newData.length - 3][colKey] = parcelTotals[parcelNumber].totalN.toFixed(2);
      newData[newData.length - 2][colKey] = parcelTotals[parcelNumber].totalP.toFixed(2);
      newData[newData.length - 1][colKey] = parcelTotals[parcelNumber].totalK.toFixed(2);
    });

    // Mise à jour des données dans le tableau
    setDataTabFertilization(newData);
    setNeedsSaveFertilization(true);
  }, [dataTabFertilization, columnsReady]);





  const handleDataChangeRegistration = useCallback((newData) => {
    if (!isEqual(dataTabRegistration, newData)) {
      setDataTabRegistration(newData);
      saveDataToRegistration(newData);
    }
  }, [dataTabRegistration, saveDataToRegistration]);

  const handleDataChangeTechnicalManager = useCallback((newCommentaryData) => {
    if (!isEqual(commentaryTechnicalManager, newCommentaryData)) {
      setTechnicalManager(newCommentaryData);
      saveTechnicalManager(newCommentaryData);
    }
  }, [commentaryTechnicalManager, saveTechnicalManager]);

  const handleDataChangeOperatorValidation = useCallback((newCommentaryData) => {
    if (!isEqual(commentaryOperatorValidation, newCommentaryData)) {
      setOperatorValidation(newCommentaryData);
      saveOperatorValidation(newCommentaryData);
    }
  }, [commentaryOperatorValidation, saveOperatorValidation]);

  const handleDataChangeClimat = useCallback((newData) => {
    const updatedData = newData.map((row, index) => {
      // Logique de conservation des valeurs de 'dose' et 'DAR' si elles sont déjà définies
      if (row.col_5 && row.col_5.type === "dropdown") {
        const selectedOption = row.col_5.value;
        const productVariations = produitsCsv[selectedOption] || [];
        const attributesMapping = {
          'substance': 'col_6',
          'target': 'col_7',
          'dose': 'col_8',
          'dar': 'col_9',
          'type': 'col_10'
        };

        Object.keys(attributesMapping).forEach(attr => {
          const uniqueValues = new Set(productVariations.map(item => item[attr]));
          if (attr === 'dose' || attr === 'dar') {
            // Pour 'dose' et 'DAR', vérifiez si une valeur est déjà sélectionnée et valide
            const currentValue = row[attributesMapping[attr]]?.value || row[attributesMapping[attr]];
            if (uniqueValues.has(currentValue)) {
              // Si la valeur actuelle est valide, conservez-la
              return;
            }
          }

          if (uniqueValues.size === 1) {
            // S'il n'y a qu'une seule valeur possible, l'affecter directement
            row[attributesMapping[attr]] = Array.from(uniqueValues)[0];
          } else {
            // S'il y a plusieurs valeurs possibles, préparer un dropdown
            row[attributesMapping[attr]] = {
              type: 'dropdown',
              value: row[attributesMapping[attr]]?.value || '',
              options: Array.from(uniqueValues),
            };
          }
        });
      }
      return row;
    });

    setDataTabClimat(updatedData);
    saveDataToClimat(updatedData);
  }, [dataTabClimat, saveDataToClimat]);




  const handleDropdownChange = useCallback((rowIndex, columnKey, selectedValue) => {
    setDataTabClimat(currentData => {
      const newData = currentData.map((row, index) => {
        if (index === rowIndex && columnKey === "col_7") {
          const selectedProduct = row.col_5.value;
          const productVariations = produitsCsv[selectedProduct]?.filter(variation => variation.target === selectedValue) || [];
          // Mise à jour basée sur la cible sélectionnée
          updateRowBasedOnTargetSelection(row, productVariations);
        }
        return row;
      });

      saveDataToClimat(newData);
      return newData;
    });
  }, [saveDataToClimat]);

  function updateRowBasedOnTargetSelection(row, productVariations) {
    ['dose', 'dar', 'type'].forEach((attr, idx) => {
      const colKey = `col_${8 + idx}`;
      const uniqueValues = new Set(productVariations.map(item => item[attr]));
      const currentValue = row[colKey]?.value || row[colKey];
      const hasCurrentValue = uniqueValues.has(currentValue);

      if (uniqueValues.size === 1) {
        row[colKey] = Array.from(uniqueValues)[0];
      } else if (uniqueValues.size > 1 && !hasCurrentValue) {
        row[colKey] = {
          type: 'dropdown',
          value: '',
          options: Array.from(uniqueValues),
        };
      } // Conserver la valeur si elle est déjà correcte
    });
  }

  const addNewPlantationRow = () => {
    const newRow = {
      col_1: "",
      col_2: "",
      col_3: "",
      col_4: "",
      col_5: "",
      col_6: "",
    };
    if (!Array.isArray(dataTabPlantation)) {
      setDataTabPlantation([newRow, newRow]);
      setNeedsSavePlantation(true);
    } else {
      setDataTabPlantation([...dataTabPlantation, newRow]);
      setNeedsSavePlantation(true);
    }
  };

  const addNewPreviousRow = () => {
    const newRow = {
      col_1: "",
      col_2: "",
      col_3: "",
      col_4: "",
      col_5: "",
      col_6: "",
      col_7: "",
    };
    if (!Array.isArray(dataTabPrevious)) {
      setDataTabPrevious([newRow, newRow]);
      setNeedsSavePrevious(true);
    } else {
      setDataTabPrevious([...dataTabPrevious, newRow]);
      setNeedsSavePrevious(true);
    }
  };


  const addNewGroundWorkRow = () => {
    const oldRow = { col_1: "Date", col_2: "Opération", col_3: "Opérateur", }
    const newRow = { col_1: "", col_2: "", col_3: "", };
    const startingColumnIndex = 4;
    if (dataTabGroundWork && dataTabGroundWork.length > 0) {
      const maxColumns = Math.max(...dataTabGroundWork.map(row => Object.keys(row).length));
      for (let i = startingColumnIndex; i <= maxColumns; i++) {
        newRow[`col_${i}`] = { checked: false }; // Ajoute une checkbox à false
      }
    }
    if (!Array.isArray(dataTabGroundWork)) {
      setDataTabGroundWork([oldRow, newRow, newRow]);
      setNeedsSaveGroundWork(true);
    } else {
      setDataTabGroundWork([...dataTabGroundWork, newRow]);
      setNeedsSaveGroundWork(true);
    }
  };

  const addNewFertilizationRow = () => {
    const oldRow2 = { col_1: "Opérateur", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }
    const oldRow = { col_1: "Date", col_2: "Type d'engrais (Minéral-Organique-Organominéral)", col_3: "Nom commercial", col_4: "N", col_5: "P", col_6: "K", col_7: "Dose/ha en kg ou L", col_8: "Mode d'épandage", };
    const newRow = { col_1: "", col_2: { type: "dropdown", value: "", options: ["Minéral", "Organique", "Orgaminérale", "Foliaire"] }, col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", };
    const lastRow1 = { col_1: "Total N", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", };
    const lastRow2 = { col_1: "Total P", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", };
    const lastRow3 = { col_1: "Total K", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", };

    const startingColumnIndex = 9; // Commence à col_9
    if (dataTabFertilization && dataTabFertilization.length > 0) {
      const maxColumns = Math.max(...dataTabFertilization.map(row => Object.keys(row).length));
      for (let i = startingColumnIndex; i <= maxColumns; i++) {
        newRow[`col_${i}`] = { checked: false }; // Ajoute une checkbox à false
      }
    }

    if (!Array.isArray(dataTabFertilization)) {
      setDataTabFertilization([oldRow2, oldRow, newRow, newRow, lastRow1, lastRow2, lastRow3]);
    } else {
      const newDataTabFertilization = [...dataTabFertilization];
      newDataTabFertilization.splice(newDataTabFertilization.length - 3, 0, newRow);
      setDataTabFertilization(newDataTabFertilization);
    }
    setNeedsSaveFertilization(true);
  };

  const addNewClimatRow = () => {
    const productNames = Object.keys(produitsCsv);
    const lastRow = { col_1: "Date de récolte", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", };
    const oldRow = { col_1: "Date", col_2: "Météo", col_3: "Vent", col_4: "Matériel et opérateur si plusieurs intervenants sur la culture	", col_5: "Produit commercial", col_6: "Substance active", col_7: "Cible", col_8: "Dose employée", col_9: "DAR", col_10: "Herbicide/fongicide/insecticide …	", col_11: "Justification	", };
    const newRow = { col_1: "", col_2: { type: "dropdown", value: "", options: ["Soleil", "Nuageux", "Nuit"] }, col_3: { type: "dropdown", value: "", options: ["Vent < 5Km/h", "Vent < 10Km/h", "Vent < 19Km/h"] }, col_4: "", col_5: { type: "dropdown", value: "", options: productNames, }, col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", };
    const startingColumnIndex = 12; // Commence à col_12
    if (dataTabClimat && dataTabClimat.length > 0) {
      const maxColumns = Math.max(...dataTabClimat.map(row => Object.keys(row).length));
      for (let i = startingColumnIndex; i <= maxColumns; i++) {
        newRow[`col_${i}`] = { checked: false }; // Ajoute une checkbox à false
      }
    }

    if (!Array.isArray(dataTabClimat)) {
      setDataTabClimat([oldRow, newRow, newRow, lastRow]);
    } else {
      const newDataTabClimat = [...dataTabClimat];
      // Insérer newRow avant la dernière ligne
      newDataTabClimat.splice(newDataTabClimat.length - 1, 0, newRow);
      setDataTabClimat(newDataTabClimat);
    }
    setNeedsSaveClimat(true);
  };

  const addNewFreeClimatRow = () => {
    const lastRow = { col_1: "Date de récolte", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", };
    const oldRow = { col_1: "Date", col_2: "Météo", col_3: "Vent", col_4: "Matériel et opérateur si plusieurs intervenants sur la culture	", col_5: "Produit commercial", col_6: "Substance active", col_7: "Cible", col_8: "Dose employée", col_9: "DAR", col_10: "Herbicide/fongicide/insecticide …	", col_11: "Justification	", };
    const newRow = { col_1: "", col_2: { type: "dropdown", value: "", options: ["Soleil", "Nuageux", "Nuit"] }, col_3: { type: "dropdown", value: "", options: ["Vent < 5Km/h", "Vent < 10Km/h", "Vent < 19Km/h"] }, col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", };
    const startingColumnIndex = 12; // Commence à col_12
    if (dataTabClimat && dataTabClimat.length > 0) {
      const maxColumns = Math.max(...dataTabClimat.map(row => Object.keys(row).length));
      for (let i = startingColumnIndex; i <= maxColumns; i++) {
        newRow[`col_${i}`] = { checked: false }; // Ajoute une checkbox à false
      }
    }

    if (!Array.isArray(dataTabClimat)) {
      setDataTabClimat([oldRow, newRow, newRow, lastRow]);
    } else {
      const newDataTabClimat = [...dataTabClimat];
      // Insérer newRow avant la dernière ligne
      newDataTabClimat.splice(newDataTabClimat.length - 1, 0, newRow);
      setDataTabClimat(newDataTabClimat);
    }
    setNeedsSaveClimat(true);
  };

  useEffect(() => {
    if (!isEqual(dataTabFertilization, undefined)) {
      const newTotals = dataTabFertilization.reduce((acc, row) => {
        acc.totalN += parseFloat(row.col_4) || 0;
        acc.totalP += parseFloat(row.col_5) || 0;
        acc.totalK += parseFloat(row.col_6) || 0;
        return acc;
      }, { totalP: 0, totalN: 0, totalK: 0 });

      setTotals(newTotals);
    }
  }, [dataTabFertilization]);

  const dataTabTotals = [
    { col_1: "Total N", col_2: totals.totalN },
    { col_1: "Total P", col_2: totals.totalP },
    { col_1: "Total K", col_2: totals.totalK }
  ];





  // -------------- FICHIER CSV -----------------

  const cleanString = (str) => {
    return str ? str.normalize("NFD").replace(/\p{Diacritic}/gu, "").trim() : str;
  };


  function getDropdownProduct(isDataTabExist) {
    fetch(`${apiUrl}/csv/get`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données CSV');
        }
        response.json().then(data => {
          const groupedProducts = data.reduce((acc, product) => {
            (acc[product.productName] = acc[product.productName] || []).push(product);
            return acc;
          }, {});
          setProduitsCsv(groupedProducts);
          const productTemp = Object.keys(groupedProducts);
          if (!isDataTabExist) {
            setDataTabClimat([{ col_1: "Date", col_2: "Météo", col_3: "Vent", col_4: "Matériel et opérateur si plusieurs intervenants sur la culture	", col_5: "Produit commercial", col_6: "Substance active", col_7: "Cible", col_8: "Dose employée", col_9: "DAR", col_10: "Herbicide/fongicide/insecticide …	", col_11: "Justification	" }, { col_1: "", col_2: { type: "dropdown", value: "", options: ["Soleil", "Nuageux", "Nuit"] }, col_3: { type: "dropdown", value: "", options: ["Vent < 5Km/h", "Vent < 10Km/h", "Vent < 19Km/h"] }, col_4: "", col_5: { type: "dropdown", value: "", options: productTemp, }, col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", col_12: "" }, { col_1: "Date de récolte", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", }]);
          }
        })
      })
      .catch(error => {
        console.error(error);
        setError(error.message);
      })

  }

  async function getDataClimat() {
    const userIdToUse = id || idUser;
    try {
      const response = await fetch(`${apiUrl}/climat/${userIdToUse}/${idCulture}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données');
      }
      const data = await response.json();
      setDataTabClimat(data.dataTab || []);
      if (!data.dataTab || data.dataTab.length === 0) {
        return false
      }
      return true
    } catch (error) {
      console.error(error);
    }

  }
  useEffect(() => {
    setIsLoading(true);
    getDataClimat()
      .then(isDataTabExist => {
        getDropdownProduct(isDataTabExist)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error);
      })

  }, [token, idUser, id, idCulture, navigate]);


  // -------------------------------- Menu déroulant --------------------------------


  const handleUserClick = (event, user) => {
    setSelectedUser(user);
    setAnchorEl(event.currentTarget);
    fetch(`${apiUrl}/culture/${user.idUser}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
    })
      .then(response => response.json())
      .then(data => {
        const extractedData = data.map(obj => {
          const campagne = obj.dataTab.find(row => row.col_1.includes('Campagne'))?.col_2 || 'non renseignée';
          const culture = obj.dataTab.find(row => row.col_1.includes('Culture'))?.col_2.value || 'non renseignée';
          setSelectedCulture(culture);
          return { campagne, culture, idCulture: obj.idCulture };
        });
        setCultureDetails(prevState => ({ ...prevState, [user.idUser]: extractedData }));
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données de conseil:', error);
      });
  };



  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewCulture = () => {
    const existingIdCulture = cultureDetails[selectedUser?.idUser]?.map(culture => culture.idCulture) ?? [];
    const lastIdCulture = existingIdCulture.length > 0 ? Math.max(...existingIdCulture) : 0;
    const newIdCulture = lastIdCulture > 0 ? lastIdCulture + 1 : 1;
    navigate(`/${selectedUser.idUser}/cahierCulture/${newIdCulture}`);
    setTechnicalManager("Nom du ou des responsables techniques:");
    setOperatorValidation("Validation par l'exploitant:\n\nLe:\n\nA:\n\nSignature:\n\n\n\n");
    setDataTabPlantation([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "" }]);
    setDataTabPrevious([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "" }]);
    setDataTabGroundWork([{ col_1: "Date", col_2: "Opération", col_3: "Opérateur", }, { col_1: "", col_2: "", col_3: "", }]);
    setDataTabFertilization([{ col_1: "Opérateur", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }, { col_1: "Date", col_2: "Type d'engrais (Minéral-Organique-Organominéral)", col_3: "Nom commercial", col_4: "N", col_5: "P", col_6: "K", col_7: "Dose/ha en kg ou L", col_8: "Mode d'épandage", }, { col_1: "", col_2: { type: "dropdown", value: "", options: ["Minéral", "Organique", "Orgaminérale", "Foliaire"] }, col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }, { col_1: "Total N", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }, { col_1: "Total P", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }, { col_1: "Total K", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }]);
    setDataTabRegistration([{ col_1: "Responsable technique (detenant certiphyto décideur)", col_2: "" }, { col_1: "Opérateur effectuant les traitements (détenant certiphyto opérateur )", col_2: "" }]);
    setDataTabClimat([{ col_1: "Date", col_2: "Météo", col_3: "Vent", col_4: "Matériel et opérateur si plusieurs intervenants sur la culture	", col_5: "Produit commercial", col_6: "Substance active", col_7: "Cible", col_8: "Dose employée", col_9: "DAR", col_10: "Herbicide/fongicide/insecticide …	", col_11: "Justification	", }, { col_1: "", col_2: { type: "dropdown", value: "", options: ["Soleil", "Nuageux", "Nuit"] }, col_3: { type: "dropdown", value: "", options: ["Vent < 5Km/h", "Vent < 10Km/h", "Vent < 19Km/h"] }, col_4: "", col_5: { type: "dropdown", value: "", options: productNames, }, col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", }, { col_1: "Date de récolte", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", }]);
    setDataTabCulture([{ col_1: "Campagne :", col_2: "" }, { col_1: "Culture :", col_2: { type: "dropdown", value: "", options: cropDropdown } }, { col_1: "Surface :", col_2: "" }, { col_1: "Parcelles concernées", col_2: "" }]);
    handleClose();
  };


  const handleOldCulture = (idCulture) => {
    if (idCulture.toString() === currentIdCulture) {
      handleClose();
      return;
    }
    navigate(`/${selectedUser.idUser}/cahierCulture/${idCulture}`);
    setTechnicalManager("Nom du ou des responsables techniques:");
    setOperatorValidation("Validation par l'exploitant:\n\nLe:\n\nA:\n\nSignature:\n\n\n\n");
    setDataTabPlantation([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "" }]);
    setDataTabPrevious([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "" }]);
    setDataTabGroundWork([{ col_1: "Date", col_2: "Opération", col_3: "Opérateur", }, { col_1: "", col_2: "", col_3: "", }]);
    setDataTabFertilization([{ col_1: "Opérateur", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }, { col_1: "Date", col_2: "Type d'engrais (Minéral-Organique-Organominéral)", col_3: "Nom commercial", col_4: "N", col_5: "P", col_6: "K", col_7: "Dose/ha en kg ou L", col_8: "Mode d'épandage", }, { col_1: "", col_2: { type: "dropdown", value: "", options: ["Minéral", "Organique", "Orgaminérale", "Foliaire"] }, col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }, { col_1: "Total N", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }, { col_1: "Total P", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }, { col_1: "Total K", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }]);
    setDataTabRegistration([{ col_1: "Responsable technique (detenant certiphyto décideur)", col_2: "" }, { col_1: "Opérateur effectuant les traitements (détenant certiphyto opérateur )", col_2: "" }]);
    setDataTabClimat([{ col_1: "Date", col_2: "Météo", col_3: "Vent", col_4: "Matériel et opérateur si plusieurs intervenants sur la culture	", col_5: "Produit commercial", col_6: "Substance active", col_7: "Cible", col_8: "Dose employée", col_9: "DAR", col_10: "Herbicide/fongicide/insecticide …	", col_11: "Justification	", }, { col_1: "", col_2: { type: "dropdown", value: "", options: ["Soleil", "Nuageux", "Nuit"] }, col_3: { type: "dropdown", value: "", options: ["Vent < 5Km/h", "Vent < 10Km/h", "Vent < 19Km/h"] }, col_4: "", col_5: { type: "dropdown", value: "", options: productNames, }, col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", }, { col_1: "Date de récolte", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", }]);
    handleClose();
  };

  // --------------------------------


  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
  }, [token, navigate]);

  const handlePrint = () => {
    window.print(); // Cette fonction déclenche l'impression du document
  };

  // useEffect(() => {
  //   // Vérifier si dataTabCulture est défini et a des éléments
  //   if (!dataTabCulture || dataTabCulture.length === 0) return;

  //   const parcellesRow = dataTabCulture.find(row => row.col_1.includes("Parcelles concernées"));
  //   if (parcellesRow && parcellesRow.col_2) {
  //     const numberOfParcelles = parseInt(parcellesRow.col_2, 10);

  //     // Vérifier si le Parcelles est un nombre valide
  //     if (!isNaN(numberOfParcelles) && numberOfParcelles >= 0) {
  //       const adjustDataTabWithParcelles = (dataTab, baseColumnIndex) => {
  //         return dataTab.map((row, rowIndex) => {
  //           const newRow = { ...row };

  //           // Supprimer les colonnes en trop
  //           Object.keys(newRow)
  //             .filter(key => parseInt(key.replace('col_', '')) > baseColumnIndex + numberOfParcelles)
  //             .forEach(key => delete newRow[key]);

  //           // Ajouter ou conserver les colonnes pour le Parcelles
  //           for (let i = baseColumnIndex + 1; i <= baseColumnIndex + numberOfParcelles; i++) {
  //             if (!newRow[`col_${i}`]) {
  //               // Assigner une chaîne vide pour la première ligne, sinon une checkbox
  //               newRow[`col_${i}`] = rowIndex === 0 ? "" : { checked: false };
  //             }
  //           }

  //           return newRow;
  //         });
  //       };
  //       const adjustDataTabWithParcellesClimat = (dataTab, baseColumnIndex) => {
  //         return dataTab.map((row, rowIndex) => {
  //           const newRow = { ...row };

  //           // Supprimer les colonnes en trop
  //           Object.keys(newRow)
  //             .filter(key => parseInt(key.replace('col_', '')) > baseColumnIndex + numberOfParcelles)
  //             .forEach(key => delete newRow[key]);

  //           // Ajouter ou conserver les colonnes pour le Parcelles
  //           for (let i = baseColumnIndex + 1; i <= baseColumnIndex + numberOfParcelles; i++) {
  //             if (!newRow[`col_${i}`]) {
  //               // Assigner une chaîne vide pour la première ligne, sinon une checkbox
  //               newRow[`col_${i}`] = (rowIndex === 0 || rowIndex === dataTab.length - 1) ? "" : { checked: false };
  //             }
  //           }
  //           return newRow;
  //         });
  //       };
  //       var newFertilization = [{ col_1: "Date", col_2: "Type d'engrais (Minéral-Organique-Organominéral)", col_3: "Nom commercial", col_4: "N", col_5: "P", col_6: "K", col_7: "Dose/ha en kg ou L", col_8: "Mode d'épandage", col_9: "Parcelle n°" }, { col_1: "", col_2: { type: "dropdown", value: "", options: ["Minéral", "Organique", "Orgaminérale", "Foliaire"] }, col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", col_9: "" }];
  //       var newClimat = [{ col_1: "Date", col_2: "Météo", col_3: "Vent", col_4: "Matériel et opérateur si plusieurs intervenants sur la culture	", col_5: "Produit commercial", col_6: "Substance active", col_7: "Cible", col_8: "Dose employée", col_9: "DAR", col_10: "Herbicide/fongicide/insecticide …	", col_11: "Justification	", col_12: "Parcelle n°" }, { col_1: "", col_2: { type: "dropdown", value: "", options: ["Soleil", "Nuageux", "Nuit"] }, col_3: { type: "dropdown", value: "", options: ["Vent < 5Km/h", "Vent < 10Km/h", "Vent < 19Km/h"] }, col_4: "", col_5: { type: "dropdown", value: "", options: productNames, }, col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", col_12: "" }, { col_1: "Date de récolte", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "", col_12: "" },];
  //       var newWork = [{ col_1: "Date", col_2: "Opération", col_3: "Opérateur", col_4: "Parcelle n°" }, { col_1: "", col_2: "", col_3: "", col_4: "" }]
  //       if (dataTabFertilization) {
  //         newFertilization = dataTabFertilization;
  //       }
  //       if (dataTabClimat) {
  //         newClimat = dataTabClimat;
  //       }
  //       if (dataTabGroundWork) {
  //         newWork = dataTabGroundWork;
  //       }
  //       const newDataTabFertilization = adjustDataTabWithParcelles(newFertilization, 8); // Ajuster en fonction de votre structure de données
  //       const newDataTabClimat = adjustDataTabWithParcellesClimat(newClimat, 11); // Ajuster en fonction de votre structure de données
  //       const newDataTabGroundWork = adjustDataTabWithParcelles(newWork, 3);

  //       // Mettre à jour l'état et sauvegarder les données
  //       if (!isEqual(dataTabFertilization, newDataTabFertilization)) {
  //         setDataTabFertilization(newDataTabFertilization);
  //         saveDataToFertilization(newDataTabFertilization);
  //       }

  //       if (!isEqual(dataTabClimat, newDataTabClimat)) {
  //         setDataTabClimat(newDataTabClimat);
  //         saveDataToClimat(newDataTabClimat);
  //       }

  //       if (!isEqual(dataTabGroundWork, newDataTabGroundWork)) {
  //         setDataTabGroundWork(newDataTabGroundWork);
  //         saveDataToGroundWork(newDataTabGroundWork);
  //       }
  //     }
  //   }
  // }, [dataTabCulture, dataTabFertilization, dataTabGroundWork, dataTabClimat, productNames, saveDataToClimat, saveDataToFertilization, saveDataToGroundWork]);

  useEffect(() => {
    if (!dataTabCulture || !dataTabPlotInventory) return;

    // Initialiser les tableaux locaux avec une structure de base ou les valeurs existantes
    let localDataTabGroundWork = Array.isArray(dataTabGroundWork)
      ? dataTabGroundWork
      : [{ col_1: "Date", col_2: "Opération", col_3: "Opérateur" }, { col_1: "", col_2: "", col_3: "" }];

    let localDataTabClimat = Array.isArray(dataTabClimat)
      ? dataTabClimat
      : [{ col_1: "Date", col_2: "Météo", col_3: "Vent", col_4: "Matériel et opérateur si plusieurs intervenants sur la culture", col_5: "Produit commercial", col_6: "Substance active", col_7: "Cible", col_8: "Dose employée", col_9: "DAR", col_10: "Herbicide/fongicide/insecticide …", col_11: "Justification" }, { col_1: "", col_2: { type: "dropdown", value: "", options: ["Soleil", "Nuageux", "Nuit"] }, col_3: { type: "dropdown", value: "", options: ["Vent < 5Km/h", "Vent < 10Km/h", "Vent < 19Km/h"] }, col_4: "", col_5: { type: "dropdown", value: "", options: productNames }, col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "" }, { col_1: "Date de récolte", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "" }];

    let localDataTabFertilization = Array.isArray(dataTabFertilization)
      ? dataTabFertilization
      : [{ col_1: "Opérateur", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "" }, { col_1: "Date", col_2: "Type d'engrais (Minéral-Organique-Organominéral)", col_3: "Nom commercial", col_4: "N", col_5: "P", col_6: "K", col_7: "Dose/ha en kg ou L", col_8: "Mode d'épandage" }, { col_1: "", col_2: { type: "dropdown", value: "", options: ["Minéral", "Organique", "Orgaminérale", "Foliaire"] }, col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }, { col_1: "Total N", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }, { col_1: "Total P", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }, { col_1: "Total K", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", }];

    const cultureValue = dataTabCulture.find(row => row.col_1.includes("Culture"))?.col_2.value || "";
    const matchingRows = dataTabPlotInventory.filter(row => row.col_5 === cultureValue);

    // Fonction pour ajouter les colonnes nécessaires
    const addColumns = (dataTab, columnsCount, startingColumnIndex, isFertilization, isClimat) => {
      if (!dataTab || !dataTab.length) return;

      // Construire la liste des valeurs potentielles basées sur `matchingRows`
      const potentialValues = matchingRows.map(row => row.col_1.toString());

      // Identifier les clés existantes dans la première ligne qui sont au-delà du seuil
      const existingColumnKeysBeyondThreshold = Object.keys(dataTab[0])
        .filter(key => key.startsWith('col_') && parseInt(key.replace('col_', '')) >= startingColumnIndex)
        .sort((a, b) => parseInt(a.replace('col_', '')) - parseInt(b.replace('col_', '')));

      // Identifier les valeurs existantes au-delà du seuil pour vérifier si elles correspondent aux valeurs potentielles
      const existingValuesBeyondThreshold = existingColumnKeysBeyondThreshold.map(key => dataTab[0][key]);

      // Filtrer pour obtenir les clés valides (correspondant aux valeurs potentielles)
      const validColumnKeys = existingColumnKeysBeyondThreshold.filter(key =>
        potentialValues.includes(dataTab[0][key])
      );

      // Supprimer les colonnes inutiles
      dataTab.forEach(row => {
        existingColumnKeysBeyondThreshold.forEach(key => {
          if (!validColumnKeys.includes(key)) {
            delete row[key];
          }
        });
      });

      // Ajouter ou mettre à jour les colonnes nécessaires
      return dataTab.map((row, rowIndex) => {
        let newRow = { ...row };

        // Gérer uniquement les colonnes au-delà du seuil
        potentialValues.forEach((potentialValue, i) => {
          const colIndex = startingColumnIndex + i;
          const colKey = `col_${colIndex}`;

          if (isFertilization) {
            // Cas spécial pour Fertilization :
            // 1ère ligne = case vide, 2ème ligne = numéro parcelle, 3ème ligne et suivantes = checkbox
            if (rowIndex === 0 || rowIndex === localDataTabFertilization.length - 1 || rowIndex === localDataTabFertilization.length - 2 || rowIndex === localDataTabFertilization.length - 3) {
              newRow[colKey] = row[colKey] !== undefined ? row[colKey] : ""; // Dernière ligne : case vide
            } else if (rowIndex === 1) {
              newRow[colKey] = potentialValue; // Deuxième ligne : numéro de parcelle
            } else {
              newRow[colKey] = row[colKey]?.checked !== undefined ? row[colKey] : { checked: false }; // Conserver l'état des checkboxes
            }
          } else if (isClimat) {
            if (rowIndex === localDataTabClimat.length - 1) {
              newRow[colKey] = row[colKey] !== undefined ? row[colKey] : ""; // Dernière ligne : case vide
            } else if (rowIndex === 0) {
              newRow[colKey] = potentialValue; // Première ligne : numéro de parcelle
            } else {
              newRow[colKey] = row[colKey]?.checked !== undefined ? row[colKey] : { checked: false }; // Conserver l'état des checkboxes
            }
          } else {
            // Pour les autres tableaux :
            // 1ère ligne = numéro parcelle, autres lignes = checkbox
            if (rowIndex === 0) {
              newRow[colKey] = potentialValue; // Première ligne : numéro de parcelle
            } else {
              // Conserver l'état de la checkbox si elle existe, sinon l'initialiser à false
              newRow[colKey] = row[colKey]?.checked !== undefined ? row[colKey] : { checked: false };
            }
          }
        });

        return newRow;
      });
    };

    if (matchingRows?.length > 0) {
      localDataTabGroundWork = addColumns(localDataTabGroundWork, matchingRows.length, 4, false, false);
      localDataTabClimat = addColumns(localDataTabClimat, matchingRows.length, 12, false, true);
      // localDataTabFertilization = addColumns(localDataTabFertilization, matchingRows.length, 9, true, false);

      setDataTabGroundWork(localDataTabGroundWork);
      setDataTabClimat(localDataTabClimat);
      // setDataTabFertilization(localDataTabFertilization);

    }
  }, [dataTabCulture, dataTabPlotInventory]);

  useEffect(() => {
    if (!dataTabCulture || !dataTabPlotInventory) return;

    let localDataTabFertilization = Array.isArray(dataTabFertilization)
      ? dataTabFertilization
      : [
        { col_1: "Opérateur", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "" },
        { col_1: "Date", col_2: "Type d'engrais (Minéral-Organique-Organominéral)", col_3: "Nom commercial", col_4: "N", col_5: "P", col_6: "K", col_7: "Dose/ha en kg ou L", col_8: "Mode d'épandage" },
        { col_1: "", col_2: { type: "dropdown", value: "", options: ["Minéral", "Organique", "Orgaminérale", "Foliaire"] }, col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "" },
        { col_1: "Total N", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "" },
        { col_1: "Total P", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "" },
        { col_1: "Total K", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "" }
      ];

    const cultureValue = dataTabCulture.find(row => row.col_1.includes("Culture"))?.col_2.value || "";
    const matchingRows = dataTabPlotInventory.filter(row => row.col_5 === cultureValue);

    // Fonction pour ajouter les colonnes nécessaires
    const addColumns = (dataTab, columnsCount, startingColumnIndex) => {
      if (!dataTab || !dataTab.length) return;

      // Construire la liste des valeurs potentielles basées sur `matchingRows`
      const potentialValues = matchingRows.map(row => row.col_1.toString());

      // Identifier les clés existantes dans la première ligne qui sont au-delà du seuil
      const existingColumnKeysBeyondThreshold = Object.keys(dataTab[0])
        .filter(key => key.startsWith('col_') && parseInt(key.replace('col_', '')) >= startingColumnIndex)
        .sort((a, b) => parseInt(a.replace('col_', '')) - parseInt(b.replace('col_', '')));

      // Filtrer pour obtenir les clés valides (correspondant aux valeurs potentielles)
      const validColumnKeys = existingColumnKeysBeyondThreshold.filter(key =>
        potentialValues.includes(dataTab[1][key])
      );

      // Supprimer les colonnes inutiles
      dataTab.forEach(row => {
        existingColumnKeysBeyondThreshold.forEach(key => {
          if (!validColumnKeys.includes(key)) {
            delete row[key];
          }
        });
      });

      // Ajouter ou mettre à jour les colonnes nécessaires
      return dataTab.map((row, rowIndex) => {
        let newRow = { ...row };

        // Gérer uniquement les colonnes au-delà du seuil
        potentialValues.forEach((potentialValue, i) => {
          const colIndex = startingColumnIndex + i;
          const colKey = `col_${colIndex}`;
          // Cas spécial pour Fertilization :
          // 1ère ligne = case vide, 2ème ligne = numéro parcelle, 3ème ligne et suivantes = checkbox
          if (rowIndex === 0 || rowIndex === localDataTabFertilization.length - 1 || rowIndex === localDataTabFertilization.length - 2 || rowIndex === localDataTabFertilization.length - 3) {
            newRow[colKey] = row[colKey] !== undefined ? row[colKey] : ""; // Dernière ligne : case vide
          } else if (rowIndex === 1) {
            newRow[colKey] = potentialValue; // Deuxième ligne : numéro de parcelle
          } else {
            newRow[colKey] = row[colKey]?.checked !== undefined ? row[colKey] : { checked: false }; // Conserver l'état des checkboxes
          }
        });

        return newRow;
      });
    };

    if (matchingRows?.length > 0) {
      localDataTabFertilization = addColumns(localDataTabFertilization, matchingRows.length, 9);
      setDataTabFertilization(localDataTabFertilization);
    }
    setColumnsReady(true)
  }, [dataTabCulture, dataTabPlotInventory, fertilizationDataReady]);




  useEffect(() => {

    const updatePlotInfoInCulture = () => {
      const cultureValue = dataTabCulture.find(row => row.col_1.includes("Culture"))?.col_2.value || "";
      const relatedPlots = dataTabPlotInventory
        .filter(row => row.col_5 === cultureValue)
        .map(row => {
          // Extraire la valeur de col_1 s'il s'agit d'une chaîne, sinon retourner 'Non spécifié'
          console.log(row.col_1)
          return row.col_1 !== undefined && row.col_1 !== null && row.col_1.toString().trim() ? row.col_1.toString().trim() : 'Non spécifié';
        })
        .join(', ');

      setDataTabCulture(currentDataTabCulture => currentDataTabCulture.map((item, index) => {
        if (index === 3) { // L'index 3 correspond à la ligne 4
          return { ...item, col_2: relatedPlots };
        }
        return item;
      }));
      setSelectedCulture(cultureValue)
    };

    if (dataTabCulture && dataTabCulture.find(row => row.col_1.includes("Culture"))?.col_2.value) {
      updatePlotInfoInCulture();
    }

  }, [dataTabPlotInventory, selectedCulture,]); // Dépendances sur dataTabPlotInventory et selectedCulture

  useEffect(() => {
    // Extraire la valeur de la ligne de "Culture"
    if (dataTabCulture) {
      const cultureValue = dataTabCulture.find(row => row.col_1.includes("Culture"))?.col_2.value;

      // Mettre à jour selectedCulture si la nouvelle valeur est différente de l'ancienne
      if (cultureValue && cultureValue !== selectedCulture) {
        setSelectedCulture(cultureValue);
      }
    }
  }, [dataTabCulture]); // Dépendance sur dataTabCulture




  const handleNavigate = () => {
    const userIdToUse = id || idUser;
    navigate(`/plotInventory/${userIdToUse}`);
  };

  const exportToExcel = (dataTab, name) => {
    // Vérifier que dataTab n'est pas vide
    if (!dataTab || dataTab.length === 0) {
      console.error("Le tableau de données est vide ou non défini.");
      return;
    }
    // Transformer les données pour l'export Excel
    const transformedData = dataTab.map(row => {
      const transformedRow = {};
      Object.entries(row).forEach(([key, value]) => {
        // Pour les dropdowns et les checkboxes, traiter les valeurs spécifiquement
        if (typeof value === 'object' && value !== null) {
          if ('value' in value) { // Gérer les dropdowns
            transformedRow[key] = value.value;
          } else if ('checked' in value) { // Gérer les checkboxes
            transformedRow[key] = value.checked ? "Oui" : "Non"; // ou utiliser "true"/"false", selon ce que vous préférez
          } else {
            transformedRow[key] = ""; // Autres objets non gérés
          }
        } else {
          // Copier la valeur telle quelle pour les autres cas
          transformedRow[key] = value;
        }
      });
      return transformedRow;
    });

    // Suppression des clés des objets (noms des colonnes) dans le fichier Excel
    // En supposant que 'transformedData' est maintenant un tableau d'objets sans clés comme propriétés
    const dataForExcel = transformedData.map(row =>
      Object.values(row) // Créer des tableaux de valeurs pour chaque ligne
    );

    const ws = XLSX.utils.aoa_to_sheet(dataForExcel); // Utiliser aoa_to_sheet pour les tableaux d'arrays
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Tableaux");

    // Créer un nom de fichier
    const formatFileNameDate = new Date().toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).split('/').join('-');

    let fileName = `${formatFileNameDate}-${userDataRaisonSociale}-${dataTabCulture[1]?.col_2}-${name}.xlsx`;

    // Écrire le fichier
    XLSX.writeFile(wb, fileName);
  };

  const exportAllDataToSingleSheet = () => {
    const wb = XLSX.utils.book_new(); // Créer un nouveau fichier Excel

    // Tableau pour contenir toutes les données à exporter
    let allData = [];

    const addTableToData = (dataTab, title) => {
      if (!dataTab || dataTab.length === 0) {
        return;
      }

      // Ajouter un titre avant chaque tableau pour distinguer les sections
      allData.push([title]);

      // Ajouter les données du tableau au format Excel
      const transformedData = dataTab.map(row => {
        return Object.fromEntries(Object.entries(row).map(([key, val]) => {
          if (typeof val === 'object' && val !== null && 'value' in val) {
            return [key, val.value]; // Gérer les dropdowns
          } else if (typeof val === 'object' && 'checked' in val) {
            return [key, val.checked ? "Oui" : "Non"]; // Gérer les checkboxes
          }
          return [key, val]; // Sinon, ajouter la valeur telle quelle
        }));
      });

      // Ajouter les lignes transformées à allData
      transformedData.forEach(row => {
        allData.push(Object.values(row)); // On récupère juste les valeurs des objets pour les mettre dans un array
      });

      // Ajouter plusieurs lignes vides après chaque tableau pour séparer visuellement (ici 3 lignes)
      allData.push([]);
      allData.push([]);
      allData.push([]);
    };

    // Ajoutez chaque tableau
    addTableToData(dataTabFarmingAll, 'Identification de l\'exploitation agricole');
    addTableToData(dataTabCulture, 'Culture');
    addTableToData(dataTabPlantation, 'Plantation');
    addTableToData(dataTabPrevious, 'Précédents culturaux');
    addTableToData(dataTabGroundWork, 'Travail du sol');
    addTableToData(dataTabFertilization, 'Fertilisation');
    addTableToData(dataTabRegistration, 'Enregistrements phytosanitaires');
    addTableToData(dataTabClimat, 'Climat');

    // Ajout des commentaires (ces valeurs viennent de votre state)
    allData.push(['Commentaires']);
    allData.push(['Nom du responsable technique :', commentaryTechnicalManager || '']);
    allData.push(['Validation par l\'exploitant :', commentaryOperatorValidation || '']);

    // Convertir le tableau en feuille Excel
    const ws = XLSX.utils.aoa_to_sheet(allData); // Utiliser aoa_to_sheet pour un tableau de tableaux (Array of Arrays)

    // Ajouter la feuille au workbook
    XLSX.utils.book_append_sheet(wb, ws, "Toutes les données");

    // Créer le fichier Excel
    const fileName = `Export_${new Date().toISOString().replace(/[\W_]+/g, '')}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };



  return (
    <>
      <style>
        {`
          @media print {
            .print-hide, .MuiButton-root, .user-name-button, .bouton-personnalise, .bouton-personnalise-2, .navbar, .bouton-personnalise-4, .bouton-personnalise-3 {
              display: none !important; /* Cacher les boutons et autres éléments non désirés */
            }
          
            .dynamic-table, .dynamic-table-row {
              page-break-inside: avoid; /* Essayer d'éviter la coupure des tableaux */
            }

            .dynamic-table, .commentary {
              margin-bottom: 50px; /* Ajoutez plus d'espacement en bas de chaque tableau/commentaire */
            }

            .date-input-view {
              display: none; /* Masque l'input de date en mode impression */
            }
          
            .date-text-view {
              display: block !important; /* Affiche le texte de la date en mode impression */
              border: none; /* Supprime tout cadre qui pourrait être appliqué */
              background: transparent; /* Assure un fond transparent */
              padding: 0; /* Supprime le padding pour éviter tout espace inutile */
              margin: 0 auto; /* Centrage horizontal */
              font-size: 18px; /* Assure que la taille de la police est appropriée */
            }

            .scale-table-for-print, .reduce-table{
              transform: scale(0.99); /* Ajustez ce facteur selon vos besoins */
              transform-origin: top left; /* Ajuste le point d'origine de la transformation */
            }
          }
          
          /* Styles par défaut (pas en mode impression) */
          .date-input-view {
            display: block; /* Affiche l'input de date normalement */
          }
          
          .date-text-view {
            display: none; /* Masque le texte de la date normalement */
          }

          #tableauAdapte {
            font-size: 10px; /* Réduction de la taille de la police */
            transform: scale(0.8); /* Ajustement de la taille */
            transform-origin: top left; /* Origine de la transformation */
            /* Ajoutez d'autres styles d'adaptation ici */
          }
          
          }
        `}
      </style>

      <Navbar />
      <Header />
      {isLoading && <Spinner />}
      <Button className="user-name-button"
        onClick={(e) => handleUserClick(e, selectedUser)}
        style={{
          float: 'right', marginRight: '40px', border: '1px solid #1E8449 ', padding: '5px 10px', backgroundColor: '#0E6655',
          color: 'white'
        }}>
        Sélectionner archive ▼
      </Button>
      <div style={{ position: 'absolute', top: 0, right: 0, marginTop: '64px', marginRight: '16px' }}></div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem sx={{ py: 0.5 }}> {/* Ajuster l'espacement vertical du MenuItem */}
          <TextField
            fullWidth
            label="Rechercher par numéro ou date" // Mettre à jour le libellé
            variant="outlined"
            size="small" // Utiliser la taille "small" pour le TextField
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-input": {
                fontSize: '0.875rem', // Réduire la taille de la police
              },
              "& .MuiInputLabel-root": {
                fontSize: '0.875rem', // Réduire la taille de la police du label
              },
              "& .MuiSvgIcon-root": {
                fontSize: '1rem', // Réduire la taille des icônes
              },
              mb: 1, // Ajuster la marge basse pour séparer du bouton
            }}
          />
        </MenuItem>
        {(isCahierCulture || isAdmin) && (
          <MenuItem onClick={handleNewCulture} sx={{ my: 0.5, justifyContent: 'center' }}>
            <Button
              sx={{
                fontSize: '0.875rem',
                padding: '4px 10px', // Réduire le padding pour diminuer la hauteur du bouton
                lineHeight: 1, // Ajuster la hauteur de ligne si nécessaire
                textTransform: 'none', // Optionnel, pour enlever la transformation en majuscules
              }}
            >
              + Créer Nouvelle Fiche
            </Button>
          </MenuItem>
        )}
        {cultureDetails[selectedUser?.idUser]?.sort((a, b) => b.idCulture - a.idCulture).map((culture, index) => (
          <MenuItem key={index} onClick={() => handleOldCulture(culture.idCulture)} sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.875rem', py: 0.5 }}>
            {`Culture : ${culture.culture} - ${culture.campagne}`}
          </MenuItem>
        ))}

      </Menu>

      <div className="relative" style={{ marginTop: '50px' }}>

        <button onClick={handleNavigate} className="bouton-personnalise">
          Inventaire Parcelle
        </button>

        <DynamicTable onDataChange={handleDataChangeFarmingAll} dataTab={dataTabFarmingAll} columnNames={["Nom, Prénom & Raison Sociale", "Adresse", "Téléphone"]} title="Identification de l'exploitation agricole" titleStyle={{ backgroundColor: '', textAlign: 'center', padding: '10px' }} LockFirstRow />
        <DynamicTable onDataChange={handleDataChangeCulture} dataTab={dataTabCulture} columnNames={["", "Culture"]} LockFirstThirdColumns hideTitle LockFourthRowFirstColumn />
        <DynamicTable onDataChange={handleDataChangePlantation} showDeleteRowUser={true} showCheckboxes={true} dataTab={dataTabPlantation} columnNames={["Parcelles concernées : (numéro) ", "Nom ou N° ilot PAC", "Date de semis/plantation", "Variété", "Densité/ha", "Origine des plants"]} title="Semis / plantation" titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} name="plantation" />
        <button
          onClick={addNewPlantationRow}
          className="bouton-personnalise"
        >
          Ajouter Ligne
        </button>
      </div>
      <div className="reduce-table">
        <DynamicTable onDataChange={handleDataChangePrevious} showDeleteRowUser={true} showCheckboxes={true} dataTab={dataTabPrevious} columnNames={["Parcelle n°", "Précédents (année N-1)", "Résidus enfouis année N-1)", "Précédents (année N-2)", "Résidus enfouis année (N-2)", "Précédents (année N-3)", "Résidus enfouis année (N-3)"]} title="Précédents culturaux" titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} name="previous" />
      </div>
      <button
        onClick={addNewPreviousRow}
        className="bouton-personnalise"
      >
        Ajouter Ligne
      </button>
      <div className="relative" style={{ overflowX: 'auto' }}>

        <DynamicTable onDataChange={handleDataChangeGroundWork} dontDeleteFirstRow={true} showCheckboxes={true} dataTab={dataTabGroundWork} columnNames={[]} title="Travail du sol et désherbage mécanique /manuel" titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} name="groundWork" LockFirstRow />
        <button
          onClick={addNewGroundWorkRow}
          className="bouton-personnalise"
        >
          Ajouter Ligne
        </button>
        <Title text="Fertilisation" />
        <div style={{ overflowX: 'auto' }}>
          <DynamicTable onDataChange={handleDataChangeFertilization} showCheckboxes={true} dataTab={dataTabFertilization} columnNames={["Date", "Type d'engrais (Minéral-Organique-Organominéral)", "Nom commercial", "N", "P", "K", "Dose/ha en kg ou L", "Mode d'épandage",]} title="Fertilisation" titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} hideTitle name="fertilization" LockSecondRow lockLastThreeRowsUpToCol8 dontDeleteLastThreeRows />
        </div>
        <button
          onClick={addNewFertilizationRow}
          className="bouton-personnalise"
        >
          Ajouter Ligne
        </button>
        <DynamicTable onDataChange={handleDataChangeRegistration} dataTab={dataTabRegistration} columnNames={[]} title="Enregistrements phytosanitaires" titleStyle={{ backgroundColor: '', textAlign: 'center', padding: '10px' }} LockFirstThirdColumns />
        <div style={{ overflowX: 'auto' }}>
          <DynamicTable onDataChange={handleDataChangeClimat} dontDeleteLastRow={true} showCheckboxes={true} dataTab={dataTabClimat} columnNames={["Date", "Météo", "Vent", "Matériel et opérateur si plusieurs intervenants sur la culture", "Produit commercial", "Substance active", "Cible", "Dose employée", "DAR", "Herbicide/fongicide/insecticide …	", "Justification",]} title="" titleStyle={{ backgroundColor: '', textAlign: 'center', padding: '10px' }} produitsCsv={produitsCsv} hideTitle onDropdownChange={handleDropdownChange} name="climat" LockFirstRow lockLastRowUpToCol11 />
        </div>
        <button
          onClick={addNewClimatRow}
          className="bouton-personnalise"
        >
          Ajouter Ligne
        </button>
        <button
          onClick={addNewFreeClimatRow}
          className="bouton-personnalise"
        >
          Ajouter Ligne Libre
        </button>
        <div className="container mx-auto relative z-10 print-hide">
          <div className="flex flex-col items-center justify-center min-h-screen">
            {error && <p>Erreur : {error}</p>}
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div style={{ marginTop: '40px', marginBottom: '40px' }}>
            * A noter que pour le référentiel GlobalG.A.P., nous rajoutons 1 jour à la DAR produit pour déterminer la première date de récolte possible
          </div>
          <Commentary onDataChange={handleDataChangeOperatorValidation} commentaryData={commentaryOperatorValidation} title="" titleStyle="" placeholder={""} />
          <button onClick={exportAllDataToSingleSheet} className="bouton-personnalise">
            Exporter Tous les Tableaux vers Excel
          </button>

          <button
            onClick={handlePrint}
            className="bouton-personnalise-2"
          >
            Imprimer
          </button>
        </div>
      </div>

    </>
  );
}

