import { Container } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

const ConfirmationPage = () => {
    const navigate = useNavigate();
    const contactName = "Virginie";
    const contactPhone = "xx.xx.xx.xx.xx";

    const handleBackToLogin = () => {
        navigate('/login');
    };

    const buttonStyle = {
        marginTop: '20px',
        padding: '10px 15px',
        fontSize: '16px',
        backgroundColor: '#4CAF50', // Couleur de fond verte
        color: 'white', // Texte blanc
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', // Ombre pour un effet en relief
        transition: '0.3s', // Transition pour un effet de survol
    };

    return (
        <div style={{
            backgroundImage: 'url("/fond_login.jpeg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: 3,
                        borderRadius: 2,
                        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src="/logo.png" alt="Logo" style={{ width: '100px', height: 'auto' }} />
                    <h1>Votre enregistrement à bien été effectué.</h1>
                    <p>Notre équipe va vous contacter, restez proche de votre téléphone.</p>
                    <button onClick={handleBackToLogin} className='bouton-personnalise' >Retour à la connexion</button>
                </Box>
            </Container>
        </div>

    );
};

export default ConfirmationPage;
