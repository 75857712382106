import React from 'react';
import { CSSProperties } from 'react';

const PageTitle: React.FC<{ title: string }> = ({ title }) => {
  const titleStyle: CSSProperties = {
    textAlign: 'center',
    color: 'black',
    padding: '10px',
    borderRadius: '5px',
    margin: '10px 0'
  };

  return (
    <h2 style={titleStyle}>{title}</h2>
  );
};

export default PageTitle;
