import React, { useState } from 'react';

const TripleSwitch = ({ initialOption = 'tous', onChange }) => {
    const [selectedOption, setSelectedOption] = useState(initialOption);

    const handleChange = (option) => {
        setSelectedOption(option);
        if (onChange) {
            onChange(option);
        }
    };

    const buttonStyle = (option) => ({
        backgroundColor: selectedOption === option ? '#0f7d21' : '#e0e0e0', // Differentiate selected option
        color: selectedOption === option ? 'white' : 'black', // Text color contrast
        border: 'none',
        borderRadius: '5px', // Rounded corners
        padding: '8px 15px',
        margin: '0 5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s, color 0.3s', // Smooth transition for color changes
        fontWeight: 'bold',
        textTransform: 'uppercase', // Uppercase text for a cleaner look
    });

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button style={buttonStyle('actif')} onClick={() => handleChange('actif')}>
                Actif
            </button>
            <button style={buttonStyle('inactif')} onClick={() => handleChange('inactif')}>
                Inactif
            </button>
            <button style={buttonStyle('tous')} onClick={() => handleChange('tous')}>
                Tous
            </button>
        </div>
    );
};

export default TripleSwitch;
