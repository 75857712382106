import React, { useState, useEffect } from 'react';
import '../App.css';
import { useParams } from 'react-router-dom';
import { Buffer } from 'buffer';
import Popper from '@mui/material/Popper';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function CustomPopper(props) {
  return (
    <Popper {...props} style={{ width: 'fit-content' }} />
  );
}

interface DynamicTableProps {
  onDataChange: (newData: any) => void;
  dataTab: any[]; // Remplacez 'any' par un type plus précis si possible
  columnNames?: string[]; // '?' indique que cette prop est optionnelle
  title?: string;
  titleStyle?: React.CSSProperties;
  showCheckboxes?: boolean;
  handleCheckboxChange?: (rowIndex: number, columnKey: string, checked: boolean) => void;
  produitsCsv?,
  editableAdmin?: boolean;
  showDeleteRow?: boolean;
  showDeleteRowUser?: boolean;
  showDeleteColumn?: boolean;
  onDeleteRow?: (rowIndex: number) => void; // Callback pour la suppression d'une ligne
  onDeleteColumn?: (columnId: string) => void;
  idUser?: number | string;
  className?: string;
  hideTitle?: boolean;
  onDropdownChange?: (rowIndex: number, columnId: string, newValue: string) => void;
  dontDeleteFirstRow?: boolean;
  dontDeleteSecondRow?: boolean;
  dontDeleteLastRow?: boolean;
  dontDeleteLastThreeRows?: boolean;
  name?: string;
  showCheckboxesInventory?: boolean;
  boldFirstThirdColumns?: boolean;
  boldAndLockFirstFourthRows?: boolean;
  LockFirstThirdColumns?: boolean;
  LockFirstRow?: boolean;
  LockSecondRow?: boolean;
  LockFourthRowFirstColumn?: boolean;
  LockFirstSecondRow?: boolean;
  LockFirstThirdFifthSeventhRow?: boolean;
  fullWidth?: boolean;
  lockLastThreeRowsUpToCol8?: boolean;
  lockLastRowUpToCol11?: boolean;
}

function DynamicTable({ idUser, onDataChange, dataTab, columnNames = [], title, titleStyle, showCheckboxes = false, produitsCsv, editableAdmin = false, showDeleteRow = false, showDeleteRowUser = false, showDeleteColumn = false, onDeleteRow, onDeleteColumn, className = "", hideTitle = false, onDropdownChange, dontDeleteFirstRow = false, dontDeleteSecondRow = false, dontDeleteLastRow = false, dontDeleteLastThreeRows = false, name = "", showCheckboxesInventory = false, boldFirstThirdColumns = false, boldAndLockFirstFourthRows = false, LockFirstThirdColumns = false, LockFirstRow = false, LockSecondRow = false, LockFirstThirdFifthSeventhRow = false, LockFirstSecondRow = false, fullWidth = false, LockFourthRowFirstColumn = false, lockLastThreeRowsUpToCol8 = false, lockLastRowUpToCol11 = false, }: DynamicTableProps) {
  const [editingCell, setEditingCell] = useState({ rowIndex: null, colId: null });
  const [getToken, setGetToken] = useState(null);
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const tokenBody = token.split('.');
      if (tokenBody.length > 1) {
        try {
          const decodedToken = JSON.parse(Buffer.from(tokenBody[1], 'base64').toString());
          setGetToken(decodedToken);
        } catch (error) {
          console.error('Erreur lors du décodage du token:', error);
        }
      }
    }


    const removeColumn = (colId) => {
      // Suppression de la colonne du state local
      const newColumns = columns.filter(col => col.id !== colId);
      const newData = data.map(row => {
        const newRow = { ...row };
        delete newRow[colId];
        return newRow;
      });

      setColumns(newColumns);
      setData(newData);
      onDataChange(newData); // Notifie le parent du changement de données

      // Appel du callback si fourni
      if (onDeleteColumn) {
        onDeleteColumn(colId);
      }
    };

    // Définir les colonnes et les données en fonction de la prop 'data'
    if (dataTab && dataTab.length > 0) {
      const firstRow = dataTab[0];
      const newColumns = Object.keys(firstRow).map((key, index) => {
        // Utiliser uniquement les noms de colonnes fournis, sinon ne pas définir de nom
        return {
          name: columnNames[index] || '',
          id: key
        };
      });

      setColumns(newColumns);
      setData(dataTab);
    }
  }, [dataTab, columnNames]);


  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({ type: '', id: null });



  const removeRow = async (rowIndex, name) => {
    const rowId = data[rowIndex].id; // Assurez-vous que chaque élément de dataTab a un id unique
    const userIdToUse = id || idUser; // Supposons que vous ayez l'idUser disponible dans le composant
    try {
      const response = await fetch(`${apiUrl}/${name}/deleteRow/${userIdToUse}/${rowId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // ou tout autre mécanisme d'authentification
        },
      });

      if (response.ok) {
        // Mise à jour de l'état pour refléter la suppression de la ligne
        const newData = data.filter((_, index) => index !== rowIndex);
        setData(newData);
        onDataChange(newData); // Notifiez le composant parent du changement
      } else {
        throw new Error('Erreur lors de la suppression de la ligne');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de la ligne:', error);
    }
  };

  const handleProductChange = (e, rowIndex, colId) => {
    const productId = e.target.value;
    const selectedProduct = produitsCsv.find(produit => produit.id === productId);

    if (selectedProduct) {
      // Mettre à jour les détails du produit dans la ligne correspondante
      const updatedData = data.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            // Supposons que 'substance', 'target', 'dose', etc. sont les ID des colonnes correspondantes
            substance: selectedProduct.substance,
            target: selectedProduct.target,
            dose: selectedProduct.dose
            // Ajoutez d'autres champs si nécessaire
          };
        }
        return row;
      });

      setData(updatedData);
      onDataChange(updatedData);
    }
  };

  const handleCellChange = (e, rowIndex, colId) => {
    const updatedData = data.map((row, index) => {
      if (index === rowIndex) {
        let updatedCell;

        if (typeof row[colId] === 'object' && row[colId].type === 'dropdown') {
          // Pour les menus déroulants, utiliser e.target.value
          updatedCell = { ...row[colId], value: e.target.value };
        } else {
          // Pour les cellules éditables normales, utiliser innerText
          const newValue = e.target.innerText;
          updatedCell = newValue;
        }
        if (typeof cell === 'object' && cell.type === 'dropdown' && cell.dropdownType === 'product') {
          handleProductChange(e, rowIndex, colId);
          return;
        }

        return { ...row, [colId]: updatedCell };
      }
      return row;
    });

    setData(updatedData);
    onDataChange(updatedData);
  };

  const getWidthForColumnName = (columnName) => {
    const columnWidths = {
      'Produit commercial': 490,
      'Cible': 680,
      'Dose employée': 125,
      'Météo': 150,
      'DAR': 125,
      'Type d\'engrais (Minéral-Organique-Organominéral)': 190,
      'Maladies/Ravageurs/Adventices/Opérations culturales': 420,
      'Culture': 300,
    };

    return columnWidths[columnName] || 200;
  };

  function getColumnStyles(columnName) {
    if (columnName === "Traçabilité automatique (cocher si pas de traçabilité)") {
      return {
        whiteSpace: 'normal', // Permet le retour à la ligne
        wordWrap: 'break-word', // Casse les mots pour éviter le débordement
        maxWidth: '200px', // Définit une largeur maximale pour la colonne
      };
    }
    if (columnName === "A" || columnName === "B" || columnName === "C" || columnName === "D" || columnName === "E" || columnName === "F") {
      return {
        width: '10%',
        textAlign: 'center',
        padding: '8px',
        border: '1px solid #ccc',
        color: 'transparent',
        visibility: 'hidden',
      };
    }
    return {};
  }

  const handleCheckboxChange = (checked, rowIndex, colId) => {
    const updatedData = data.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, [colId]: { ...row[colId], checked } };
      }
      return row;
    });
    setData(updatedData);
    onDataChange && onDataChange(updatedData);
  };

  const handleCheckboxChangeInventory = (checked, rowIndex, colId) => {
    const updatedData = data.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, [colId]: { ...row[colId], checked } };
      }
      if (index !== 0) { // Exclut la première ligne de toute modification
        if (checked) {
          // Pour la numérotation, on commence à 1 pour la ligne suivant directement la première ligne
          row = { ...row, col_1: index }; // Assurez-vous que 'firstCol' est la clé de la première colonne
        } else {
          row = { ...row, col_1: "" };
        }
      }
      return row;
    });
    setData(updatedData);
    onDataChange && onDataChange(updatedData);
  };


  const isEditable = () => !editableAdmin || (editableAdmin && getToken?.admin);


  return (
    <>
      <style>
        {`
          @media print {
            .print-hide {
              display: none;
            }
          `}
      </style>

      <div className="dynamic-table">
        <div className="button-container print-hide">
          {/* Other buttons or content */}
        </div>
        {title && <h2 style={{ textAlign: 'center', width: '100%', ...titleStyle }}>{title}</h2>}
        <table>
          <thead>
            {!hideTitle && ( // Déplacer la condition pour englober tout le <tr>
              <tr>
                {columnNames.length > 0 && columns.map((col) => (
                  <th key={col.id} style={{ textAlign: 'left', padding: '8px', border: '1px solid #ccc', ...getColumnStyles(col.name), }}>
                    {col.name}
                  </th>
                ))}
              </tr>
            )}
            {fullWidth && ( // Déplacer la condition pour englober tout le <tr>
              <tr>
                {columnNames.length > 0 && columns.map((col) => (
                  <th key={col.id} style={{ ...getColumnStyles(col.name) }}>
                    {col.name}
                  </th>
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col, colIndex) => {
                  const cell = row[col.id];
                  const isSpecialRow = rowIndex === 0 || rowIndex === 3;
                  const isCellLocked = lockLastThreeRowsUpToCol8 && (rowIndex >= data.length - 3);
                  const isCellLocked2 = lockLastRowUpToCol11 && (rowIndex == data.length - 1) && (colIndex <= 10);
                  const cellStyle = dontDeleteFirstRow || dontDeleteSecondRow || dontDeleteLastRow && rowIndex === 0 || (boldFirstThirdColumns && (colIndex === 0 || colIndex === 2)) || (isSpecialRow && boldAndLockFirstFourthRows) ? { fontWeight: 'bold' } : {};
                  if (isCellLocked || isCellLocked2) {
                    cellStyle.backgroundColor = '#d3d3d3';
                  }
                  if (cell && typeof cell === 'object' && cell.type === 'dropdown') {
                    const filteredOptions = cell.options.filter(option => option != null).map(option => option.toString());

                    // Trier les options et les transformer
                    const preparedOptions = cell.options
                      .filter(option => option !== null && option !== undefined) // Filtrer les valeurs null et undefined
                      .map(option => option.toString()) // Convertir chaque option en chaîne de caractères
                      .sort((a, b) => {
                        // Supposons que col.name est l'identifiant pour le dropdown spécial
                        if (col.name === "Vent") {
                          const numA = parseInt(a.match(/\d+/) || 0, 10);
                          const numB = parseInt(b.match(/\d+/) || 0, 10);
                          return numA - numB;
                        } else {
                          // Tri alphabétique pour les autres dropdowns
                          return a.localeCompare(b);
                        }
                      })
                      .map(option => ({
                        label: option,
                        firstLetter: option[0]?.toUpperCase() || '', // Utiliser le premier caractère pour le groupement
                      }));

                    return (
                      <td key={`${rowIndex}-${col.id}`}>
                        <Autocomplete
                          id={`autocomplete-${rowIndex}-${col.id}`}
                          options={preparedOptions}
                          groupBy={(option) => option.firstLetter}
                          getOptionLabel={(option) => option.label}
                          value={preparedOptions.find(o => o.label === cell.value) || null}
                          fullWidth
                          disabled={!isEditable()}
                          sx={{
                            width: getWidthForColumnName(col.name),
                          }}
                          onChange={(event, newValue) => {
                            const newValueLabel = newValue ? newValue.label : '';
                            handleCellChange({ target: { value: newValueLabel } }, rowIndex, col.id);
                            onDropdownChange && onDropdownChange(rowIndex, col.id, newValueLabel);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={col.name}
                              variant="outlined"
                              disabled={!isEditable() || (editableAdmin && !getToken?.admin)}
                            />
                          )}
                          PopperComponent={CustomPopper}
                          disablePortal
                          autoHighlight
                          autoComplete
                          includeInputInList
                        />
                      </td>

                    );
                  } else if (showCheckboxes && cell && typeof cell === 'object' && 'checked' in cell) {
                    return (
                      <td key={`${rowIndex}-${col.id}`} className="cell" style={{ textAlign: 'center' }}>
                        <input
                          type="checkbox"
                          checked={cell.checked}
                          onChange={isEditable() ? (e) => handleCheckboxChange(e.target.checked, rowIndex, col.id) : undefined}
                          disabled={!isEditable()}
                        />
                      </td>
                    )
                  } else if (showCheckboxesInventory && cell && typeof cell === 'object' && 'checked' in cell) {
                    return (
                      <td key={`${rowIndex}-${col.id}`} className="cell" style={{ textAlign: 'center' }}>
                        <input
                          type="checkbox"
                          checked={cell.checked}
                          onChange={isEditable() ? (e) => handleCheckboxChangeInventory(e.target.checked, rowIndex, col.id) : undefined}
                          disabled={!isEditable()}
                        />
                      </td>
                    )
                  } else {
                    return (
                      <td
                        key={`${rowIndex}-${col.id}`}
                        className={`cell ${editingCell.rowIndex === rowIndex && editingCell.colId === col.id ? 'cell-editing' : ''}`}
                        contentEditable={isEditable() && !(boldAndLockFirstFourthRows && (rowIndex === 0 || rowIndex === 3)) && !(LockFirstThirdColumns && (colIndex === 0 || colIndex === 2) && !getToken?.admin) && !(LockFirstRow && (rowIndex === 0) && !getToken?.admin) && !(LockSecondRow && (rowIndex === 1)) && !(LockFirstThirdFifthSeventhRow && (rowIndex === 0 || rowIndex === 2 || rowIndex === 4 || rowIndex === 6) && !getToken?.admin) && !(LockFirstSecondRow && (rowIndex === 0 || rowIndex === 1) && !getToken?.admin) && !(LockFourthRowFirstColumn && (rowIndex === 3 || colIndex === 0)) && !isCellLocked && !isCellLocked2}
                        suppressContentEditableWarning
                        onBlur={isEditable() ? (e) => handleCellChange(e, rowIndex, col.id) : undefined}
                        style={{ textAlign: 'center', whiteSpace: 'pre-wrap', ...cellStyle }} // Applique le style conditionnel
                      >
                        {cell}
                      </td>
                    );
                  }
                })}
                {showDeleteRow && getToken?.admin && (
                  <td className="print-hide">
                    <IconButton onClick={() => removeRow(rowIndex, name)} aria-label="delete">
                      <HighlightOffIcon />
                    </IconButton>
                  </td>
                )}
                {showDeleteRowUser && (
                  <td className="print-hide">
                    <IconButton onClick={() => removeRow(rowIndex, name)} aria-label="delete">
                      <HighlightOffIcon />
                    </IconButton>
                  </td>
                )}
                {dontDeleteFirstRow && rowIndex > 0 && (
                  <td className="print-hide">
                    <IconButton onClick={() => removeRow(rowIndex, name)} aria-label="delete">
                      <HighlightOffIcon />
                    </IconButton>
                  </td>
                )}
                {dontDeleteSecondRow && rowIndex > 1 && (
                  <td className="print-hide">
                    <IconButton onClick={() => removeRow(rowIndex, name)} aria-label="delete">
                      <HighlightOffIcon />
                    </IconButton>
                  </td>
                )}
                {dontDeleteLastRow && rowIndex > 0 && rowIndex !== data.length - 1 && (
                  <td className="print-hide">
                    <IconButton onClick={() => removeRow(rowIndex, name)} aria-label="delete">
                      <HighlightOffIcon />
                    </IconButton>
                  </td>
                )}
                {dontDeleteLastThreeRows && rowIndex > 1 && rowIndex < data.length - 3 && (
                  <td className="print-hide">
                    <IconButton onClick={() => removeRow(rowIndex, name)} aria-label="delete">
                      <HighlightOffIcon />
                    </IconButton>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default DynamicTable;

