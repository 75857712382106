import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from "../component/dynamicTable.tsx";
import Navbar from "../component/navbar.tsx";
import Title from "../component/title.tsx";
import Header from "../component/header.tsx";
import { Buffer } from "buffer";
import isEqual from 'lodash/isEqual';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ArrowBack from "../component/flecheBack.tsx";
import PageTitle from "../component/pageTitle.tsx";
import TabBar from "../component/tapBar.tsx";
import Commentary from "../component/commentaire.tsx";

export default function ConseilStategique() {
    const navigate = useNavigate();
    const [totals, setTotals] = useState({ totalCultures: 0, totalSurfaces: 0, totalVarietes: 0 });
    const [error, setError] = useState(null);
    const [tractorNames, setTractorNames] = useState([]); // Déplacez cette ligne en haut pour initialiser tractorNames
    const [solNames, setSolNames] = useState([]); // Déplacez cette ligne en haut pour initialiser SolNames
    const [pulNames, setPulNames] = useState([]); // Déplacez cette ligne en haut pour initialiser PulNames
    const [autreNames, setAutreNames] = useState([]); // Déplacez cette ligne en haut pour initialiser AutrerNames
    const [dataTabIdCt, setDataTabIdCt] = useState([])
    const [dataTabFeatures, setDataTabFeatures] = useState([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "" }])
    const [dataTabProvider, setDataTabProvider] = useState([{ col_1: "", col_2: "", col_3: "", col_4: "" }])
    const [dataTabSurface, setDataTabSurface] = useState([{ col_1: "Surface agricole utile totale de l'exploitation :", col_2: "" }])
    const [dataTabCrop, setDataTabCrop] = useState([{ col_1: { type: "dropdown", value: "", options: ["Arboriculture", "Maraîchage", "Vigne", "Grande culture"] }, col_2: "", col_3: "", col_4: "", col_5: "", col_6: "" }]);
    const [dataTabEconomic, setDataTabEconomic] = useState([{ col_1: "CA année N-1 : ", col_2: "" }, { col_1: "CA année N : ", col_2: "" }])
    const [dataTabInfoEconomic, setDataTabInfoEconomic] = useState([{ col_1: "Personnel Permanent", col_2: "", col_3: "" }, { col_1: "Qualifié", col_2: "", col_3: "" }, { col_1: "Non Qualifié", col_2: "", col_3: "" }, { col_1: "Personnel Saisonnier", col_2: "", col_3: "" }, { col_1: "Qualifié", col_2: "", col_3: "" }, { col_1: "Non Qualifié", col_2: "", col_3: "" }])
    const [dataTabEquipment, setDataTabEquipment] = useState([{ col_1: "", col_2: { checked: false }, col_3: { checked: false }, col_4: { checked: false }, col_5: { checked: false }, }])
    const [dataTabFight, setDataTabFight] = useState([{ col_1: "Choix des sites de culture", col_2: { checked: false }, col_3: "Date de semis/plantation", col_4: { checked: false } },
    { col_1: "Variétés résistantes", col_2: { checked: false }, col_3: "Densité de semis/plantation", col_4: { checked: false } },
    { col_1: "Qualité des semences et plants", col_2: { checked: false }, col_3: "Modalité d'ensemencement", col_4: { checked: false } },
    { col_1: "Culture de variétés en mélange", col_2: { checked: false }, col_3: "Préservation des auxiliaires présents", col_4: { checked: false } },
    { col_1: "Greffage", col_2: { checked: false }, col_3: "Effeuillage", col_4: { checked: false } },
    { col_1: "Limitation des risques de verse (GC)", col_2: { checked: false }, col_3: "Adaptation de la taille", col_4: { checked: false } }])

    const [dataTabFightEnv, setDataTabFightEnv] = useState([{ col_1: "Rotations des cultures", col_2: { checked: false }, col_3: "Paillage", col_4: { checked: false } },
    { col_1: "Assolement (forme/taille)", col_2: { checked: false }, col_3: "Gérer le micro climat sous abris", col_4: { checked: false } },
    { col_1: "Structure du sol", col_2: { checked: false }, col_3: "Agir sur les microorganismes de la rhizosphère", col_4: { checked: false } },
    { col_1: "Labour/Non Labour", col_2: { checked: false }, col_3: "Fertilisation", col_4: { checked: false } },
    { col_1: "Agroforesterie", col_2: { checked: false }, col_3: "Irrigation", col_4: { checked: false } },
    { col_1: "Couvert végétal (réduction advenctives)", col_2: { checked: false }, col_3: "Réservoir d'auxilliare", col_4: { checked: false } },
    { col_1: "Enherbement", col_2: { checked: false }, col_3: "", col_4: "" }])

    const [dataTabFightPest, setDataTabFightPest] = useState([{ col_1: "Gestion des débris végétaux", col_2: { checked: false }, col_3: "Solarisation", col_4: { checked: false } },
    { col_1: "Cultures intermédiaires", col_2: { checked: false }, col_3: "Auxillaires", col_4: { checked: false } },
    { col_1: "Faux semis", col_2: { checked: false }, col_3: "Plantes pièges", col_4: { checked: false } },
    { col_1: "Désinfection des sols à la vapeur", col_2: { checked: false }, col_3: "Hygiène des outils/locaux", col_4: { checked: false } },
    { col_1: "Biofumigation", col_2: { checked: false }, col_3: "", col_4: "" }])

    const [dataTabFightProcess, setDataTabFightProcess] = useState([{ col_1: "Désherbage mécanique en post levée", col_2: { checked: false }, col_3: "Perturbation des ravageurs par l'eau", col_4: { checked: false } },
    { col_1: "Désherbage mécanique des cultures pérennnes", col_2: { checked: false }, col_3: "Températures basses en post récoltes", col_4: { checked: false } },
    { col_1: "Désherbage thermique", col_2: { checked: false }, col_3: "Températures hautes sous abris (maladies/ravageurs)", col_4: { checked: false } },
    { col_1: "Dispositif anti insectes", col_2: { checked: false }, col_3: "Epamprage mécanique", col_4: { checked: false } },
    { col_1: "Capture en masse des ravageurs", col_2: { checked: false }, col_3: "Intervention physique sur végétal", col_4: { checked: false } }])

    const [dataTabFightBio, setDataTabFightBio] = useState([{ col_1: "Confusion sexuelle", col_2: { checked: false }, col_3: "Microorganismes contre pathogène", col_4: { checked: false } },
    { col_1: "Auxilliaires", col_2: { checked: false }, col_3: "Mycoherbicide", col_4: { checked: false } },
    { col_1: "Stimulation défense naturelle de la plante", col_2: { checked: false }, col_3: "Extraits de végétaux contre bio agresseur", col_4: { checked: false } },
    { col_1: "Microorganismes contre ravageurs", col_2: { checked: false }, col_3: "Lâcher de mâles stériles", col_4: { checked: false } },
    { col_1: "Nématodes contre ravageurs", col_2: { checked: false }, col_3: "", col_4: "" }])

    const [dataTabFightPestBis, setDataTabFightPestBis] = useState([])
    const [dataTabFightEnvBis, setDataTabFightEnvBis] = useState([])
    const [dataTabFightBis, setDataTabFightBis] = useState([])
    const [dataTabFightProcessBis, setDataTabFightProcessBis] = useState([])
    const [dataTabFightBioBis, setDataTabFightBioBis] = useState([])
    const [dataTabFightChemistyBis, setDataTabFightChemistyBis] = useState([])

    const [dataTabFightChemisty, setDataTabFightChemisty] = useState([{ col_1: "Réflexion sur la modulation de dose", col_2: { checked: false } }, { col_1: "Gestion des risques de résistances", col_2: { checked: false } }, { col_1: "Diminuer IFT", col_2: { checked: false } }])
    const [listEquipment, setlistEquipment] = useState([{ col_1: "Tracteur", col2: "", col_3: "" }, { col_1: "Travail du Sol", col2: "", col_3: "" }, { col_1: "Pulvérisation", col2: "", col_3: "" }, { col_1: "Autres", col2: "", col_3: "" }]); // Déplacez cette ligne en haut pour initialiser listEquipment
    const [validationQuizz, setValidationQuizz] = useState(false)

    const [commentaryWeakStrongPoints, setWeakStrongPoints] = useState("");
    const [commentaryWeakStrongEconomic, setWeakStrongEconomic] = useState("");
    const [commentaryPedoclimatic, setPedoclimatic] = useState("");

    const token = localStorage.getItem('token');
    const { id, idCst } = useParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [strategicDetails, setStrategicDetails] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [strategicDate, setStrategicDate] = useState("");
    const [strategicDateFormated, setStrategicDateFormated] = useState(strategicDate);
    const [needsSaveFeatures, setNeedsSaveFeatures] = useState(false);
    const [needsSaveProvider, setNeedsSaveProvider] = useState(false);
    const [needsSaveCrop, setNeedsSaveCrop] = useState(false);
    const [needsSaveEquipment, setNeedsSaveEquipment] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    let idUser = null;
    let isAdmin = false;



    if (token) {
        const tokenBody = token.split('.')[1];
        if (tokenBody) {
            const decodedToken = JSON.parse(Buffer.from(tokenBody, 'base64').toString());
            idUser = decodedToken.id;
            isAdmin = decodedToken.admin;
        }
    }

    const formatDate = (dateString) => {
        if (!dateString) return "Non définie";
        const [year, month, day] = dateString.split('-');
        if (day === undefined || month === undefined) {
            return `${year}`
        }
        return `${day}/${month}/${year}`;
    };

    // ------------------------ START POST ARRAY -----------------------------

    const saveDataToStrategicDate = async () => {
        try {
            const userIdToUse = id || idUser;
            const formattedDate = strategicDate && strategicDate !== "" ? formatToYYYYMMDD(strategicDate) : null;
            const bodyData = {
                idUser: userIdToUse,
                idCst: idCst,
                strategicDateOnly: formattedDate
            };
            const response = await fetch(`${apiUrl}/strategicDate/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(bodyData)
            });

            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveDataToIdCt = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/idCt/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };


    const saveDataToFeatures = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/features/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };


    const saveDataToProvider = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/provider/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };



    const saveMarket = async (newCommentaryData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/market/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, commentaryData: newCommentaryData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du commentaire');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveDataToSurface = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/surface/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };


    const saveDataToCrop = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/crop/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveDataToEconomic = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/economic/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveDataToInfoEconomic = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/infoEconomic/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newDat, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };


    const saveDataToEquipment = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/equipment/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };


    const saveDataToFight = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/fight/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveDataToFightEnv = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/fightEnv/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveDataToFightPest = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/fightPest/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveDataToFightProcess = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/fightProcess/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveDataToFightBio = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/fightBio/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveDataToFightChemisty = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/fightChemisty/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveWeakStrongPoints = async (newCommentaryData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/weakStrongPoints/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, commentaryData: newCommentaryData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du commentaire');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveWeakStrongEconomic = async (newCommentaryData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/weakStrongEconomic/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, commentaryData: newCommentaryData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du commentaire');
        } catch (error) {
            setError(error.message);
        }
    };

    const savePedoclimatic = async (newCommentaryData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/pedoclimatic/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, commentaryData: newCommentaryData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du commentaire');
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        if (needsSaveProvider) {
            // Procéder à la sauvegarde des données
            saveDataToProvider(dataTabProvider)
                .then(() => setNeedsSaveProvider(false)) // Réinitialiser l'indicateur après la sauvegarde
                .catch(error => {
                    console.error(error);
                    // Gérer l'erreur de sauvegarde si nécessaire
                });
        }
    }, [needsSaveProvider]);

    useEffect(() => {
        if (needsSaveCrop) {
            // Procéder à la sauvegarde des données
            saveDataToCrop(dataTabCrop)
                .then(() => setNeedsSaveCrop(false)) // Réinitialiser l'indicateur après la sauvegarde
                .catch(error => {
                    console.error(error);
                    // Gérer l'erreur de sauvegarde si nécessaire
                });
        }
    }, [needsSaveCrop]);

    useEffect(() => {
        if (needsSaveEquipment) {
            // Procéder à la sauvegarde des données
            saveDataToEquipment(dataTabEquipment)
                .then(() => setNeedsSaveEquipment(false)) // Réinitialiser l'indicateur après la sauvegarde
                .catch(error => {
                    console.error(error);
                    // Gérer l'erreur de sauvegarde si nécessaire
                });
        }
    }, [needsSaveEquipment]);

    useEffect(() => {
        if (needsSaveFeatures) {
            // Procéder à la sauvegarde des données
            saveDataToFeatures(dataTabFeatures)
                .then(() => setNeedsSaveFeatures(false)) // Réinitialiser l'indicateur après la sauvegarde
                .catch(error => {
                    console.error(error);
                    // Gérer l'erreur de sauvegarde si nécessaire
                });
        }
    }, [needsSaveFeatures]);

    // ----------------- HANDLE CHANGE FOR ARRAY -----------------------------


    const handleDataChangeIdCt = useCallback((newData) => {
        if (!isEqual(dataTabIdCt, newData)) {
            setDataTabIdCt(newData);
            saveDataToIdCt(newData);
        }
    }, [dataTabIdCt, saveDataToIdCt]);

    const handleDataChangeFeatures = useCallback((newData) => {
        if (!isEqual(dataTabFeatures, newData)) {
            setDataTabFeatures(newData);
            saveDataToFeatures(newData);
        }
    }, [dataTabFeatures, saveDataToFeatures]);

    const handleDataChangeProvider = useCallback((newData) => {
        if (!isEqual(dataTabProvider, newData)) {
            setDataTabProvider(newData);
            saveDataToProvider(newData);
        }
    }, [dataTabProvider, saveDataToProvider]);


    const handleDataChangeSurface = useCallback((newData) => {
        if (!isEqual(dataTabSurface, newData)) {
            setDataTabSurface(newData);
            saveDataToSurface(newData);
        }
    }, [dataTabSurface, saveDataToSurface]);

    const handleDataChangeCrop = useCallback((newData) => {
        if (!isEqual(dataTabCrop, newData)) {
            setDataTabCrop(newData);
            saveDataToCrop(newData);
        }
    }, [dataTabCrop, saveDataToCrop]);

    const handleDataChangeEconomic = useCallback((newData) => {
        if (!isEqual(dataTabEconomic, newData)) {
            setDataTabEconomic(newData);
            saveDataToEconomic(newData);
        }
    }, [dataTabEconomic, saveDataToEconomic]);

    const handleDataChangeInfoEconomic = useCallback((newData) => {
        if (!isEqual(dataTabInfoEconomic, newData)) {
            setDataTabInfoEconomic(newData);
            saveDataToInfoEconomic(newData);
        }
    }, [dataTabInfoEconomic, saveDataToInfoEconomic]);


    const handleDataChangeEquipment = useCallback((newData) => {
        if (!isEqual(dataTabEquipment, newData)) {
            setDataTabEquipment(newData);
            saveDataToEquipment(newData);
        }
    }, [dataTabEquipment, saveDataToEquipment]);

    const handleDataChangeFight = useCallback((newData) => {
        if (!isEqual(dataTabFight, newData)) {
            setDataTabFight(newData);
            saveDataToFight(newData);
        }
    }, [dataTabFight, saveDataToFight]);

    const handleDataChangeFightEnv = useCallback((newData) => {
        if (!isEqual(dataTabFightEnv, newData)) {
            setDataTabFightEnv(newData);
            saveDataToFightEnv(newData);
        }
    }, [dataTabFightEnv, saveDataToFightEnv]);

    const handleDataChangeFightPest = useCallback((newData) => {
        if (!isEqual(dataTabFightPest, newData)) {
            setDataTabFightPest(newData);
            saveDataToFightPest(newData);
        }
    }, [dataTabFightPest, saveDataToFightPest]);

    const handleDataChangeFightProcess = useCallback((newData) => {
        if (!isEqual(dataTabFightProcess, newData)) {
            setDataTabFightProcess(newData);
            saveDataToFightProcess(newData);
        }
    }, [dataTabFightProcess, saveDataToFightProcess]);

    const handleDataChangeFightBio = useCallback((newData) => {
        if (!isEqual(dataTabFightBio, newData)) {
            setDataTabFightBio(newData);
            saveDataToFightBio(newData);
        }
    }, [dataTabFightBio, saveDataToFightBio]);

    const handleDataChangeFightChemisty = useCallback((newData) => {
        if (!isEqual(dataTabFightChemisty, newData)) {
            setDataTabFightChemisty(newData);
            saveDataToFightChemisty(newData);
        }
    }, [dataTabFightChemisty, saveDataToFightChemisty]);

    const handleDataChangeWeakStrongPoints = useCallback((newCommentaryData) => {
        if (!isEqual(commentaryWeakStrongPoints, newCommentaryData)) {
            setWeakStrongPoints(newCommentaryData);
            saveWeakStrongPoints(newCommentaryData);
        }
    }, [commentaryWeakStrongPoints, saveWeakStrongPoints]);

    const handleDataChangeWeakStrongEconomic = useCallback((newCommentaryData) => {
        if (!isEqual(commentaryWeakStrongEconomic, newCommentaryData)) {
            setWeakStrongEconomic(newCommentaryData);
            saveWeakStrongEconomic(newCommentaryData);
        }
    }, [commentaryWeakStrongEconomic, saveWeakStrongEconomic]);

    const handleDataChangePedoclimatic = useCallback((newCommentaryData) => {
        if (!isEqual(commentaryPedoclimatic, newCommentaryData)) {
            setPedoclimatic(newCommentaryData);
            savePedoclimatic(newCommentaryData);
        }
    }, [commentaryPedoclimatic, savePedoclimatic]);

    const handleNavigate = () => {
        const userIdToUse = id || idUser;
        navigate(`/${userIdToUse}/questionnaireCst/${idCst}`);
    };

    const addNewCropRow = () => {
        const newRow = {
            col_1: { type: "dropdown", value: "", options: ["Arboriculture", "Maraîchage", "Vigne", "Grande culture"] },
            col_2: "",
            col_3: "",
            col_4: "",
            col_5: "",
            col_6: ""
        };
        if (!Array.isArray(dataTabCrop)) {
            setDataTabCrop([newRow, newRow]);
            setNeedsSaveCrop(true)
        } else {
            setDataTabCrop([...dataTabCrop, newRow]);
            setNeedsSaveCrop(true)
        }
    };

    const addNewFeaturesRow = () => {
        const newRow = {
            col_1: "",
            col_2: "",
            col_3: "",
            col_4: "",
            col_5: "",
            col_6: ""
        };
        if (!Array.isArray(dataTabFeatures)) {
            setDataTabFeatures([newRow, newRow]);
            setNeedsSaveFeatures(true)
        } else {
            setDataTabFeatures([...dataTabFeatures, newRow]);
            setNeedsSaveFeatures(true)
        }
    };

    const addNewProviderRow = () => {
        const newRow = {
            col_1: "",
            col_2: "",
            col_3: "",
            col_4: "",
        };
        if (!Array.isArray(dataTabProvider)) {
            setDataTabProvider([newRow, newRow]);
            setNeedsSaveProvider(true)
        } else {
            setDataTabProvider([...dataTabProvider, newRow]);
            setNeedsSaveProvider(true)
        }
    };

    const handleStrategicDateChange = (newDate) => {
        const formattedDate = formatToYYYYMMDD(newDate);
        setStrategicDate(formattedDate);
        setStrategicDateFormated(newDate);
    };

    const handleUserClick = (event, user) => {
        setSelectedUser(user);
        setAnchorEl(event.currentTarget);
        fetch(`${apiUrl}/strategicDate/${user.idUser}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
            .then(response => response.json())
            .then(data => {
                setStrategicDetails(prevState => ({ ...prevState, [user.idUser]: data }));
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données de conseil:', error);
            });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNewStrategic = () => {
        const today = new Date().toISOString().split('T')[0];
        const lastidCst = Math.max(...strategicDetails[selectedUser?.idUser]?.map(strategic => strategic.idCst) ?? [0]);
        const newidCst = lastidCst + 1;
        navigate(`/${selectedUser.idUser}/rapport/${newidCst}`);
        setStrategicDate(today);
        setStrategicDateFormated(today);
        setDataTabIdCt([]);
        setDataTabFeatures([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "" }])
        setDataTabProvider([{ col_1: "", col_2: "", col_3: "", col_4: "" }])
        setDataTabSurface([{ col_1: "Surface agricole utile totale de l'exploitation :", col_2: "" }])
        setDataTabCrop([{ col_1: { type: "dropdown", value: "", options: ["Arboriculture", "Maraîchage", "Vigne", "Grande culture"] }, col_2: "", col_3: "", col_4: "", col_5: "", col_6: "" }]);
        setDataTabEconomic([{ col_1: "CA année N-1 : ", col_2: "" }, { col_1: "CA année N : ", col_2: "" }])
        setDataTabInfoEconomic([{ col_1: "Personnel Permanent", col_2: "", col_3: "" }, { col_1: "Qualifié", col_2: "", col_3: "" }, { col_1: "Non Qualifié", col_2: "", col_3: "" }, { col_1: "Personnel Saisonnier", col_2: "", col_3: "" }, { col_1: "Qualifié", col_2: "", col_3: "" }, { col_1: "Non Qualifié", col_2: "", col_3: "" }])
        setDataTabEquipment([{ col_1: "", col_2: { checked: false }, col_3: { checked: false }, col_4: { checked: false }, col_5: { checked: false }, }])
        setDataTabFight([{ col_1: "Choix des sites de culture", col_2: { checked: false }, col_3: "Date de semis/plantation", col_4: { checked: false } },
        { col_1: "Variétés résistantes", col_2: { checked: false }, col_3: "Densité de semis/plantation", col_4: { checked: false } },
        { col_1: "Qualité des semences et plants", col_2: { checked: false }, col_3: "Modalité d'ensemencement", col_4: { checked: false } },
        { col_1: "Culture de variétés en mélange", col_2: { checked: false }, col_3: "Préservation des auxiliaires présents", col_4: { checked: false } },
        { col_1: "Greffage", col_2: { checked: false }, col_3: "Effeuillage", col_4: { checked: false } },
        { col_1: "Limitation des risques de verse (GC)", col_2: { checked: false }, col_3: "Adaptation de la taille", col_4: { checked: false } }])

        setDataTabFightEnv([{ col_1: "Rotations des cultures", col_2: { checked: false }, col_3: "Paillage", col_4: { checked: false } },
        { col_1: "Assolement (forme/taille)", col_2: { checked: false }, col_3: "Gérer le micro climat sous abris", col_4: { checked: false } },
        { col_1: "Structure du sol", col_2: { checked: false }, col_3: "Agir sur les microorganismes de la rhizosphère", col_4: { checked: false } },
        { col_1: "Labour/Non Labour", col_2: { checked: false }, col_3: "Fertilisation", col_4: { checked: false } },
        { col_1: "Agroforesterie", col_2: { checked: false }, col_3: "Irrigation", col_4: { checked: false } },
        { col_1: "Couvert végétal (réduction advenctives)", col_2: { checked: false }, col_3: "Réservoir d'auxilliare", col_4: { checked: false } },
        { col_1: "Enherbement", col_2: { checked: false }, col_3: "", col_4: "" }])

        setDataTabFightPest([{ col_1: "Gestion des débris végétaux", col_2: { checked: false }, col_3: "Solarisation", col_4: { checked: false } },
        { col_1: "Cultures intermédiaires", col_2: { checked: false }, col_3: "Auxillaires", col_4: { checked: false } },
        { col_1: "Faux semis", col_2: { checked: false }, col_3: "Plantes pièges", col_4: { checked: false } },
        { col_1: "Désinfection des sols à la vapeur", col_2: { checked: false }, col_3: "Hygiène des outils/locaux", col_4: { checked: false } },
        { col_1: "Biofumigation", col_2: { checked: false }, col_3: "", col_4: "" }])

        setDataTabFightProcess([{ col_1: "Désherbage mécanique en post levée", col_2: { checked: false }, col_3: "Perturbation des ravageurs par l'eau", col_4: { checked: false } },
        { col_1: "Désherbage mécanique des cultures pérennnes", col_2: { checked: false }, col_3: "Températures basses en post récoltes", col_4: { checked: false } },
        { col_1: "Désherbage thermique", col_2: { checked: false }, col_3: "Températures hautes sous abris (maladies/ravageurs)", col_4: { checked: false } },
        { col_1: "Dispositif anti insectes", col_2: { checked: false }, col_3: "Epamprage mécanique", col_4: { checked: false } },
        { col_1: "Capture en masse des ravageurs", col_2: { checked: false }, col_3: "Intervention physique sur végétal", col_4: { checked: false } }])

        setDataTabFightBio([{ col_1: "Confusion sexuelle", col_2: { checked: false }, col_3: "Microorganismes contre pathogène", col_4: { checked: false } },
        { col_1: "Auxilliaires", col_2: { checked: false }, col_3: "Mycoherbicide", col_4: { checked: false } },
        { col_1: "Stimulation défense naturelle de la plante", col_2: { checked: false }, col_3: "Extraits de végétaux contre bio agresseur", col_4: { checked: false } },
        { col_1: "Microorganismes contre ravageurs", col_2: { checked: false }, col_3: "Lâcher de mâles stériles", col_4: { checked: false } },
        { col_1: "Nématodes contre ravageurs", col_2: { checked: false }, col_3: "", col_4: "" }])

        setDataTabFightChemisty([{ col_1: "Réflexion sur la modulation de dose", col_2: { checked: false } }, { col_1: "Gestion des risques de résistances", col_2: { checked: false } }, { col_1: "Diminuer IFT", col_2: { checked: false } }])
        setlistEquipment([{ col_1: "Tracteur", col2: "", col_3: "" }, { col_1: "Travail du Sol", col2: "", col_3: "" }, { col_1: "Pulvérisation", col2: "", col_3: "" }, { col_1: "Autres", col2: "", col_3: "" }]); // Déplacez cette ligne en haut pour initialiser listEquipment
        setWeakStrongPoints("");
        setWeakStrongEconomic("");
        setPedoclimatic("");
        setStrategicDetails({});
        saveDataToStrategicDate();
        handleClose();
    };


    const handleOldStrategic = (idCstbis) => {
        var isTrue = false;
        if (isEqual(idCstbis, idCst)) {
            isTrue = true;
        }
        navigate(`/${selectedUser.idUser}/rapport/${idCstbis}`);

        if (isTrue) {
            setStrategicDate("");
            setStrategicDateFormated("");
            setDataTabIdCt([])
            setDataTabFeatures([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "" }])
            setDataTabProvider([{ col_1: "", col_2: "", col_3: "", col_4: "" }])
            setDataTabSurface([{ col_1: "Surface agricole utile totale de l'exploitation :", col_2: "" }])
            setDataTabCrop([{ col_1: { type: "dropdown", value: "", options: ["Arboriculture", "Maraîchage", "Vigne", "Grande culture"] }, col_2: "", col_3: "", col_4: "", col_5: "", col_6: "" }]);
            setDataTabEconomic([{ col_1: "CA année N-1 : ", col_2: "" }, { col_1: "CA année N : ", col_2: "" }])
            setDataTabInfoEconomic([{ col_1: "Personnel Permanent", col_2: "", col_3: "" }, { col_1: "Qualifié", col_2: "", col_3: "" }, { col_1: "Non Qualifié", col_2: "", col_3: "" }, { col_1: "Personnel Saisonnier", col_2: "", col_3: "" }, { col_1: "Qualifié", col_2: "", col_3: "" }, { col_1: "Non Qualifié", col_2: "", col_3: "" }])
            setDataTabEquipment([{ col_1: "", col_2: { checked: false }, col_3: { checked: false }, col_4: { checked: false }, col_5: { checked: false }, }])
            setDataTabFight([{ col_1: "Choix des sites de culture", col_2: { checked: false }, col_3: "Date de semis/plantation", col_4: { checked: false } },
            { col_1: "Variétés résistantes", col_2: { checked: false }, col_3: "Densité de semis/plantation", col_4: { checked: false } },
            { col_1: "Qualité des semences et plants", col_2: { checked: false }, col_3: "Modalité d'ensemencement", col_4: { checked: false } },
            { col_1: "Culture de variétés en mélange", col_2: { checked: false }, col_3: "Préservation des auxiliaires présents", col_4: { checked: false } },
            { col_1: "Greffage", col_2: { checked: false }, col_3: "Effeuillage", col_4: { checked: false } },
            { col_1: "Limitation des risques de verse (GC)", col_2: { checked: false }, col_3: "Adaptation de la taille", col_4: { checked: false } }])

            setDataTabFightEnv([{ col_1: "Rotations des cultures", col_2: { checked: false }, col_3: "Paillage", col_4: { checked: false } },
            { col_1: "Assolement (forme/taille)", col_2: { checked: false }, col_3: "Gérer le micro climat sous abris", col_4: { checked: false } },
            { col_1: "Structure du sol", col_2: { checked: false }, col_3: "Agir sur les microorganismes de la rhizosphère", col_4: { checked: false } },
            { col_1: "Labour/Non Labour", col_2: { checked: false }, col_3: "Fertilisation", col_4: { checked: false } },
            { col_1: "Agroforesterie", col_2: { checked: false }, col_3: "Irrigation", col_4: { checked: false } },
            { col_1: "Couvert végétal (réduction advenctives)", col_2: { checked: false }, col_3: "Réservoir d'auxilliare", col_4: { checked: false } },
            { col_1: "Enherbement", col_2: { checked: false }, col_3: "", col_4: "" }])

            setDataTabFightPest([{ col_1: "Gestion des débris végétaux", col_2: { checked: false }, col_3: "Solarisation", col_4: { checked: false } },
            { col_1: "Cultures intermédiaires", col_2: { checked: false }, col_3: "Auxillaires", col_4: { checked: false } },
            { col_1: "Faux semis", col_2: { checked: false }, col_3: "Plantes pièges", col_4: { checked: false } },
            { col_1: "Désinfection des sols à la vapeur", col_2: { checked: false }, col_3: "Hygiène des outils/locaux", col_4: { checked: false } },
            { col_1: "Biofumigation", col_2: { checked: false }, col_3: "", col_4: "" }])

            setDataTabFightProcess([{ col_1: "Désherbage mécanique en post levée", col_2: { checked: false }, col_3: "Perturbation des ravageurs par l'eau", col_4: { checked: false } },
            { col_1: "Désherbage mécanique des cultures pérennnes", col_2: { checked: false }, col_3: "Températures basses en post récoltes", col_4: { checked: false } },
            { col_1: "Désherbage thermique", col_2: { checked: false }, col_3: "Températures hautes sous abris (maladies/ravageurs)", col_4: { checked: false } },
            { col_1: "Dispositif anti insectes", col_2: { checked: false }, col_3: "Epamprage mécanique", col_4: { checked: false } },
            { col_1: "Capture en masse des ravageurs", col_2: { checked: false }, col_3: "Intervention physique sur végétal", col_4: { checked: false } }])

            setDataTabFightBio([{ col_1: "Confusion sexuelle", col_2: { checked: false }, col_3: "Microorganismes contre pathogène", col_4: { checked: false } },
            { col_1: "Auxilliaires", col_2: { checked: false }, col_3: "Mycoherbicide", col_4: { checked: false } },
            { col_1: "Stimulation défense naturelle de la plante", col_2: { checked: false }, col_3: "Extraits de végétaux contre bio agresseur", col_4: { checked: false } },
            { col_1: "Microorganismes contre ravageurs", col_2: { checked: false }, col_3: "Lâcher de mâles stériles", col_4: { checked: false } },
            { col_1: "Nématodes contre ravageurs", col_2: { checked: false }, col_3: "", col_4: "" }])

            setDataTabFightChemisty([{ col_1: "Réflexion sur la modulation de dose", col_2: { checked: false } }, { col_1: "Gestion des risques de résistances", col_2: { checked: false } }, { col_1: "Diminuer IFT", col_2: { checked: false } }])
            setlistEquipment([{ col_1: "Tracteur", col2: "", col_3: "" }, { col_1: "Travail du Sol", col2: "", col_3: "" }, { col_1: "Pulvérisation", col2: "", col_3: "" }, { col_1: "Autres", col2: "", col_3: "" }]); // Déplacez cette ligne en haut pour initialiser listEquipment
            setWeakStrongPoints("");
            setWeakStrongEconomic("");
            setPedoclimatic("");
            setStrategicDetails({});
            saveDataToStrategicDate();
        }
        handleClose();
    };

    const filteredStrategic = strategicDetails[selectedUser?.idUser]?.filter(strategic => {
        const strategicDateFormatted = strategic.strategicDateOnly ? formatDate(strategic.strategicDateOnly) : "Date à renseigner";
        return searchQuery === "" || strategic.idCst.toString().startsWith(searchQuery) || strategicDateFormatted.startsWith(searchQuery);
    });

    const tabs = [
        { label: 'Partie 1', path: `/${id}/rapport/${idCst}` },
        { label: 'Annexe(s)', path: `/${id}/annexe/1/${idCst}` },
        { label: 'Partie 2', path: `/${id}/rapport2/${idCst}` },
        { label: 'Partie 3', path: `/${id}/rapport3/${idCst}` },
    ];

    useEffect(() => {
        if (strategicDate) {
            saveDataToStrategicDate();
        }
    }, [strategicDate]);

    const formatToYYYYMMDD = (dateString) => {
        if (!dateString) return "";

        // Parse the date string to avoid timezone issues
        const [year, month, day] = dateString.split('T')[0].split('-');

        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    };

    //------------------GET USER-----------------

    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchUserData = async () => {
            try {
                const response = await fetch(`${apiUrl}/user/${userIdToUse}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données utilisateur');
                }

                const userData = await response.json();

                // Mappage des données utilisateur sur dataTabIdCt
                const assujettiTVA = userData.assujettiTVA ? "Oui" : "Non";
                const newDataTabIdCt = [
                    { col_1: "Raison Sociale : ", col_2: userData.raisonSociale, col_3: "Siret : ", col_4: userData.siret },
                    { col_1: "Nom, Prénom : ", col_2: `${userData.firstName} ${userData.lastName}`, col_3: "N° IntraCommunautaire : ", col_4: userData.intraCom },
                    { col_1: "Adresse : ", col_2: userData.adresse, col_3: "Assujetti TVA : ", col_4: assujettiTVA },
                    { col_1: "Code Postal : ", col_2: userData.postalCode, col_3: "N° PACAGE : ", col_4: userData.pacage },
                    { col_1: "Email : ", col_2: userData.email, col_3: "Téléphone : ", col_4: userData.telephone },
                    { col_1: "Ville : ", col_2: userData.ville, col_3: "", col_4: "" },

                ];

                setDataTabIdCt(newDataTabIdCt);

            } catch (error) {
                console.error(error);
            }
        };

        fetchUserData();
    }, [id, idUser]);


    // ----------------- START GET ARRAY ---------------------

    useEffect(() => {
        const userIdToUse = id || idUser;
        const fetchUserById = async () => {
            try {
                const response = await fetch(`${apiUrl}/user/${userIdToUse}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setSelectedUser(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchUserById();
    }, [token, idUser, id]);

    useEffect(() => {

        const userIdToUse = id || idUser;
        const fetchStrategicDate = async () => {
            try {
                const response = await fetch(`${apiUrl}/strategicDate/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                const formattedDate = formatToYYYYMMDD(strategicDate);
                setStrategicDate(data.strategicDateOnly || new Date().toISOString().split('T')[0]);
                setStrategicDateFormated(data.strategicDateOnly || new Date().toISOString().split('T')[0]);
            } catch (error) {
                console.error(error);
            }
        };
        fetchStrategicDate();
    }, [token, idUser, id, idCst]);

    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchFeatures = async () => {
            try {
                const response = await fetch(`${apiUrl}/features/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setDataTabFeatures(data.dataTab || []);
                if (!isEqual(dataTabFeatures, data)) {
                    setDataTabFeatures(data.dataTab);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchFeatures();
    }, [token, idUser, id, idCst]);


    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchProvider = async () => {
            try {
                const response = await fetch(`${apiUrl}/provider/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setDataTabProvider(data.dataTab || []);
                if (!isEqual(dataTabProvider, data)) {
                    setDataTabProvider(data.dataTab);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchProvider();
    }, [token, idUser, id, idCst]);



    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchSurface = async () => {
            try {
                const response = await fetch(`${apiUrl}/surface/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setDataTabSurface(data.dataTab || []);
                if (!isEqual(dataTabSurface, data)) {
                    setDataTabSurface(data.dataTab);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchSurface();
    }, [token, idUser, id, idCst]);

    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchCrop = async () => {
            try {
                const response = await fetch(`${apiUrl}/crop/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setDataTabCrop(data.dataTab || []);
                if (!isEqual(dataTabCrop, data)) {
                    setDataTabCrop(data.dataTab);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchCrop();
    }, [token, idUser, id, idCst]);

    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchEconomic = async () => {
            try {
                const response = await fetch(`${apiUrl}/economic/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setDataTabEconomic(data.dataTab || []);
                if (!isEqual(dataTabEconomic, data)) {
                    setDataTabEconomic(data.dataTab);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchEconomic();
    }, [token, idUser, id, idCst]);


    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchInfoEconomic = async () => {
            try {
                const response = await fetch(`${apiUrl}/infoEconomic/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setDataTabInfoEconomic(data.dataTab || []);
                if (!isEqual(dataTabInfoEconomic, data)) {
                    setDataTabInfoEconomic(data.dataTab);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchInfoEconomic();
    }, [token, idUser, id, idCst]);


    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchEquipment = async () => {
            try {
                const response = await fetch(`${apiUrl}/equipment/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setDataTabEquipment(data.dataTab || []);
                if (!isEqual(dataTabEquipment, data)) {
                    setDataTabEquipment(data.dataTab);
                    setTractorNames(getTractorNames(data)); // Met à jour les noms des tracteurs
                    setSolNames(getSolNames(data));
                    setPulNames(getPulNames(data));
                    setAutreNames(getAutreNames(data));
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchEquipment();
    }, [token, idUser, id, idCst]);


    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchFightBis = async () => {
            try {
                const response = await fetch(`${apiUrl}/fight/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();

                // Filtrer les données pour n'afficher que celles avec les cases cochées
                if (data.dataTab && data.dataTab.length > 0) {
                    const filteredData = data.dataTab.reduce((acc, row) => {
                        if (row.col_2.checked) {
                            acc.push({ col_1: row.col_1 });  // Ajouter col_1 si col_2 est checked
                        }
                        if (row.col_4.checked) {
                            acc.push({ col_1: row.col_3 });  // Ajouter col_3 si col_4 est checked, mais en tant que col_1
                        }
                        return acc;
                    }, []);
                    setDataTabFightBis(filteredData);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchFightBis();
    }, [token, idUser, id, idCst]);

    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchFightBis = async () => {
            try {
                const response = await fetch(`${apiUrl}/fight/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();

                if (data.dataTab && data.dataTab.length > 0) {  // Vérifie si dataTab existe et contient des éléments
                    // Filtrer les données pour n'afficher que celles avec les cases cochées
                    const filteredData = data.dataTab.reduce((acc, row) => {
                        if (row.col_2.checked) {
                            acc.push({ col_1: row.col_1 });  // Ajouter col_1 si col_2 est checked
                        }
                        if (row.col_4.checked) {
                            acc.push({ col_1: row.col_3 });  // Ajouter col_3 si col_4 est checked, mais en tant que col_1
                        }
                        return acc;
                    }, []);
                    setDataTabFightBis(filteredData);
                } else {
                    setDataTabFightBis([]); // Optionnel : Réinitialiser ou définir selon besoin
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchFightBis();
    }, [token, idUser, id, idCst]); // Dépendances du useEffect


    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchFightPestBis = async () => {
            try {
                const response = await fetch(`${apiUrl}/fightPest/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();

                // Filtrer les données pour n'afficher que celles avec les cases cochées
                if (data.dataTab && data.dataTab.length > 0) {

                    const filteredData = data.dataTab.reduce((acc, row) => {
                        if (row.col_2.checked) {
                            acc.push({ col_1: row.col_1 });  // Ajouter col_1 si col_2 est checked
                        }
                        if (row.col_4.checked) {
                            acc.push({ col_1: row.col_3 });  // Ajouter col_3 si col_4 est checked, mais en tant que col_1
                        }
                        return acc;
                    }, []);


                    setDataTabFightPestBis(filteredData);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchFightPestBis();
    }, [token, idUser, id, idCst]);


    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchFightProcessBis = async () => {
            try {
                const response = await fetch(`${apiUrl}/fightProcess/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();

                // Filtrer les données pour n'afficher que celles avec les cases cochées
                if (data.dataTab && data.dataTab.length > 0) {
                    const filteredData = data.dataTab.reduce((acc, row) => {
                        if (row.col_2.checked) {
                            acc.push({ col_1: row.col_1 });  // Ajouter col_1 si col_2 est checked
                        }
                        if (row.col_4.checked) {
                            acc.push({ col_1: row.col_3 });  // Ajouter col_3 si col_4 est checked, mais en tant que col_1
                        }
                        return acc;
                    }, []);


                    setDataTabFightProcessBis(filteredData);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchFightProcessBis();
    }, [token, idUser, id, idCst]);

    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchFightBioBis = async () => {
            try {
                const response = await fetch(`${apiUrl}/fightBio/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                if (data.dataTab && data.dataTab.length > 0) {

                    // Filtrer les données pour n'afficher que celles avec les cases cochées
                    const filteredData = data.dataTab.reduce((acc, row) => {
                        if (row.col_2.checked) {
                            acc.push({ col_1: row.col_1 });  // Ajouter col_1 si col_2 est checked
                        }
                        if (row.col_4.checked) {
                            acc.push({ col_1: row.col_3 });  // Ajouter col_3 si col_4 est checked, mais en tant que col_1
                        }
                        return acc;
                    }, []);


                    setDataTabFightBioBis(filteredData);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchFightBioBis();
    }, [token, idUser, id, idCst]);

    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchFightChemisty = async () => {
            try {
                const response = await fetch(`${apiUrl}/fightChemisty/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();

                // Filtrer les données pour n'afficher que celles avec les cases cochées
                if (data.dataTab && data.dataTab.length > 0) {

                    const filteredData = data.dataTab.reduce((acc, row) => {
                        if (row.col_2.checked) {
                            acc.push({ col_1: row.col_1 });  // Ajouter col_1 si col_2 est checked
                        }
                        return acc;
                    }, []);
                    setDataTabFightChemistyBis(filteredData);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchFightChemisty();
    }, [token, idUser, id, idCst]);

    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchValidationQuizz = async () => {
            try {
                const response = await fetch(`${apiUrl}/validationQuizz/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setValidationQuizz(data.validationQuizz);
            } catch (error) {
                console.error(error);
            }
        };
        fetchValidationQuizz();
    }, [token, idUser, id, idCst]);



    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchValidationQuizz = async () => {
            try {
                const response = await fetch(`${apiUrl}/validationQuizz/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setValidationQuizz(data.validationQuizz || false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchValidationQuizz();
    }, [token, idUser, id, idCst]);

    useEffect(() => {
        const userIdToUse = id || idUser;
        const fetchWeakStrongPoints = async () => {
            try {
                console.log("je suis en haut du get")
                const response = await fetch(`${apiUrl}/weakStrongPoints/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                console.log("data")
                console.log(data)
                setWeakStrongPoints(data.commentaryData || "");
                if (!isEqual(commentaryWeakStrongPoints, data)) {
                    setWeakStrongPoints(data.commentaryData);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchWeakStrongPoints();
    }, [token, idUser, id, idCst]);

    useEffect(() => {
        const userIdToUse = id || idUser;
        const fetchWeakStrongEconomic = async () => {
            try {
                const response = await fetch(`${apiUrl}/weakStrongEconomic/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setWeakStrongEconomic(data.commentaryData || "");
                if (!isEqual(commentaryWeakStrongEconomic, data)) {
                    setWeakStrongEconomic(data.commentaryData);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchWeakStrongEconomic();
    }, [token, idUser, id, idCst]);

    useEffect(() => {
        const userIdToUse = id || idUser;
        const fetchPedoclimatic = async () => {
            try {
                const response = await fetch(`${apiUrl}/pedoclimatic/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setPedoclimatic(data.commentaryData || "");
                if (!isEqual(commentaryPedoclimatic, data)) {
                    setPedoclimatic(data.commentaryData);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchPedoclimatic();
    }, [token, idUser, id, idCst]);



    // ---------------------------------
    useEffect(() => {
        // Vérifiez si dataTabCrop est bien un tableau et contient des éléments.
        if (Array.isArray(dataTabCrop) && dataTabCrop.length > 0) {
            const cultureSet = new Set(); // Set pour stocker les cultures uniques
            const newTotals = dataTabCrop.reduce((acc, row) => {
                // Assurez-vous que col_5 est un nombre valide avant d'ajouter à totalSurfaces.
                acc.totalSurfaces += parseFloat(row.col_5) || 0;
    
                // Traitez col_4 pour compter les cultures uniques.
                if (row.col_4) {
                    const cultures = row.col_4.split(',').map(culture => culture.trim());
                    cultures.forEach(culture => {
                        if (culture) cultureSet.add(culture); // Ajouter seulement si non vide
                    });
                }
    
                // Incrémentez totalVarietes si col_6 a une valeur non vide.
                if (row.col_6) acc.totalVarietes += 1;
    
                return acc;
            }, { totalSurfaces: 0, totalVarietes: 0 });
    
            // Ajoutez le nombre de cultures uniques à newTotals.
            newTotals.totalCultures = cultureSet.size;
    
            setTotals(newTotals);
        } else {
            // Initialiser les totaux à zéro si dataTabCrop n'est pas un tableau ou est vide.
            setTotals({ totalCultures: 0, totalSurfaces: 0, totalVarietes: 0 });
        }
    }, [dataTabCrop]);
    




    useEffect(() => {

        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
    }, [navigate]);



    // Fonction pour gérer les changements des cases à cocher dans dataTabEquipment
    const handleCheckboxChange = (rowIndex, columnKey, checked) => {
        const updatedData = dataTabEquipment.map((row, index) => {
            if (index === rowIndex) {
                return { ...row, [columnKey]: { ...row[columnKey], checked } };
            }
            return row;
        });
        setDataTabEquipment(updatedData);
    };

    const addNewEquipmentRow = () => {
        // Modèle pour une nouvelle ligne avec une case à cocher dans chaque colonne
        const newRow = {
            col_1: "",
            col_2: { checked: false },
            col_3: { checked: false },
            col_4: { checked: false },
            col_5: { checked: false }
        };

        if (!Array.isArray(dataTabEquipment)) {
            setDataTabEquipment([newRow, newRow]);
            setNeedsSaveEquipment(true)
        } else {
            setDataTabEquipment([...dataTabEquipment, newRow]);
            setNeedsSaveEquipment(true)
        }
    };

    const dataTabTotals = [
        { col_1: "Total des Cultures", col_2: totals.totalCultures },
        { col_1: "Total des Surfaces", col_2: totals.totalSurfaces },
        { col_1: "Total des Variétés", col_2: totals.totalVarietes }
    ];

    function getTractorNames(data) {
        if (!data || !data.dataTab) {
            return [];
        }

        return data.dataTab
            .filter(item => item.col_2.checked)
            .map(item => ({ name: item.col_1 }));
    }

    function getSolNames(data) {
        if (!data || !data.dataTab) {
            return [];
        }

        return data.dataTab
            .filter(item => item.col_3.checked)
            .map(item => ({ name: item.col_1 }));
    }

    function getPulNames(data) {
        if (!data || !data.dataTab) {
            return [];
        }

        return data.dataTab
            .filter(item => item.col_4.checked)
            .map(item => ({ name: item.col_1 }));
    }

    function getAutreNames(data) {
        if (!data || !data.dataTab) {
            return [];
        }

        return data.dataTab
            .filter(item => item.col_5.checked)
            .map(item => ({ name: item.col_1 }));
    }


    const concatenatedTractorNames = tractorNames.map(t => t.name).join(", ");
    const dataTable = [{ "Tracteurs": concatenatedTractorNames }];

    const concatenatedSolNames = solNames.map(t => t.name).join(", ");
    const dataTableSol = [{ "Travail du Sol": concatenatedSolNames }];

    const concatenatedPulNames = pulNames.map(t => t.name).join(", ");
    const dataTablePul = [{ "Tracteurs": concatenatedPulNames }];

    const concatenatedAutreNames = autreNames.map(t => t.name).join(", ");
    const dataTableAutre = [{ "Tracteurs": concatenatedAutreNames }];

    useEffect(() => {
        setlistEquipment([
            { col_1: "Tracteur", col_2: concatenatedTractorNames, col_3: "" },
            { col_1: "Travail du Sol", col_2: concatenatedSolNames, col_3: "" },
            { col_1: "Pulvérisation", col_2: concatenatedPulNames, col_3: "" },
            { col_1: "Autres", col_2: concatenatedAutreNames, col_3: "" },
        ]);
    }, [concatenatedTractorNames, concatenatedSolNames, concatenatedPulNames, concatenatedAutreNames]);

    const handlePrint = () => {
        window.print(); // Cette fonction déclenche l'impression du document
    };

    return (
        <>

            <style>
                {`
          @media print {
            .print-hide, .MuiButton-root, .user-name-button, .bouton-personnalise, .bouton-personnalise-2, .navbar, .tapBar, .bouton-personnalise-4, .bouton-personnalise-3 {
              display: none !important; /* Cacher les boutons et autres éléments non désirés */
            }
          
            .dynamic-table, .dynamic-table-row {
              page-break-inside: avoid; /* Essayer d'éviter la coupure des tableaux */
            }

            .dynamic-table, .commentary {
              margin-bottom: 50px; /* Ajoutez plus d'espacement en bas de chaque tableau/commentaire */
            }

            .date-input-view {
              display: none; /* Masque l'input de date en mode impression */
            }
          
            .date-text-view {
              display: block !important; /* Affiche le texte de la date en mode impression */
              border: none; /* Supprime tout cadre qui pourrait être appliqué */
              background: transparent; /* Assure un fond transparent */
              padding: 0; /* Supprime le padding pour éviter tout espace inutile */
              margin: 0 auto; /* Centrage horizontal */
              font-size: 18px; /* Assure que la taille de la police est appropriée */
            }
          }
          
          /* Styles par défaut (pas en mode impression) */
          .date-input-view {
            display: block; /* Affiche l'input de date normalement */
          }
          
          .date-text-view {
            display: none; /* Masque le texte de la date normalement */
          }

          #tableauAdapte {
            font-size: 10px; /* Réduction de la taille de la police */
            transform: scale(0.8); /* Ajustement de la taille */
            transform-origin: top left; /* Origine de la transformation */
            /* Ajoutez d'autres styles d'adaptation ici */
          }
          
          }
        `}
            </style>
            <Navbar />
            <Header />
            <Button className="user-name-button"
                onClick={(e) => handleUserClick(e, selectedUser)}
                style={{
                    float: 'right', marginRight: '40px', border: '1px solid #1E8449 ', padding: '5px 10px', backgroundColor: '#0E6655',
                    color: 'white'
                }}>
                Sélectionner archive ▼
            </Button>
            <div style={{ position: 'absolute', top: 0, right: 0, marginTop: '64px', marginRight: '16px' }}>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem sx={{ py: 0.5 }}> {/* Ajuster l'espacement vertical du MenuItem */}
                        <TextField
                            fullWidth
                            label="Rechercher par numéro ou date" // Mettre à jour le libellé
                            variant="outlined"
                            size="small" // Utiliser la taille "small" pour le TextField
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={{
                                "& .MuiOutlinedInput-input": {
                                    fontSize: '0.875rem', // Réduire la taille de la police
                                },
                                "& .MuiInputLabel-root": {
                                    fontSize: '0.875rem', // Réduire la taille de la police du label
                                },
                                "& .MuiSvgIcon-root": {
                                    fontSize: '1rem', // Réduire la taille des icônes
                                },
                                mb: 1, // Ajuster la marge basse pour séparer du bouton
                            }}
                        />
                    </MenuItem>
                    {isAdmin && <MenuItem onClick={handleNewStrategic} sx={{ my: 0.5, justifyContent: 'center' }}> {/* Réduire l'espacement vertical pour le bouton */}
                        <Button
                            sx={{
                                fontSize: '0.875rem',
                                padding: '4px 10px', // Réduire le padding pour diminuer la hauteur du bouton
                                lineHeight: 1, // Ajuster la hauteur de ligne si nécessaire
                                textTransform: 'none', // Optionnel, pour enlever la transformation en majuscules
                            }}
                        >
                            + Créer Nouvelle Fiche
                        </Button>
                    </MenuItem>}
                    {filteredStrategic
                        ?.sort((a, b) => b.idCst - a.idCst)
                        .map((strategic, index) => (
                            <MenuItem key={index} onClick={() => handleOldStrategic(strategic.idCst)} sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.875rem', py: 0.5 }}>
                                {`Fiche ${strategic.idCst} - ${formatDate(strategic.strategicDateOnly)}`}
                            </MenuItem>
                        ))}

                </Menu>
            </div>
            <div className="relative">
                <ArrowBack />
                <PageTitle title={`Fiche de préconisation - Conseil stratégique n°${idCst}`} />
                <div className="relative">
                    <div style={{ textAlign: 'center', margin: '20px', fontSize: '20px' }}>
                        {(!validationQuizz) && <button onClick={handleNavigate} className="bouton-personnalise-3">
                            Questionnaire - {idCst}
                        </button>}
                        {validationQuizz && <button onClick={handleNavigate} className="bouton-personnalise-4">
                            Questionnaire - {idCst}
                        </button>}
                    </div>
                    <div style={{ textAlign: 'center', margin: '20px', fontSize: '20px' }}>
                        <span>Date de préconisation : </span>
                        {isAdmin ? (
                            <input
                                type="date"
                                value={strategicDateFormated || ""}
                                onChange={e => handleStrategicDateChange(e.target.value)}
                                style={{ fontSize: '18px', padding: '5px', marginLeft: '10px' }}
                            />
                        ) : (
                            <span>{formatDate(strategicDateFormated)}</span>
                        )}
                    </div>
                    <Title text="Identification de l'exploitation agricole" />
                    <div className="tapBar">
                        <TabBar tabs={tabs} />
                    </div>
                    <DynamicTable onDataChange={handleDataChangeIdCt} dataTab={dataTabIdCt} columnNames={[]} title="Diagnostic conseil stratégique phytosanitaire" titleStyle={{ backgroundColor: '', textAlign: 'center', padding: '10px' }} editableAdmin boldFirstThirdColumns />
                    <Title text="Caractéristique du système d'exploitation" />
                    <DynamicTable onDataChange={handleDataChangeFeatures} showDeleteRow={true} dataTab={dataTabFeatures} columnNames={["Qualité", "Nom", "Prénom", "N°", "Type", "Date d'expiration"]} title="Personnel détenant le certiphyto " titleStyle={{ backgroundColor: '', padding: '10px' }} editableAdmin name="features" />
                    {isAdmin &&
                        <button
                            onClick={addNewFeaturesRow}
                            className="bouton-personnalise"
                        >
                            Ajouter Personnel
                        </button>}
                    <DynamicTable onDataChange={handleDataChangeProvider} showDeleteRow={true} dataTab={dataTabProvider} columnNames={["Inventions concernés", "Entreprise", "Agrement", "CF"]} title="Prestataire de service intervenant sur l'exploitation en lien avec l'utilisation des Produits Phytosanitaires" titleStyle={{ backgroundColor: '', padding: '10px' }} editableAdmin name="provider" />
                    {isAdmin &&
                        <button
                            onClick={addNewProviderRow}
                            className="bouton-personnalise"
                        >
                            Ajouter Prestatire
                        </button>}
                    <Title text="Spécifité pédo-climatique de l'exploitation" />
                    <DynamicTable onDataChange={handleDataChangeSurface} dataTab={dataTabSurface} columnNames={[]} title="" editableAdmin />
                    <DynamicTable onDataChange={handleDataChangeCrop} showDeleteRow={true} dataTab={dataTabCrop} columnNames={["Qualité", "Parcelles Concernées", "Précédents culturaux", "Culture", "Surface", "Variété"]} title="Assolement : (année N et N-1 si campagne en cours)" editableAdmin name="crop" />
                    {isAdmin &&
                        <button
                            onClick={addNewCropRow}
                            className="bouton-personnalise"
                        >
                            Ajouter Assolement
                        </button>}
                    <DynamicTable
                        onDataChange={() => { }}
                        dataTab={dataTabTotals}
                        columnNames={["Description", "Total"]}
                        title="Totaux"
                        titleStyle={{ backgroundColor: '', textAlign: 'center', padding: '10px' }}
                        editableAdmin
                    />
                    <Commentary onDataChange={handleDataChangePedoclimatic} commentaryData={commentaryPedoclimatic} title="Points forts et points faibles pédoclimatique :" placeholder={"Points forts et points faibles pédoclimatique :"} titleStyle={{ marginLeft: '10px' }} editableAdmin />
                    <Title text="Situation économique de l'exploitation agricole" />
                    <DynamicTable onDataChange={handleDataChangeInfoEconomic} dataTab={dataTabInfoEconomic} columnNames={[".", "Année N-1", "Année N"]} title="" editableAdmin boldAndLockFirstFourthRows />
                    <DynamicTable
                        onDataChange={() => { }}
                        dataTab={listEquipment}
                        columnNames={["Type", "Noms", "CT Pulverisateur et Rampe"]}
                        title="Liste du Matériel Disponible"
                        titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }}
                        editableAdmin
                    />
                    <Commentary onDataChange={handleDataChangeWeakStrongEconomic} commentaryData={commentaryWeakStrongEconomic} title="Points forts et points faibles économique :" placeholder={"Points forts et points faibles économique : "} titleStyle={{ marginLeft: '10px' }} editableAdmin />
                    <div style={{ marginTop: "40px", marginLeft: '10px' }}>
                        {totals.totalCultures === 0 ?
                            "Bilan Phyto par Culture : Annexes 1 à 1" :
                            `Bilan Phyto par Culture : Annexes 1 à ${totals.totalCultures}`
                        }
                    </div>

                    
                    <Commentary onDataChange={handleDataChangeWeakStrongPoints} commentaryData={commentaryWeakStrongPoints} title="Points forts et points faibles :" placeholder={"Points forts et points faibles : "} titleStyle={{ marginLeft: '10px' }} editableAdmin />
                    <Title text="Méthodes de lutte intgérée utlisée sur l'exploitation" />
                    <DynamicTable onDataChange={handleDataChangeFight} showCheckboxes={true} dataTab={dataTabFightBis} columnNames={["A"]} title="A- favoriser son autodéfense : " titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} editableAdmin LockFirstThirdColumns hideTitle fullWidth />
                    <DynamicTable onDataChange={handleDataChangeFightEnv} showCheckboxes={true} dataTab={dataTabFightEnvBis} columnNames={["B"]} title="B- Agir sur l'environnement de la plantes : " titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} editableAdmin LockFirstThirdColumns hideTitle fullWidth />
                    <DynamicTable onDataChange={handleDataChangeFightPest} showCheckboxes={true} dataTab={dataTabFightPestBis} columnNames={["C"]} title="C- Agir sur le bioagresseur en dehors de sa phase néfaste : " titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} editableAdmin LockFirstThirdColumns hideTitle fullWidth />
                    <DynamicTable onDataChange={handleDataChangeFightProcess} showCheckboxes={true} dataTab={dataTabFightProcessBis} columnNames={["D"]} title="D- Procédés physiques : " titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} editableAdmin LockFirstThirdColumns hideTitle fullWidth />
                    <DynamicTable onDataChange={handleDataChangeFightBio} showCheckboxes={true} dataTab={dataTabFightBioBis} columnNames={["E"]} title="E- Procédés Biologiques : " titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} editableAdmin LockFirstThirdColumns hideTitle fullWidth />
                    <div style={{ marginBottom: '50px' }}>
                        <DynamicTable
                            onDataChange={handleDataChangeFightChemisty}
                            showCheckboxes={true}
                            dataTab={dataTabFightChemistyBis}
                            columnNames={["F"]}
                            title="F- Procédé chimique : "
                            titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }}
                            editableAdmin
                            LockFirstThirdColumns
                            fullWidth
                            hideTitle
                        />
                    </div>

                    <div className="container mx-auto relative z-10">
                        <div className="flex flex-col items-center justify-center min-h-screen">
                        </div>
                    </div>
                </div>
                <button
                    onClick={() => navigate(`/${id}/annexe/${idCst}/1`)}
                    className="print-hide"
                    style={{
                        position: 'fixed', // Se fixe dans le coin inférieur droit de l'écran
                        bottom: '20px', // À 20px du bas
                        right: '20px', // À 20px de la droite
                        height: '50px', // Hauteur du bouton
                        width: '50px', // Largeur du bouton (ajustez la taille selon les besoins)
                        borderRadius: '50%', // Forme circulaire
                        backgroundColor: '#00b09b', // Couleur de fond (bleu primaire de Bootstrap par exemple)
                        color: 'white', // Couleur de la flèche
                        fontSize: '30px', // Taille du texte de la flèche/de l'icône
                        lineHeight: '55px', // Centre la flèche verticalement
                        boxShadow: '0px 2px 5px rgba(0,0,0,0.2)', // Ombre optionnelle pour la profondeur
                        border: 'none', // Pas de bordure
                        cursor: 'pointer', // Change le curseur au survol
                    }}
                    aria-label="Naviguer vers la page suivante"
                >
                    ↪
                </button>
                <button
                    onClick={handlePrint}
                    className="bouton-personnalise-2"
                >
                    Imprimer
                </button>
            </div>
        </>
    );
}


