import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from "../component/dynamicTable.tsx";
import Navbar from "../component/navbar.tsx";
import Title from "../component/title.tsx";
import Header from "../component/header.tsx";
import { Buffer } from "buffer";
import isEqual from 'lodash/isEqual';
import TabBar from "../component/tapBar.tsx";

export default function CahierCultureBis() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [dataTabAction, setDataTabAction] = useState([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "" }]);
  const token = localStorage.getItem('token');
  const { id, idCst } = useParams();
  const [needsSaveAction, setNeedsSaveAction] = useState(false);
  const tabs = [
    { label: 'Partie 1', path: `/${id}/rapport/${idCst}` },
    { label: 'Annexe(s)', path: `/${id}/annexe/${idCst}/1` },
    { label: 'Partie 2', path: `/${id}/rapport2/${idCst}` },
    { label: 'Partie 3', path: `/${id}/rapport3/${idCst}` },
  ];
  const apiUrl = process.env.REACT_APP_API_URL;

  let idUser = null;
  let isAdmin = false;

  if (token) {
    const tokenBody = token.split('.')[1];
    if (tokenBody) {
      const decodedToken = JSON.parse(Buffer.from(tokenBody, 'base64').toString());
      idUser = decodedToken.id;
      isAdmin = decodedToken.admin;
    }
  }

  // ------------- POST ------------

  const saveDataToAction = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/action/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (needsSaveAction) {
      // Procéder à la sauvegarde des données
      saveDataToAction(dataTabAction)
        .then(() => setNeedsSaveAction(false)) // Réinitialiser l'indicateur après la sauvegarde
        .catch(error => {
          console.error(error);
          // Gérer l'erreur de sauvegarde si nécessaire
        });
    }
  }, [needsSaveAction]);

  // ------------- HANDLE ------------

  const handleDataChangeAction = useCallback((newData) => {
    if (!isEqual(dataTabAction, newData)) {
      setDataTabAction(newData);
      saveDataToAction(newData);
    }
  }, [dataTabAction, saveDataToAction]);

  // --------------- GET --------------

  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchAction = async () => {
      try {
        const response = await fetch(`${apiUrl}/action/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabAction(data.dataTab || []);
        if (!isEqual(dataTabAction, data)) {
          setDataTabAction(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchAction();
  }, [token, idUser, id, idCst]);


  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
  }, [token, navigate]);

  const handlePrint = () => {
    window.print(); 
  };
  

  const printButtonStyle = {
    padding: '10px 20px',
    margin: '20px',
    backgroundColor: 'blue',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
  };

  const addNewActionRow = () => {
    const newRow = {
      col_1: "",
      col_2: "",
      col_3: "",
      col_4: "",
      col_5: "",
      col_6: "",
      col_7: "",
    };
    if (!Array.isArray(dataTabAction)) {
      setDataTabAction([newRow, newRow]);
      setNeedsSaveAction(true);
    } else {
      setDataTabAction([...dataTabAction, newRow]);
      setNeedsSaveAction(true);
    }
  };

  return (
    <>
      <style>
        {`
          @media print {
            .print-hide, .MuiButton-root, .user-name-button, .bouton-personnalise, .bouton-personnalise-2, .navbar, .tapBar, .bouton-personnalise-4, .bouton-personnalise-3 {
              display: none !important; /* Cacher les boutons et autres éléments non désirés */
            }
          
            .dynamic-table, .dynamic-table-row {
              page-break-inside: avoid; /* Essayer d'éviter la coupure des tableaux */
            }

            .dynamic-table, .commentary {
              margin-bottom: 50px; /* Ajoutez plus d'espacement en bas de chaque tableau/commentaire */
            }

            .date-input-view {
              display: none; /* Masque l'input de date en mode impression */
            }
          
            .date-text-view {
              display: block !important; /* Affiche le texte de la date en mode impression */
              border: none; /* Supprime tout cadre qui pourrait être appliqué */
              background: transparent; /* Assure un fond transparent */
              padding: 0; /* Supprime le padding pour éviter tout espace inutile */
              margin: 0 auto; /* Centrage horizontal */
              font-size: 18px; /* Assure que la taille de la police est appropriée */
            }
            .dynamic-table {
              font-size: 10px; /* Ajustez selon le besoin */
              transform: scale(0.99); /* Ajustez l'échelle selon le besoin */
              transform-origin: top left; /* Définit l'origine de la transformation */
            }
          }
          
          /* Styles par défaut (pas en mode impression) */
          .date-input-view {
            display: block; /* Affiche l'input de date normalement */
          }
          
          .date-text-view {
            display: none; /* Masque le texte de la date normalement */
          }

          #tableauAdapte {
            font-size: 10px; /* Réduction de la taille de la police */
            transform: scale(0.8); /* Ajustement de la taille */
            transform-origin: top left; /* Origine de la transformation */
            /* Ajoutez d'autres styles d'adaptation ici */
          }
          
          }
        `}
      </style>

      <Navbar />
      <Header />

      <div className="relative">
        <Title text="Plan d'Action : " />
        <div className="tapBar">
        <TabBar tabs={tabs}/>
        </div>
        <div style={{ overflowX: 'auto', marginBottom: '50px' }}>
          <DynamicTable
            onDataChange={handleDataChangeAction}
            showDeleteRow={true}
            dataTab={dataTabAction}
            title=""
            columnNames={["Intitulé de l'action", "Objectif de réduction de PP", "Moyen humain nécessaire", "Estimation du coût de mise en œuvre", "Outil/ref utilisées", "Action CEPP", "Objectif date de mise en œuvre"]}
            editableAdmin
            name="action"
          />
        </div>
        {isAdmin && <button
          onClick={addNewActionRow}
          className="bouton-personnalise"
        >
          Ajouter Action
        </button>}
      </div>
      <button
        onClick={() => navigate(`/${id}/rapport2/${idCst}`)} // Remplacez '/prochaine-page' par votre route cible
        className="print-hide"
        style={{
          position: 'fixed', // Se fixe dans le coin inférieur gauche de l'écran
          bottom: '20px', // À 20px du bas
          left: '20px', // À 20px de la gauche (au lieu de `right`)
          height: '50px', // Hauteur du bouton
          width: '50px', // Largeur du bouton (ajustez la taille selon les besoins)
          borderRadius: '50%', // Forme circulaire
          backgroundColor: '#00b09b', // Couleur de fond (bleu primaire de Bootstrap par exemple)
          color: 'white', // Couleur de la flèche
          fontSize: '24px', // Taille du texte de la flèche/de l'icône
          lineHeight: '55px', // Centre la flèche verticalement
          boxShadow: '0px 2px 5px rgba(0,0,0,0.2)', // Ombre optionnelle pour la profondeur
          border: 'none', // Pas de bordure
          cursor: 'pointer', // Change le curseur au survol
        }}
        aria-label="Naviguer vers la page suivante"
      >
        ↩
      </button>
      <button
        onClick={handlePrint}
        className="bouton-personnalise-2"
      >
        Imprimer
      </button>
    </>
  );
}


