import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './screens/Register.tsx';
import SignIn from './screens/SignIn.tsx';
import Home from './screens/home.tsx';
import CahierCulture from './screens/cahierDeCulture.tsx';
import UserList from './screens/listUser.tsx';
import ConfirmationPage from './screens/confirmPage.tsx';
import DetailsUser from './screens/detailsUser.tsx';
import ConseilStategique from './screens/conseilStrat.tsx';
import ConseilSpecifique from './screens/conseilSpe.tsx';
import CspAdmin from './screens/CspAdmin.tsx';
import Rapport from './screens/Rapport.tsx';
import Rapport2 from './screens/rapport2.tsx';
import Rapport3 from './screens/rapport3.tsx';
import FileUploadPage from './screens/upload.tsx';
import Annexe from './screens/annexe.tsx';
import CahierCultureAdmin from "./screens/cahierCultureAdmin.tsx";
import CstAdmin from "./screens/CstAdmin.tsx";
import ResetPassword from "./screens/resetPassword.tsx";
import ProfilePage from "./screens/profil.tsx";
import PlotInventory from "./screens/plotInventory.tsx";

function App() {

  return (
    <Router>
      <Routes>
        {/* Définissez le chemin '/' pour afficher le composant Register */}
        <Route path="/" element={<Register />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/home" element={<Home />} />
        <Route path="/:id/cahierCulture/:idCulture" element={<CahierCulture />} />
        <Route path="/listeUtilisateurs" element={<UserList />} />
        <Route path="/confirmPage" element={<ConfirmationPage />} />
        <Route path="/user/:id/details" element={<DetailsUser />} />
        <Route path="/:id/questionnaireCst/:idCst" element={<ConseilStategique />} />
        <Route path="/:id/conseilSpecifique/:idCsp" element={<ConseilSpecifique />} />
        <Route path="/cspAdmin" element={<CspAdmin />} />
        <Route path="/cstAdmin" element={<CstAdmin />} />
        <Route path="/cahierCultureAdmin" element={<CahierCultureAdmin />} />
        <Route path="/:id/Rapport/:idCst" element={<Rapport />} />
        <Route path="/:id/rapport2/:idCst" element={<Rapport2 />} />
        <Route path="/:id/rapport3/:idCst" element={<Rapport3 />} />
        <Route path="/upload" element={<FileUploadPage />} />
        <Route path=":id/annexe/:idAnnexe/:idCst" element={<Annexe />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
        <Route path="/profil" element={<ProfilePage />} />
        <Route path="/plotInventory/:id" element={<PlotInventory />} />

        {/* Ajoutez d'autres routes si nécessaire */}
      </Routes>
    </Router>
  );
}

export default App;
