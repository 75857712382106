import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Grid, TextField, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled, CssBaseline, InputAdornment, IconButton, Snackbar } from '@mui/material';
import Navbar from '../component/navbar.tsx';
import { useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MuiAlert from '@mui/material/Alert';


const ProfileButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProfilePage = () => {
    // États pour gérer les informations de l'utilisateur et les entrées du formulaire
    const apiUrl = process.env.REACT_APP_API_URL;

    const [userDetails, setUserDetails] = useState({});
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [error, setError] = useState({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    });
    let idUser = null;

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        if (token) {
            const tokenBody = token.split('.')[1];
            if (tokenBody) {
                const decodedToken = JSON.parse(Buffer.from(tokenBody, 'base64').toString());
                idUser = decodedToken.id;
            }
        }
    })


    const passwordIsValid = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    // État pour contrôler l'affichage de la boîte de dialogue de confirmation
    const [openDialog, setOpenDialog] = useState(false);

    // Fonction pour fermer la boîte de dialogue de confirmation
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // Fonction de gestion de la soumission du formulaire qui ouvre la boîte de dialogue
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = true;
        let errors = { oldPassword: '', newPassword: '', confirmNewPassword: '' };

        if (!passwordIsValid(newPassword)) {
            errors.newPassword = "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.";
            isValid = false;
        }

        if (newPassword !== confirmNewPassword) {
            errors.confirmNewPassword = "Les mots de passe ne correspondent pas.";
            isValid = false;
        }

        if (!oldPassword) {
            errors.oldPassword = "Veuillez entrer votre mot de passe actuel.";
            isValid = false;
        }

        setError(errors);

        if (isValid) {
            handleChangePassword(newPassword);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleChangePassword = (newPassword) => {
        fetch(`${apiUrl}/user/password`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ idUser, oldPassword, newPassword })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la modification du mot de passe');
                }
                // Mettre à jour l'état avec les nouvelles données utilisateur
                return response.json();
            })
            .then(updatedUser => {
                setUserDetails(prevDetails => ({ ...prevDetails, ...updatedUser }));
                // Réinitialiser le champ de saisie du nouveau mot de passe
                setNewPassword('');
                setOpenSnackbar(true); // Pour afficher le snackbar de succès
                setTimeout(() => navigate('/home'), 3000); // Rediriger après 3 secondes
            })
            .catch(error => {
                console.error("Erreur lors de la modification du mot de passe :", error);
                setOpenErrorSnackbar(true); // Affiche la Snackbar d'échec
            });
        handleCloseDialog();
    };

    useEffect(() => {
        if (openSnackbar) {
            setOpenErrorSnackbar(false); // Ferme la Snackbar d'échec lorsque la Snackbar de succès s'ouvre
        }
    }, [openSnackbar]);

    return (
        <>
            <Navbar />
            <div style={{
                backgroundImage: 'url("/fond_mdp.jpeg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Container maxWidth="md" sx={{ mt: 4 }}>
                    <CssBaseline />
                    <Box
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            padding: 3,
                            borderRadius: 2,
                            boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img src="/logo.png" alt="Logo" style={{ width: '100px', height: 'auto', marginBottom:'30px'}} />
                        <Typography variant="h4" gutterBottom>Mon Profil</Typography>
                        <Grid container spacing={3} alignItems="center">
                            {/* Autres éléments de l'UI ici */}
                        </Grid>

                        {/* Formulaire de modification du mot de passe */}
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                            <Typography variant="h6">Changer votre mot de passe</Typography>
                            <TextField
                                error={!!error.oldPassword}
                                helperText={error.oldPassword}
                                margin="normal"
                                required
                                fullWidth
                                name="oldPassword"
                                label="Ancien mot de passe"
                                type={showPassword ? 'text' : 'password'}
                                id="oldPassword"
                                autoComplete="current-password"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                error={!!error.newPassword}
                                helperText={error.newPassword}
                                margin="normal"
                                required
                                fullWidth
                                name="newPassword"
                                label="Nouveau mot de passe"
                                type={showPassword ? 'text' : 'password'}
                                id="newPassword"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                error={!!error.confirmNewPassword}
                                helperText={error.confirmNewPassword}
                                margin="normal"
                                required
                                fullWidth
                                name="confirmNewPassword"
                                label="Confirmer le nouveau mot de passe"
                                type={showPassword ? 'text' : 'password'}
                                id="confirmNewPassword"
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <ProfileButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, bgcolor: 'green', '&:hover': { bgcolor: 'darkgreen' } }}
                            >
                                Mettre à jour le mot de passe
                            </ProfileButton>
                        </Box>

                        {/* Boîte de dialogue de confirmation */}
                        <Dialog open={openDialog} onClose={handleCloseDialog}>
                            <DialogTitle>Confirmation</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Êtes-vous sûr de vouloir changer votre mot de passe ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog}>Annuler</Button>
                                <Button onClick={() => handleChangePassword(newPassword)} autoFocus>
                                    Confirmer
                                </Button>

                            </DialogActions>
                        </Dialog>
                        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                            <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                                Mot de passe modifié avec succès.
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                            <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error" sx={{ width: '100%' }}>
                                Échec de la modification du mot de passe.
                            </Alert>
                        </Snackbar>
                    </Box>
                </Container>
            </div >
        </>
    );
};

export default ProfilePage;
