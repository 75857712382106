import React, { useEffect, useState } from "react";
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import Navbar from "../component/navbar.tsx";
import TripleSwitch from "../component/tripleswitch.tsx";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, createTheme, ThemeProvider } from '@mui/material';
import * as XLSX from 'xlsx';

export default function UserList() {
    const [users, setUsers] = useState([]);
    const [searchByPhone, setSearchByPhone] = useState('');
    const [searchByEmail, setSearchByEmail] = useState('');
    const [searchByAddress, setSearchByAddress] = useState('');
    const [searchByPostalCode, setSearchByPostalCode] = useState('');
    const [searchByRaisonSociale, setSearchByRaisonSociale] = useState('');
    const [searchByVille, setSearchByVille] = useState('');
    const [searchByConseilStrategique, setSearchByConseilStrategique] = useState(false);
    const [searchByConseilSpecifique, setSearchByConseilSpecifique] = useState(false);
    const [searchByCahierCulture, setSearchByCahierCulture] = useState(false);
    const [disabledUserId, setDisabledUserId] = useState(null);
    const [filterActif, setFilterActif] = useState('tous');
    const [openDialog, setOpenDialog] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;


    const navigate = useNavigate();


    useEffect(() => {

        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        fetch(`${apiUrl}/user/users`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des utilisateurs');
                }
                return response.json();
            })
            .then(data => {

                setUsers(data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [navigate]);


    const handleSwitchChange = (option) => {
        setFilterActif(option); // Mettre à jour l'état avec l'option sélectionnée
    };

    const handleOpenDialog = (userId) => {
        setUserIdToDelete(userId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = () => {
        if (userIdToDelete) {
            handleDeleteUser(userIdToDelete);
            setOpenDialog(false);
        }
    };

    const handleDeleteUser = (userId) => {
        setOpenDialog(false);
        fetch(`${apiUrl}/user/${userId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la suppression de l\'utilisateur');
                }
                return response.json();
            })
            .then(() => {
                setUsers(users.filter(user => user.idUser !== userId));
            })
            .catch(error => {
                console.error("Erreur lors de la suppression :", error);
            });
    };




    const handleActiveChange = (userId, isActive) => {

        setDisabledUserId(userId);
        fetch(`${apiUrl}/user/${userId}/active`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ active: isActive })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la mise à jour du statut actif');
                }
                return response.json();
            })
            .then(data => {
                // Mise à jour de l'état des utilisateurs avec les nouvelles informations
                const updatedUsers = users.map(user => {
                    if (user.idUser === userId) {
                        return { ...user, active: data.isActive }; // Assurez-vous que 'data.active' renvoie le nouvel état actif de l'utilisateur
                    }
                    return user;
                });
                setUsers(updatedUsers);
            })
            .catch(error => {
                console.error("Erreur lors de la mise à jour :", error);
            })
            .finally(() => {
                setDisabledUserId(null)
            })
    };


    const theme = createTheme({
        palette: {
            primary: {
                main: '#0f7d21', // Votre couleur primaire personnalisée
            },
            secondary: {
                main: '#918f8d', // Votre couleur secondaire personnalisée
            },
            // Vous pouvez également ajouter des couleurs supplémentaires si nécessaire
        },
    });


    const filteredUsers = users.filter(user => {
        const filterByPhone = searchByPhone === '' || (user.telephone || '').toLowerCase().includes(searchByPhone.toLowerCase());
        const filterByEmail = searchByEmail === '' || (user.email || '').toLowerCase().includes(searchByEmail.toLowerCase());
        const filterByAddress = searchByAddress === '' || (user.adresse || '').toLowerCase().includes(searchByAddress.toLowerCase());
        const filterByPostalCode = searchByPostalCode === '' || (user.postalCode || '').toLowerCase().includes(searchByPostalCode.toLowerCase());
        const filterByRaisonSociale = searchByRaisonSociale === '' || (user.raisonSociale || '').toLowerCase().includes(searchByRaisonSociale.toLowerCase());
        const filterByVille = searchByVille === '' || (user.ville || '').toLowerCase().includes(searchByVille.toLowerCase());
        const filterByConseilStrategique = !searchByConseilStrategique || user.conseilStrategique === searchByConseilStrategique;
        const filterByConseilSpecifique = !searchByConseilSpecifique || user.conseilSpecifique === searchByConseilSpecifique;
        const filterByCahierCulture = !searchByCahierCulture || user.cahierCulture === searchByCahierCulture;
        if (filterActif === 'actif' && !user.active) return false;
        else if (filterActif === 'inactif' && user.active) return false;

        return filterByPhone && filterByEmail && filterByAddress && filterByPostalCode && filterByRaisonSociale && filterByVille && filterByConseilStrategique && filterByConseilSpecifique && filterByCahierCulture;
    });

    const handleViewClient = (userId) => {
        navigate(`/user/${userId}/details`);
    };


    const handleConseilStrategiqueChange = (userId, newValue) => {
        updateOption(userId, { conseilStrategique: newValue }, 'conseilStrategique');
    };

    const handleConseilSpecifiqueChange = (userId, newValue) => {
        updateOption(userId, { conseilSpecifique: newValue }, 'conseilSpecifique');
    };

    const handleCahierCultureChange = (userId, newValue) => {
        updateOption(userId, { cahierCulture: newValue }, 'cahierCulture');
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const updateOption = (userId, dataToUpdate, optionEndpoint) => {

        fetch(`${apiUrl}/user/${userId}/${optionEndpoint}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(dataToUpdate)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Erreur lors de la mise à jour de ${optionEndpoint}`);
                }
                return response.json();
            })
            .then(data => {
                // Mettre à jour l'état des utilisateurs avec les nouvelles informations
                const updatedUsers = users.map(user => {
                    if (user.idUser === userId) {
                        return { ...user, ...data };
                    }
                    return user;
                });
                setUsers(updatedUsers);
            })
            .catch(error => {
                console.error("Erreur lors de la mise à jour :", error);
            });
    };

    const exportToExcel = () => {
        // Supposons que 'users' est un tableau contenant tous les détails des utilisateurs à exporter
        const usersData = users.map(user => {
            // Utilisez la déstructuration pour exclure les champs spécifiés
            const { password, resetPasswordToken, resetPasswordExpire, ...userWithoutSensitiveInfo } = user;
            if (userWithoutSensitiveInfo.createdAt) {
                userWithoutSensitiveInfo.createdAt = formatDate(userWithoutSensitiveInfo.createdAt);
            }
            if (userWithoutSensitiveInfo.updatedAt) {
                userWithoutSensitiveInfo.updatedAt = formatDate(userWithoutSensitiveInfo.updatedAt);
            }
            return userWithoutSensitiveInfo;
        });

        const ws = XLSX.utils.json_to_sheet(usersData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Utilisateurs");

        // Créer un nom de fichier basé sur la date actuelle
        const formatFileNameDate = new Date().toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).split('/').join('-');

        const fileName = `ppgaccompagnement-utilisateurs-${formatFileNameDate}.xlsx`;
        // Écrire le fichier
        XLSX.writeFile(wb, fileName);
    };



    return (
        <>
            <Navbar />
            <div style={{
                backgroundImage: 'url("/fond_login.jpeg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div style={{
                    width: '100%', // Ajustez la largeur selon vos besoins
                    maxWidth: '1200px', // Ajustez la largeur maximale selon vos besoins
                    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Fond blanc semi-transparent
                    padding: '20px', // Espace intérieur pour ne pas coller au bord
                    borderRadius: '10px', // Bords arrondis pour l'esthétique
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Ombre pour un effet de profondeur
                    overflowY: 'auto', // Permettre le défilement si le contenu dépasse la hauteur
                    boxSizing: 'border-box', // Inclure le padding dans le calcul de la hauteur
                    height: 'calc(100vh - 60px)', // Ajustez selon la hauteur de votre Navbar
                }}>
                    <div className="table-scroll-container">
                        <h1 className="filters-container font-bold mb-5">Liste des utilisateurs</h1>
                        <button
                            className="bouton-personnalise"
                            variant="contained"
                            color="primary"
                            onClick={exportToExcel}
                            style={{ marginTop: '20px' }}
                        >
                            Exporter vers Excel
                        </button>
                        {/* Barre de recherche */}
                        <div className="filters-container">
                            <input
                                type="text"
                                value={searchByRaisonSociale}
                                onChange={(e) => setSearchByRaisonSociale(e.target.value)}
                                placeholder="Raison sociale"
                            />
                            <input
                                type="text"
                                value={searchByEmail}
                                onChange={(e) => setSearchByEmail(e.target.value)}
                                placeholder="Email"
                            />
                            <input
                                type="text"
                                value={searchByPhone}
                                onChange={(e) => setSearchByPhone(e.target.value)}
                                placeholder="Téléphone"
                            />
                            <input
                                type="text"
                                value={searchByAddress}
                                onChange={(e) => setSearchByAddress(e.target.value)}
                                placeholder="Adresse"
                            />
                            <input
                                type="text"
                                value={searchByVille}
                                onChange={(e) => setSearchByVille(e.target.value)}
                                placeholder="Ville"
                            />
                            <div className="checkbox-container">

                                <label>
                                    <input
                                        type="checkbox"
                                        checked={searchByConseilStrategique}
                                        onChange={(e) => setSearchByConseilStrategique(e.target.checked)}
                                    /> Conseil Stratégique
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={searchByConseilSpecifique}
                                        onChange={(e) => setSearchByConseilSpecifique(e.target.checked)}
                                    /> Conseil Spécifique
                                </label>

                                <label>
                                    <input
                                        type="checkbox"
                                        checked={searchByCahierCulture}
                                        onChange={(e) => setSearchByCahierCulture(e.target.checked)}
                                    /> Cahier de Culture
                                </label>
                                <label>
                                    <TripleSwitch color="secondary" onChange={handleSwitchChange} />
                                </label>
                            </div>
                        </div>

                        <table className="user-table">
                            <thead>
                                <tr className="text-left bg-grey-200">
                                    <th className="px-4 py-2 font-semibold">Suppression</th>
                                    <th className="px-4 py-2 font-semibold">Fiche Client</th>
                                    <th className="px-4 py-2 font-semibold">Actif</th>
                                    <th className="px-4 py-2 font-semibold">Conseil Stratégique</th>
                                    <th className="px-4 py-2 font-semibold">Conseil Spécifique</th>
                                    <th className="px-4 py-2 font-semibold">Cahier de Culture</th>
                                    <th className="px-4 py-2 font-semibold">Raison Sociale</th>
                                    <th className="px-4 py-2 font-semibold">Ville</th>
                                    <th className="px-4 py-2 font-semibold">Code Postal</th>
                                    <th className="px-4 py-2 font-semibold">Téléphone</th>
                                    <th className="px-4 py-2 font-semibold">Email</th>

                                    {/* Ajoutez d'autres en-têtes si nécessaire */}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUsers.map(user => (
                                    <tr key={user.idUser} className="text-left border-b border-grey-200">
                                        <td className="px-4 py-2">
                                            <ThemeProvider theme={theme}>
                                                <Button variant="contained" color="secondary" onClick={() => handleOpenDialog(user.idUser)}>
                                                    Supprimer
                                                </Button>
                                            </ThemeProvider>
                                        </td>
                                        <ThemeProvider theme={theme}>
                                            <td className="px-4 py-2">
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => handleViewClient(user.idUser)}
                                                >
                                                    Fiche Client
                                                </Button>
                                            </td>
                                        </ThemeProvider>
                                        <ThemeProvider theme={theme}>
                                            <td className="px-4 py-2">
                                                <Switch
                                                    checked={user.active}
                                                    onChange={() => handleActiveChange(user.idUser, !user.active)}
                                                    disabled={disabledUserId === user.idUser}
                                                    color="primary"
                                                />
                                            </td>

                                            <td>
                                                <Switch
                                                    checked={user.conseilStrategique}
                                                    onChange={(e) => handleConseilStrategiqueChange(user.idUser, e.target.checked)}
                                                />
                                            </td>
                                            <td>
                                                <Switch
                                                    checked={user.conseilSpecifique}
                                                    onChange={(e) => handleConseilSpecifiqueChange(user.idUser, e.target.checked)}
                                                />
                                            </td>
                                            <td>
                                                <Switch
                                                    checked={user.cahierCulture}
                                                    onChange={(e) => handleCahierCultureChange(user.idUser, e.target.checked)}
                                                />
                                            </td>
                                        </ThemeProvider>
                                        <td className="px-4 py-2">{user.raisonSociale}</td>
                                        <td className="px-4 py-2">{user.ville}</td>
                                        <td className="px-4 py-2">{user.postalCode}</td>
                                        <td className="px-4 py-2">{user.telephone}</td>
                                        <td className="px-4 py-2">{user.email}</td>

                                        <td className="px-4 py-2">

                                        </td>
                                        {/* Ajoutez d'autres cellules pour les données supplémentaires */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                        <DialogTitle>Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog}>Annuler</Button>
                            <Button onClick={handleConfirmDelete} color="primary">
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </>
    );
}
