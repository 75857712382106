import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Typography, Switch, FormGroup, Button, FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle, Box, CssBaseline, Snackbar } from '@mui/material';
import Navbar from "../component/navbar.tsx";
import ArrowBack from "../component/flecheBack.tsx";
import { EditableField } from "../component/modifButton.tsx";
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function DetailsUser() {
    const [newPassword, setNewPassword] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [generatedPassword, setGeneratedPassword] = useState('');
    const [isOpen, setIsOpen] = useState(false); // État pour contrôler l'ouverture et la fermeture de la boîte de dialogue
    const { id } = useParams();
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleRoleChange = (isAdmin) => {
        const updatedRole = isAdmin ? 'admin' : 'user'; // Déterminez le nouveau rôle basé sur l'état du switch
        fetch(`${apiUrl}/user/${id}/role`, { // Correspond à la route définie dans votre backend
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ role: updatedRole })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la mise à jour du rôle');
                }
                return response.json();
            })
            .then(data => {
                setUserDetails(prev => ({ ...prev, role: data.role })); // Mise à jour de l'état local
                setSnackbarSeverity('success');
                setSnackbarMessage('Le rôle a été mis à jour avec succès');
                setSnackbarOpen(true);
            })
            .catch(error => {
                console.error("Erreur lors de la mise à jour du rôle :", error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur lors de la mise à jour du rôle');
                setSnackbarOpen(true);
            });
    };


    const handleClose = () => {
        setIsOpen(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleConfirm = () => {
        generateRandomPassword();
        setIsOpen(false); // Appel à la fonction pour générer le mot de passe aléatoire après confirmation
        // Transmet le nouveau mot de passe à la fonction handleChangePassword
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        // Utilisez directement l'ID pour récupérer les détails de l'utilisateur
        fetch(`${apiUrl}/user/users`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des détails de l\'utilisateur');
                }
                return response.json();
            })
            .then(data => {
                const userWithId = data.find(user => user.idUser === parseInt(id));
                setUserDetails(userWithId);
            })
            .catch(error => {
                console.error(error);
            });
    }, [id, navigate]);

    const validateField = (field, value) => {
        if (field === 'ville' || field === 'raisonSociale' || field === 'adresse') {
            // Retourne false si "ville" est nulle ou vide, true sinon
            return value != null && value.trim() !== '';
        }
        const validationRules = {
            postalCode: /^\d{5}$/,
            siret: /^\d{14}$/,
            intraCom: /^FR\d{11}$/,
            pacage: /^\d{9}$/,
            telephone: /^\d{10}$/,
            firstName: /^[a-zA-Z]+([ -][a-zA-Z]+)*$/,
            lastName: /^[a-zA-Z\s]+$/,
            email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        };

        // Vérifier si la règle de validation existe pour le champ donné
        if (validationRules.hasOwnProperty(field)) {
            // Retourne true si la valeur correspond à l'expression régulière, sinon false
            return validationRules[field].test(value);
        } else {
            // Aucune règle de validation n'est définie pour ce champ, donc on considère la validation comme passée
            return true;
        }
    };



    const handleCheckboxChange = (field, newValue, label) => {
        // Valide la nouvelle valeur
        if (!validateField(field, newValue)) {
            setSnackbarSeverity('error');
            setSnackbarMessage(`La valeur saisie pour le champ "${label}" est invalide.`);
            setSnackbarOpen(true);
            return; // Arrête la fonction si la validation échoue
        }

        // Continuer avec la mise à jour si la validation réussit
        const token = localStorage.getItem('token'); // Récupère le token
        fetch(`${apiUrl}/user/${id}/${field}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ [field]: newValue })
        })
            .then(response => response.json())
            .then(updatedUser => {
                setUserDetails(prevDetails => ({ ...prevDetails, [field]: newValue }));
                setSnackbarSeverity('success');
                setSnackbarMessage(`Le champ ${label} a été mis à jour avec succès`);
                setSnackbarOpen(true);
                // window.location.reload();
            })
            .catch(error => {
                console.error("Erreur lors de la mise à jour :", error);
                setSnackbarSeverity('error');
                setSnackbarMessage(`Erreur lors de la mise à jour du champ ${label}`);
                setSnackbarOpen(true);
            });
    };


    const generateRandomPassword = () => {
        const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowercase = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialChars = '!@#?&%';
        const allChars = uppercase + lowercase + numbers + specialChars;
        let newPassword = '';

        // Ajouter une majuscule
        newPassword += uppercase[Math.floor(Math.random() * uppercase.length)];

        // Ajouter une minuscule
        newPassword += lowercase[Math.floor(Math.random() * lowercase.length)];

        // Ajouter un chiffre
        newPassword += numbers[Math.floor(Math.random() * numbers.length)];

        // Ajouter un caractère spécial
        newPassword += specialChars[Math.floor(Math.random() * specialChars.length)];

        // Générer le reste du mot de passe
        for (let i = 0; i < 4; i++) {
            newPassword += allChars[Math.floor(Math.random() * allChars.length)];
        }

        // Mélanger les caractères pour obtenir un mot de passe aléatoire
        newPassword = newPassword.split('').sort(() => Math.random() - 0.5).join('');

        // Mettre à jour l'état avec le nouveau mot de passe généré
        setNewPassword(newPassword);
        setGeneratedPassword(newPassword);
        handleChangePassword(newPassword); // Appeler handleChangePassword avec le nouveau mot de passe
    };



    const handleChangePassword = (newPassword) => {
        // Envoyer une requête au serveur pour mettre à jour le mot de passe
        fetch(`${apiUrl}/user/${id}/password`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ newPassword }) // Utiliser le nouveau mot de passe reçu comme corps de la requête
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la modification du mot de passe');
                }
                // Mettre à jour l'état avec les nouvelles données utilisateur
                return response.json();
            })
            .then(updatedUser => {
                setUserDetails(prevDetails => ({ ...prevDetails, ...updatedUser }));
                // Réinitialiser le champ de saisie du nouveau mot de passe
                setNewPassword('');
            })
            .catch(error => {
                console.error("Erreur lors de la modification du mot de passe :", error);
                setSnackbarSeverity('error');
                setSnackbarMessage('Erreur lors de la modification du mot de passe');
                setSnackbarOpen(true);
            });
    };




    return (
        <>
            <Navbar />

            <div style={{
                backgroundImage: 'url("/fond_login.jpeg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Container component="main" maxWidth="md" sx={{ overflowY: 'auto', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CssBaseline />
                    <Box
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            padding: 2,
                            borderRadius: 2,
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Ajuste l'ombre pour un effet plus prononcé
                            border: '1px solid rgba(0, 0, 0, 0.12)', // Ajoute un léger contour pour mieux délimiter la case
                            maxWidth: '800px',
                            maxHeight: '700px',
                            width: '100%',
                            height: 'auto',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: 4, // Ajustez selon besoin pour positionner le Box dans la vue
                            marginBottom: 4, // Ajustez selon besoin pour positionner le Box dans la vue
                        }}
                    >
                        <ArrowBack />
                        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                        <Typography component="h1" variant="h5" sx={{ margin: '20px 0' }}>
                            Fiche : {userDetails.raisonSociale}
                        </Typography>
                        <FormControlLabel
                            control={<Switch checked={userDetails.role === 'admin'} onChange={(e) => handleRoleChange(e.target.checked)} />}
                            label="Admin"
                            style={{ marginBottom: '20px' }}
                        />
                        {userDetails && (

                            <FormGroup>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '100px', justifyContent: 'space-between', width: '100%' }}>
                                    {/* Première colonne */}
                                    <div style={{ flexBasis: 'calc(50% - 50px)' }}> {/* Ajustez le flexBasis pour contrôler la largeur de la colonne */}
                                        <EditableField
                                            label="Nom"
                                            value={userDetails.lastName || ''}
                                            onSave={(newValue) => handleCheckboxChange('lastName', newValue, 'nom')}
                                            textStyle={{ color: 'black', backgroundColor: 'white', width: '100%' }}
                                        />
                                        <EditableField
                                            label="Téléphone"
                                            value={userDetails.telephone || ''}
                                            onSave={(newValue) => handleCheckboxChange('telephone', newValue, 'téléphone')}
                                            textStyle={{ color: 'black', backgroundColor: 'white' }}
                                        />
                                        <EditableField
                                            label="Raison Sociale"
                                            value={userDetails.raisonSociale || ''}
                                            onSave={(newValue) => handleCheckboxChange('raisonSociale', newValue, 'raison sociale')}
                                            textStyle={{ color: 'black', backgroundColor: 'white' }}
                                        />
                                        <EditableField
                                            label="Code Postal"
                                            value={userDetails.postalCode || ''}
                                            onSave={(newValue) => handleCheckboxChange('postalCode', newValue, 'code postal')}
                                            textStyle={{ color: 'black', backgroundColor: 'white' }}
                                        />
                                        <EditableField
                                            label="Siret"
                                            value={userDetails.siret || ''}
                                            onSave={(newValue) => handleCheckboxChange('siret', newValue, 'siret')}
                                            textStyle={{ color: 'black', backgroundColor: 'white' }}
                                        />
                                        <EditableField
                                            label="Numéro Pacage"
                                            value={userDetails.pacage || ''}
                                            onSave={(newValue) => handleCheckboxChange('pacage', newValue, 'numéro de pacage')}
                                            textStyle={{ color: 'black', backgroundColor: 'white' }}
                                        />
                                    </div>


                                    {/* Seconde colonne */}
                                    <div style={{ flexBasis: 'calc(50% - 50px)' }}> {/* Ajustez le flexBasis pour contrôler la largeur de la colonne */}

                                        <EditableField
                                            label="Prénom"
                                            value={userDetails.firstName || ''}
                                            onSave={(newValue) => handleCheckboxChange('firstName', newValue, 'prénom')}
                                            textStyle={{ color: 'black', backgroundColor: 'white' }}
                                        />
                                        <EditableField
                                            label="Email"
                                            value={userDetails.email || ''}
                                            onSave={(newValue) => handleCheckboxChange('email', newValue, 'mail')}
                                            textStyle={{ color: 'black', backgroundColor: 'white' }}
                                        />
                                        <EditableField
                                            label="Ville"
                                            value={userDetails.ville || ''}
                                            onSave={(newValue) => handleCheckboxChange('ville', newValue, 'ville')}
                                            textStyle={{ color: 'black', backgroundColor: 'white' }}
                                        />
                                        <EditableField
                                            label="Adresse"
                                            value={userDetails.adresse || ''}
                                            onSave={(newValue) => handleCheckboxChange('adresse', newValue, 'adresse')}
                                            textStyle={{ color: 'black', backgroundColor: 'white' }}
                                        />
                                        <EditableField
                                            label="Numéro Intra-Communautaire"
                                            value={userDetails.intraCom || ''}
                                            onSave={(newValue) => handleCheckboxChange('intraCom', newValue, 'numéro intra-communautaire')}
                                            textStyle={{ color: 'black', backgroundColor: 'white' }}
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={!!userDetails.assujettiTVA} onChange={(e) => handleCheckboxChange('assujettiTVA', e.target.checked, 'assujetti à la TVA')} />}
                                            label="Assujetti TVA"
                                            sx={{ ml: "5px", mt: "10px" }}
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        )}

                        <FormGroup style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                                <EditableField
                                    label="Nouveau Mot de Passe"
                                    value={generatedPassword}
                                    onSave={handleChangePassword}
                                    textStyle={{ color: 'black', backgroundColor: 'white' }}
                                />
                                <button className="bouton-personnalise-2" onClick={() => setIsOpen(true)}>
                                    Générer un Mot de Passe Aléatoire
                                </button>
                                <Dialog open={isOpen} onClose={handleClose}>
                                    <DialogTitle>Confirmation</DialogTitle>
                                    <DialogContent>
                                        Êtes-vous sûr de vouloir générer un nouveau mot de passe ?
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose} color="primary">
                                            Annuler
                                        </Button>
                                        <Button onClick={handleConfirm} color="primary" autoFocus>
                                            Confirmer
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </FormGroup>
                    </Box>
                </Container>
            </div>
        </>

    );
}
