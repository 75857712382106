import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import Logout from './Logout.tsx';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual.js';
import Spinner from './spinner.tsx';


function Navbar() {
    const token = localStorage.getItem('token');
    const [userDetails, setUserDetails] = useState(null);
    const navigate = useNavigate();
    const isAdmin = userDetails && userDetails.role === 'admin';
    const isConseilSpecifique = userDetails && userDetails.conseilSpecifique;
    const isConseilStrategique = userDetails && userDetails.conseilStrategique;
    const isCahierCulture = userDetails && userDetails.cahierCulture;
    const [isArchiveCsp, setIsArchiveCsp] = useState(false);
    const [isArchiveCst, setIsArchiveCst] = useState(false);
    const [isArchiveCulture, setIsArchiveCulture] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;


    useEffect(() => {
        if (token) {
            const tokenBody = token.split('.')[1];
            const decodedToken = JSON.parse(window.atob(tokenBody));
            fetchUserData(decodedToken.id);
            fetchCspData(decodedToken.id);
            fetchCstData(decodedToken.id);
            fetchCultureData(decodedToken.id);
        }
    }, [token]);


    const fetchUserData = async (userId) => {
        try {
            const response = await fetch(`${apiUrl}/user/${userId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données utilisateur');
            }

            const userData = await response.json();
            setUserDetails(userData);

        } catch (error) {
            console.error('Erreur:', error);
        }
    };

    const fetchCspData = async (userId) => {
        try {
            const response = await fetch(`${apiUrl}/adviceDate/${userId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données utilisateur');
            }
            const data = await response.json();
            if (!isEqual(data.length, 0)) {
                setIsArchiveCsp(true);
            }
        } catch (error) {
            console.error('Erreur:', error);
        }
    };

    const fetchCstData = async (userId) => {
        try {
            const response = await fetch(`${apiUrl}/strategicDate/${userId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données utilisateur');
            }
            const data = await response.json();
            if (!isEqual(data.length, 0)) {
                setIsArchiveCst(true);
            }
        } catch (error) {
            console.error('Erreur:', error);
        }
    };

    const fetchCultureData = async (userId) => {
        try {
            const response = await fetch(`${apiUrl}/culture/${userId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données utilisateur');
            }
            const data = await response.json();
            if (!isEqual(data.length, 0)) {
                setIsArchiveCulture(true);
            }
        } catch (error) {
            console.error('Erreur:', error);
        }
    };


    if (!userDetails) {
        return <Spinner />;
    }

    return (
        <AppBar className='navbar' position="static" style={{ backgroundColor: isAdmin ? '#BF5207 ' : '#012705', width: '100%' }}>
            <Toolbar style={{ justifyContent: 'space-between' }}>
                {/* Logo à gauche */}
                <Button color="inherit" href="/home">
                    <img src="/logo.png" alt="Logo" style={{ height: 55 }} />
                </Button>

                {/* Conteneur central pour les éléments centrés */}
                <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
                    {!isAdmin && (isConseilSpecifique || isArchiveCsp) && <Button color="inherit" href={`/${userDetails.idUser}/conseilSpecifique/1`}>Conseil Spécifique</Button>}
                    {!isAdmin && (isCahierCulture || isArchiveCulture) && <Button color="inherit" href={`/${userDetails.idUser}/cahierCulture/1`}>Cahier de Culture</Button>}
                    {!isAdmin && (isConseilStrategique || isArchiveCst) && <Button color="inherit" href={`/${userDetails.idUser}/rapport/1`}>Conseil Stratégique</Button>}
                    {isAdmin && <Button color="inherit" href={`/cspAdmin`}>Conseil Spécifique</Button>}
                    {isAdmin && <Button color="inherit" href={`/cstAdmin`}>Conseil Stratégique</Button>}
                    {isAdmin && <Button color="inherit" href={`/cahierCultureAdmin`}>Cahier de culture</Button>}
                    {isAdmin && <Button color="inherit" href={`/listeUtilisateurs`}>Liste utilisateur</Button>}
                    {isAdmin && <Button color="inherit" href={`/upload`}>Upload CSV</Button>}
                    {!isAdmin && <Button color="inherit" href={'/profil'}>Profil</Button>}
                </Box>

                {/* Bouton de déconnexion à droite */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 0 }}>
                    <Logout />
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;
