import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from "../component/dynamicTable.tsx";
import Navbar from "../component/navbar.tsx";
import Commentary from "../component/commentaire.tsx";
import Title from "../component/title.tsx";
import Header from "../component/header.tsx";
import { Buffer } from "buffer";
import isEqual from 'lodash/isEqual';
import TabBar from "../component/tapBar.tsx";

export default function CahierCulture() {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token');
    const [dataTabSubstance, setDataTabSubstance] = useState([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: "" }]);
    const [dataTabImpasses, setDataTabImpasses] = useState([{ col_1: "", col_2: "", col_3: "", col_4: "" }]);
    const [commentaryResistance, setResistance] = useState("");
    const [needsSaveImpasses, setNeedsSaveImpasses] = useState(false);
    const [needsSaveSubstance, setNeedsSaveSubstance] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;


    const { id, idCst } = useParams();
    let idUser = null;
    let isAdmin = false;

    const tabs = [
        { label: 'Partie 1', path: `/${id}/rapport/${idCst}` },
        { label: 'Annexe(s)', path: `/${id}/annexe/${idCst}/1` },
        { label: 'Partie 2', path: `/${id}/rapport2/${idCst}` },
        { label: 'Partie 3', path: `/${id}/rapport3/${idCst}` },
    ];

    if (token) {
        const tokenBody = token.split('.')[1];
        if (tokenBody) {
            const decodedToken = JSON.parse(Buffer.from(tokenBody, 'base64').toString());
            idUser = decodedToken.id;
            isAdmin = decodedToken.admin;
        }
    }

    // ------------------ POST -----------------

    const saveDataToSubstance = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/substance/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveDataToImpasses = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/impasses/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };

    const saveResistance = async (newData) => {
        try {
            const userIdToUse = id || idUser;
            const response = await fetch(`${apiUrl}/resistance/post/${userIdToUse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
            });
            if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
        } catch (error) {
            setError(error.message);
        }
    };
    // --------------- CONST ----------------

    const handleDataChangeSubstance = useCallback((newData) => {
        if (!isEqual(dataTabSubstance, newData)) {
            setDataTabSubstance(newData);
            saveDataToSubstance(newData);
        }
    }, [dataTabSubstance, saveDataToSubstance]);



    const handleDataChangeImpasses = useCallback((newData) => {
        if (!isEqual(dataTabImpasses, newData)) {
            setDataTabImpasses(newData);
            saveDataToImpasses(newData);
        }
    }, [dataTabImpasses, saveDataToImpasses]);

    const handleDataChangeResistance = useCallback((newCommentaryData) => {
        if (!isEqual(commentaryResistance, newCommentaryData)) {
            setResistance(newCommentaryData);
            saveResistance(newCommentaryData);
        }
    }, [commentaryResistance, saveResistance]);

    useEffect(() => {
        if (needsSaveSubstance) {
            // Procéder à la sauvegarde des données
            saveDataToSubstance(dataTabSubstance)
                .then(() => setNeedsSaveSubstance(false)) // Réinitialiser l'indicateur après la sauvegarde
                .catch(error => {
                    console.error(error);
                    // Gérer l'erreur de sauvegarde si nécessaire
                });
        }
    }, [needsSaveSubstance]);

    useEffect(() => {
        if (needsSaveImpasses) {
            // Procéder à la sauvegarde des données
            saveDataToImpasses(dataTabImpasses)
                .then(() => setNeedsSaveImpasses(false)) // Réinitialiser l'indicateur après la sauvegarde
                .catch(error => {
                    console.error(error);
                    // Gérer l'erreur de sauvegarde si nécessaire
                });
        }
    }, [needsSaveImpasses]);

    // ------------------- GET -------------------

    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchSubstance = async () => {
            try {
                const response = await fetch(`${apiUrl}/Substance/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setDataTabSubstance(data.dataTab || []);
                if (!isEqual(dataTabSubstance, data)) {
                    setDataTabSubstance(data.dataTab);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchSubstance();
    }, [token, idUser, id, idCst]);


    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchImpasses = async () => {
            try {
                const response = await fetch(`${apiUrl}/Impasses/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setDataTabImpasses(data.dataTab || []);
                if (!isEqual(dataTabImpasses, data)) {
                    setDataTabImpasses(data.dataTab);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchImpasses();
    }, [token, idUser, id, idCst]);

    useEffect(() => {
        const userIdToUse = id || idUser;

        const fetchResistance = async () => {
            try {
                const response = await fetch(`${apiUrl}/resistance/${userIdToUse}/${idCst}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setResistance(data.commentaryData || "");
                if (!isEqual(commentaryResistance, data)) {
                    setResistance(data.commentaryData);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchResistance();
    }, [token, idUser, id, idCst]);

    // ---------------- ADD ROW ---------------------

    const addNewSubstanceRow = () => {
        const newRow = {
            col_1: "",
            col_2: "",
            col_3: "",
            col_4: "",
            col_5: ""
        };
        if (!Array.isArray(dataTabSubstance)) {
            setDataTabSubstance([newRow, newRow]);
            setNeedsSaveSubstance(true)
        } else {
            setDataTabSubstance([...dataTabSubstance, newRow]);
            setNeedsSaveSubstance(true)
        }
    };

    const addNewImpassesRow = () => {
        const newRow = {
            col_1: "",
            col_2: "",
            col_3: "",
            col_4: "",
        };
        if (!Array.isArray(dataTabImpasses)) {
            setDataTabImpasses([newRow, newRow]);
            setNeedsSaveImpasses(true)
        } else {
            setDataTabImpasses([...dataTabImpasses, newRow]);
            setNeedsSaveImpasses(true)
        }
    };

    useEffect(() => {
        if (!token) {
            navigate('/login');
            return;
        }
    }, [token, navigate]);

    const handlePrint = () => {
        window.print(); // Cette fonction déclenche l'impression du document
    };

    const printButtonStyle = {
        padding: '10px 20px',
        margin: '20px',
        backgroundColor: 'blue',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };


    return (
        <>
            <style>
                {`
          @media print {
            .print-hide, .MuiButton-root, .user-name-button, .bouton-personnalise, .bouton-personnalise-2, .navbar, .tapBar, .bouton-personnalise-4, .bouton-personnalise-3 {
              display: none !important; /* Cacher les boutons et autres éléments non désirés */
            }
          
            .dynamic-table, .dynamic-table-row {
              page-break-inside: avoid; /* Essayer d'éviter la coupure des tableaux */
            }

            .dynamic-table, .commentary {
              margin-bottom: 50px; /* Ajoutez plus d'espacement en bas de chaque tableau/commentaire */
            }

            .date-input-view {
              display: none; /* Masque l'input de date en mode impression */
            }
          
            .date-text-view {
              display: block !important; /* Affiche le texte de la date en mode impression */
              border: none; /* Supprime tout cadre qui pourrait être appliqué */
              background: transparent; /* Assure un fond transparent */
              padding: 0; /* Supprime le padding pour éviter tout espace inutile */
              margin: 0 auto; /* Centrage horizontal */
              font-size: 18px; /* Assure que la taille de la police est appropriée */
            }
          }
          
          /* Styles par défaut (pas en mode impression) */
          .date-input-view {
            display: block; /* Affiche l'input de date normalement */
          }
          
          .date-text-view {
            display: none; /* Masque le texte de la date normalement */
          }

          #tableauAdapte {
            font-size: 10px; /* Réduction de la taille de la police */
            transform: scale(0.8); /* Ajustement de la taille */
            transform-origin: top left; /* Origine de la transformation */
            /* Ajoutez d'autres styles d'adaptation ici */
          }
          
          }
        `}
            </style>

            <Navbar />
            <Header />

            <div className="relative">
            </div>
            <Title text="Conseil stratégique phytosanitaire" />
            <div className="tapBar">
                <TabBar tabs={tabs} />
            </div>
            <Title text="Recommandations prioritaires : " customStyle={{ backgroundColor: 'transparent' }} />
            <DynamicTable
                onDataChange={handleDataChangeSubstance}
                dataTab={dataTabSubstance}
                showDeleteRow={true}
                title="Les Substances actives utilisées sur l’exploitation ou utilisables candidates à la substitution ou à critères d’exclusion"
                columnNames={["SA", "Spé. commerciale", "Cultures concernées", "Cibles", "Date de fin si existante"]}
                editableAdmin
                name="substance"
            />
            {isAdmin && <button
                onClick={addNewSubstanceRow}
                className="bouton-personnalise"
            >
                Ajouter Ligne
            </button>}
            <DynamicTable
                onDataChange={handleDataChangeImpasses}
                showDeleteRow={true}
                dataTab={dataTabImpasses}
                title="Les futures impasses techniques : usages couvert par une seule SA "
                columnNames={["SA", "Spé. commerciale", "Cultures concernées", "Cibles"]}
                editableAdmin
                name="impasses"
            />
            {isAdmin && <button
                onClick={addNewImpassesRow}
                className="bouton-personnalise"
            >
                Ajouter Ligne
            </button>}

            <div style={{ marginBottom: '50px' }}>
                <Commentary onDataChange={handleDataChangeResistance} commentaryData={commentaryResistance} title="Gestion des résistances par cultures : " titleStyle="" placeholder={"À partir du bilan phyto faire analyse du programme "} editableAdmin />
            </div>
            <button
                onClick={() => navigate(`/${id}/rapport/${idCst}`)}
                className="print-hide"
                style={{
                    position: 'fixed', // Se fixe dans le coin inférieur gauche de l'écran
                    bottom: '20px', // À 20px du bas
                    left: '20px', // À 20px de la gauche (au lieu de `right`)
                    height: '50px', // Hauteur du bouton
                    width: '50px', // Largeur du bouton (ajustez la taille selon les besoins)
                    borderRadius: '50%', // Forme circulaire
                    backgroundColor: '#00b09b', // Couleur de fond (bleu primaire de Bootstrap par exemple)
                    color: 'white', // Couleur de la flèche
                    fontSize: '24px', // Taille du texte de la flèche/de l'icône
                    lineHeight: '55px', // Centre la flèche verticalement
                    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)', // Ombre optionnelle pour la profondeur
                    border: 'none', // Pas de bordure
                    cursor: 'pointer', // Change le curseur au survol
                }}
                aria-label="Naviguer vers la page suivante"
            >
                ↩
            </button>

            <button
                onClick={() => navigate(`/${id}/rapport3/${idCst}`)}
                className="print-hide"
                style={{
                    position: 'fixed', // Se fixe dans le coin inférieur droit de l'écran
                    bottom: '20px', // À 20px du bas
                    right: '20px', // À 20px de la droite
                    height: '50px', // Hauteur du bouton
                    width: '50px', // Largeur du bouton (ajustez la taille selon les besoins)
                    borderRadius: '50%', // Forme circulaire
                    backgroundColor: '#00b09b', // Couleur de fond (bleu primaire de Bootstrap par exemple)
                    color: 'white', // Couleur de la flèche
                    fontSize: '30px', // Taille du texte de la flèche/de l'icône
                    lineHeight: '55px', // Centre la flèche verticalement
                    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)', // Ombre optionnelle pour la profondeur
                    border: 'none', // Pas de bordure
                    cursor: 'pointer', // Change le curseur au survol
                }}
                aria-label="Naviguer vers la page suivante"
            >
                ↪
            </button>
            <button
                onClick={handlePrint}
                className="bouton-personnalise-2"
            >
                Imprimer
            </button>
        </>
    );
}


