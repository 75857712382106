import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from "../component/dynamicTable.tsx";
import Navbar from "../component/navbar.tsx";
import Commentary from "../component/commentaire.tsx";
import Title from "../component/title.tsx";
import Header from "../component/header.tsx";
import { Buffer } from "buffer";
import isEqual from 'lodash/isEqual';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import ArrowBack from "../component/flecheBack.tsx";


export default function ConseilStategique() {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState(null);
  const [dataTabIdCt, setDataTabIdCt] = useState([])
  const [dataTabFeatures, setDataTabFeatures] = useState([{ col_1: "", col_2: "", col_3: "", col_4: "", col_5: { type: "dropdown", value: "", options: ["Décideur", "Opérateur", "Conseil", "Vente", "Prestation de service"] }, col_6: "" }])
  const [dataTabProvider, setDataTabProvider] = useState([{ col_1: "", col_2: "", col_3: "", col_4: "" }])
  const [dataTabSurface, setDataTabSurface] = useState([{ col_1: "Surface agricole utile totale de l'exploitation :", col_2: "" }])
  const [dataTabCrop, setDataTabCrop] = useState([{ col_1: { type: "dropdown", value: "", options: ["Arboriculture", "Maraîchage", "Vigne ou Grande culture"] }, col_2: "", col_3: "", col_4: "", col_5: "", col_6: "" }]); // Initialiser avec un tableau vide
  const [dataTabEconomic, setDataTabEconomic] = useState([{ col_1: "CA année N-1 : ", col_2: "" }, { col_1: "CA année N : ", col_2: "" }])
  const [dataTabInfoEconomic, setDataTabInfoEconomic] = useState([{ col_1: "Personnel Permanent", col_2: "", col_3: "" }, { col_1: "Qualifié", col_2: "", col_3: "" }, { col_1: "Non Qualifié", col_2: "", col_3: "" }, { col_1: "Personnel Saisonnier", col_2: "", col_3: "" }, { col_1: "Qualifié", col_2: "", col_3: "" }, { col_1: "Non Qualifié", col_2: "", col_3: "" }])
  const [dataTabEquipment, setDataTabEquipment] = useState([{
    col_1: "",
    col_2: { checked: false },
    col_3: { checked: false },
    col_4: { checked: false },
    col_5: { checked: false }
  }])
  const [dataTabFight, setDataTabFight] = useState([{ col_1: "Choix des sites de culture", col_2: { checked: false }, col_3: "Date de semis/plantation", col_4: { checked: false } },
  { col_1: "Variétés résistantes", col_2: { checked: false }, col_3: "Densité de semis/plantation", col_4: { checked: false } },
  { col_1: "Qualité des semences et plants", col_2: { checked: false }, col_3: "Modalité d'ensemencement", col_4: { checked: false } },
  { col_1: "Culture de variété en mélanges", col_2: { checked: false }, col_3: "Préservation des auxiliaires présents", col_4: { checked: false } },
  { col_1: "Greffage", col_2: { checked: false }, col_3: "Effeuillage", col_4: { checked: false } },
  { col_1: "Limitation des risques de verse (GC)", col_2: { checked: false }, col_3: "Adaptation de la taille", col_4: { checked: false } }])

  const [dataTabFightEnv, setDataTabFightEnv] = useState([{ col_1: "Rotations des cultures", col_2: { checked: false }, col_3: "Paillage", col_4: { checked: false } },
  { col_1: "Assolement (forme/taille)", col_2: { checked: false }, col_3: "Gérer le micro climat sous abris", col_4: { checked: false } },
  { col_1: "Structure du sol", col_2: { checked: false }, col_3: "Agir sur les microorganismes de la rhizosphère", col_4: { checked: false } },
  { col_1: "Labour/Non Labour", col_2: { checked: false }, col_3: "Fertilisation", col_4: { checked: false } },
  { col_1: "Agroforesterie", col_2: { checked: false }, col_3: "Irrigation", col_4: { checked: false } },
  { col_1: "Couvert végétal (réduction advenctives)", col_2: { checked: false }, col_3: "Réservoir d'auxilliare", col_4: { checked: false } },
  { col_1: "Enherbement", col_2: { checked: false }, col_3: "", col_4: "" }])

  const [dataTabFightPest, setDataTabFightPest] = useState([{ col_1: "Gestion des débris végétaux", col_2: { checked: false }, col_3: "Solarisation", col_4: { checked: false } },
  { col_1: "Cultures intermédiaires", col_2: { checked: false }, col_3: "Auxillaires", col_4: { checked: false } },
  { col_1: "Faux semis", col_2: { checked: false }, col_3: "Plantes pièges", col_4: { checked: false } },
  { col_1: "Désinfection des sols à la vapeur", col_2: { checked: false }, col_3: "Hygiène des outils/locaux", col_4: { checked: false } },
  { col_1: "Biofumigation", col_2: { checked: false }, col_3: "", col_4: "" }])

  const [dataTabFightProcess, setDataTabFightProcess] = useState([{ col_1: "Désherbage mécanique en post levée", col_2: { checked: false }, col_3: "Perturbation des ravageurs par l'eau", col_4: { checked: false } },
  { col_1: "Désherbage mécanique des cultures pérennnes", col_2: { checked: false }, col_3: "Températures basses en post récoltes", col_4: { checked: false } },
  { col_1: "Désherbage thermique", col_2: { checked: false }, col_3: "Températures hautes sous abris (maladies/ravageurs)", col_4: { checked: false } },
  { col_1: "Dispositif anti insectes", col_2: { checked: false }, col_3: "Epamprage mécanique", col_4: { checked: false } },
  { col_1: "Capture en masse des ravageurs", col_2: { checked: false }, col_3: "Intervention physique sur végétal", col_4: { checked: false } }])

  const [dataTabFightBio, setDataTabFightBio] = useState([{ col_1: "Confusion sexuelle", col_2: { checked: false }, col_3: "Microorganismes contre pathogène", col_4: { checked: false } },
  { col_1: "Auxilliaires", col_2: { checked: false }, col_3: "Mycoherbicide", col_4: { checked: false } },
  { col_1: "Stimulation défense naturelle de la plante", col_2: { checked: false }, col_3: "Extraits de végétaux contre bio agresseur", col_4: { checked: false } },
  { col_1: "Microorganismes contre ravageurs", col_2: { checked: false }, col_3: "Lâcher de mâles stériles", col_4: { checked: false } },
  { col_1: "Nématodes contre ravageurs", col_2: { checked: false }, col_3: "", col_4: "" }])

  const [dataTabFightChemisty, setDataTabFightChemisty] = useState([{ col_1: "Réflexion sur la modulation de dose", col_2: { checked: false } }, { col_1: "Gestion des risques de résistances", col_2: { checked: false } }, { col_1: "Diminuer IFT", col_2: { checked: false } }])
  const [dataTabMoreInfo, setDataTabMoreInfo] = useState([{ col_1: "Cahier des charges de l'exploitation agricole : (PFI/GLOBALG.A.P./etc)" },
  { col_1: "" },
  { col_1: "Exploitation membre d'un collectif agro-environemental (GIEE, Dephy,...)" },
  { col_1: "" },
  { col_1: "Précisier si l'exploitation est engagée avec dans une démarche agro-environementale (MAE phytos/MAE biodiversité/ PSE/ certification niveau 2 / natura 2000 et autres)" },
  { col_1: "" },
  { col_1: "Commercialisation des produits issus de l'exploitation par culture:" },
  { col_1: "" }])

  const [dataTabInvestment, setDataTabInvestment] = useState([{ col_1: "Nulle", col_2: { checked: false }, col_3: "Faible", col_4: { checked: false } }, { col_1: "Moyenne", col_2: { checked: false }, col_3: "Importante", col_4: { checked: false } }])
  const token = localStorage.getItem('token');
  const { id, idCst } = useParams();
  const [validationQuizz, setValidationQuizz] = useState(false);
  const [isCst, setIsCst] = useState(false);
  const [needsSaveFeatures, setNeedsSaveFeatures] = useState(false);
  const [needsSaveProvider, setNeedsSaveProvider] = useState(false);
  const [needsSaveCrop, setNeedsSaveCrop] = useState(false);
  const [needsSaveEquipment, setNeedsSaveEquipment] = useState(false);


  const apiUrl = process.env.REACT_APP_API_URL;


  let idUser = null;
  let isAdmin = false;

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmValidation = async () => {
    setOpenDialog(false);
    const userIdToUse = id || idUser;
    navigate(`/${userIdToUse}/Rapport/${idCst}`);
    setValidationQuizz(true);
    saveValidationQuizz(true);
  };

  if (token) {
    const tokenBody = token.split('.')[1];
    if (tokenBody) {
      const decodedToken = JSON.parse(Buffer.from(tokenBody, 'base64').toString());
      idUser = decodedToken.id;
      isAdmin = decodedToken.admin;
    }
  }

  // ------------------------ START POST ARRAY -----------------------------

  const saveValidationQuizz = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/validationQuizz/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, validationQuizz: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToIdCt = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/idCt/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };


  const saveDataToFeatures = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/features/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };


  const saveDataToProvider = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/provider/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };




  const saveDataToSurface = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/surface/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };


  const saveDataToCrop = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/crop/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToEconomic = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/economic/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToInfoEconomic = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/infoEconomic/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };


  const saveDataToEquipment = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/equipment/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };


  const saveDataToFight = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/fight/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToFightEnv = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/fightEnv/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToFightPest = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/fightPest/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToFightProcess = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/fightProcess/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToFightBio = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/fightBio/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToFightChemisty = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/fightChemisty/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToMoreInfo = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/moreInfo/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  const saveDataToInvestment = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/investment/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData, idCst: idCst })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (needsSaveProvider) {
      // Procéder à la sauvegarde des données
      saveDataToProvider(dataTabProvider)
        .then(() => setNeedsSaveProvider(false)) // Réinitialiser l'indicateur après la sauvegarde
        .catch(error => {
          console.error(error);
          // Gérer l'erreur de sauvegarde si nécessaire
        });
    }
  }, [needsSaveProvider]);
  
  useEffect(() => {
    if (needsSaveCrop) {
      // Procéder à la sauvegarde des données
      saveDataToCrop(dataTabCrop)
        .then(() => setNeedsSaveCrop(false)) // Réinitialiser l'indicateur après la sauvegarde
        .catch(error => {
          console.error(error);
          // Gérer l'erreur de sauvegarde si nécessaire
        });
    }
  }, [needsSaveCrop]);

  useEffect(() => {
    if (needsSaveEquipment) {
      // Procéder à la sauvegarde des données
      saveDataToEquipment(dataTabEquipment)
        .then(() => setNeedsSaveEquipment(false)) // Réinitialiser l'indicateur après la sauvegarde
        .catch(error => {
          console.error(error);
          // Gérer l'erreur de sauvegarde si nécessaire
        });
    }
  }, [needsSaveEquipment]);

  useEffect(() => {
    if (needsSaveFeatures) {
      // Procéder à la sauvegarde des données
      saveDataToFeatures(dataTabFeatures)
        .then(() => setNeedsSaveFeatures(false)) // Réinitialiser l'indicateur après la sauvegarde
        .catch(error => {
          console.error(error);
          // Gérer l'erreur de sauvegarde si nécessaire
        });
    }
  }, [needsSaveFeatures]);
  // ----------------- HANDLE CHANGE FOR ARRAY -----------------------------


  const handleDataChangeIdCt = useCallback((newData) => {
    if (!isEqual(dataTabIdCt, newData)) {
      setDataTabIdCt(newData);
      saveDataToIdCt(newData);
    }
  }, [dataTabIdCt, saveDataToIdCt]);

  const handleDataChangeFeatures = useCallback((newData) => {
    if (!isEqual(dataTabFeatures, newData)) {
      setDataTabFeatures(newData);
      saveDataToFeatures(newData);
    }
  }, [dataTabFeatures, saveDataToFeatures]);

  const handleDataChangeProvider = useCallback((newData) => {
    if (!isEqual(dataTabProvider, newData)) {
      setDataTabProvider(newData);
      saveDataToProvider(newData);
    }
  }, [dataTabProvider, saveDataToProvider]);

  const handleDataChangeSurface = useCallback((newData) => {
    if (!isEqual(dataTabSurface, newData)) {
      setDataTabSurface(newData);
      saveDataToSurface(newData);
    }
  }, [dataTabSurface, saveDataToSurface]);

  const handleDataChangeCrop = useCallback((newData) => {
    if (!isEqual(dataTabCrop, newData)) {
      setDataTabCrop(newData);
      saveDataToCrop(newData);
    }
  }, [dataTabCrop, saveDataToCrop]);



  const handleDataChangeEconomic = useCallback((newData) => {
    if (!isEqual(dataTabEconomic, newData)) {
      setDataTabEconomic(newData);
      saveDataToEconomic(newData);
    }
  }, [dataTabEconomic, saveDataToEconomic]);

  const handleDataChangeInfoEconomic = useCallback((newData) => {
    if (!isEqual(dataTabInfoEconomic, newData)) {
      setDataTabInfoEconomic(newData);
      saveDataToInfoEconomic(newData);
    }
  }, [dataTabInfoEconomic, saveDataToInfoEconomic]);


  const handleDataChangeEquipment = useCallback((newData) => {
    if (!isEqual(dataTabEquipment, newData)) {
      setDataTabEquipment(newData);
      saveDataToEquipment(newData);
    }
  }, [dataTabEquipment, saveDataToEquipment]);

  const handleDataChangeFight = useCallback((newData) => {
    if (!isEqual(dataTabFight, newData)) {
      setDataTabFight(newData);
      saveDataToFight(newData);
    }
  }, [dataTabFight, saveDataToFight]);

  const handleDataChangeFightEnv = useCallback((newData) => {
    if (!isEqual(dataTabFightEnv, newData)) {
      setDataTabFightEnv(newData);
      saveDataToFightEnv(newData);
    }
  }, [dataTabFightEnv, saveDataToFightEnv]);

  const handleDataChangeFightPest = useCallback((newData) => {
    if (!isEqual(dataTabFightPest, newData)) {
      setDataTabFightPest(newData);
      saveDataToFightPest(newData);
    }
  }, [dataTabFightPest, saveDataToFightPest]);

  const handleDataChangeFightProcess = useCallback((newData) => {
    if (!isEqual(dataTabFightProcess, newData)) {
      setDataTabFightProcess(newData);
      saveDataToFightProcess(newData);
    }
  }, [dataTabFightProcess, saveDataToFightProcess]);

  const handleDataChangeFightBio = useCallback((newData) => {
    if (!isEqual(dataTabFightBio, newData)) {
      setDataTabFightBio(newData);
      saveDataToFightBio(newData);
    }
  }, [dataTabFightBio, saveDataToFightBio]);

  const handleDataChangeFightChemisty = useCallback((newData) => {
    if (!isEqual(dataTabFightChemisty, newData)) {
      setDataTabFightChemisty(newData);
      saveDataToFightChemisty(newData);
    }
  }, [dataTabFightChemisty, saveDataToFightChemisty]);

  const addNewCropRow = () => {
    const newRow = {
      col_1: { type: "dropdown", value: "", options: ["Arboriculture", "Maraîchage", "Vigne", "Grande culture"] },
      col_2: "",
      col_3: "",
      col_4: "",
      col_5: "",
      col_6: ""
    };
    if (!Array.isArray(dataTabCrop)) {
      setDataTabCrop([newRow, newRow]);
      setNeedsSaveCrop(true)
    } else {
      setDataTabCrop([...dataTabCrop, newRow]);
      setNeedsSaveCrop(true)
    }
  };

  const handleDataChangeMoreInfo = useCallback((newData) => {
    if (!isEqual(dataTabMoreInfo, newData)) {
      setDataTabMoreInfo(newData);
      saveDataToMoreInfo(newData);
    }
  }, [dataTabMoreInfo, saveDataToMoreInfo]);


  const handleDataChangeInvestment = useCallback((newData) => {
    if (!isEqual(dataTabInvestment, newData)) {
      setDataTabInvestment(newData);
      saveDataToInvestment(newData);
    }
  }, [dataTabInvestment, saveDataToInvestment]);


  //------------------GET USER-----------------

  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchUserData = async () => {
      try {
        const response = await fetch(`${apiUrl}/user/${userIdToUse}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données utilisateur');
        }

        const userData = await response.json();

        // Mappage des données utilisateur sur dataTabIdCt
        const assujettiTVA = userData.assujettiTVA ? "Oui" : "Non";
        const newDataTabIdCt = [
          { col_1: "Raison Sociale : ", col_2: userData.raisonSociale, col_3: "Siret : ", col_4: userData.siret },
          { col_1: "Nom, Prénom : ", col_2: `${userData.firstName} ${userData.lastName}`, col_3: "N° IntraCommunautaire : ", col_4: userData.intraCom },
          { col_1: "Adresse : ", col_2: userData.adresse, col_3: "Assujetti TVA : ", col_4: assujettiTVA },
          { col_1: "Code Postal : ", col_2: userData.postalCode, col_3: "N° PACAGE : ", col_4: userData.pacage },
          { col_1: "Email : ", col_2: userData.email, col_3: "Téléphone : ", col_4: userData.telephone },
          { col_1: "Ville : ", col_2: userData.ville, col_3: "", col_4: "" },

        ];

        setDataTabIdCt(newDataTabIdCt);

      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, [id, idUser]);


  // ----------------- START GET ARRAY ---------------------

  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchFeatures = async () => {
      try {
        const response = await fetch(`${apiUrl}/features/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabFeatures(data.dataTab || []);
        if (!isEqual(dataTabFeatures, data)) {
          setDataTabFeatures(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchFeatures();
  }, [token, idUser, id, idCst]);


  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchProvider = async () => {
      try {
        const response = await fetch(`${apiUrl}/provider/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabProvider(data.dataTab || []);

        if (!isEqual(dataTabProvider, data)) {
          setDataTabProvider(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchProvider();
  }, [token, idUser, id, idCst]);

  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchSurface = async () => {
      try {
        const response = await fetch(`${apiUrl}/surface/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabSurface(data.dataTab || []);
        if (!isEqual(dataTabSurface, data)) {
          setDataTabSurface(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchSurface();
  }, [token, idUser, id, idCst]);

  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchCrop = async () => {
      try {
        const response = await fetch(`${apiUrl}/crop/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabCrop(data.dataTab || []);
        if (!isEqual(dataTabCrop, data)) {
          setDataTabCrop(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCrop();
  }, [token, idUser, id, idCst]);


  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchEconomic = async () => {
      try {
        const response = await fetch(`${apiUrl}/economic/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabEconomic(data.dataTab || []);
        if (!isEqual(dataTabEconomic, data)) {
          setDataTabEconomic(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchEconomic();
  }, [token, idUser, id, idCst]);


  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchInfoEconomic = async () => {
      try {
        const response = await fetch(`${apiUrl}/infoEconomic/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabInfoEconomic(data.dataTab || []);
        if (!isEqual(dataTabInfoEconomic, data)) {
          setDataTabInfoEconomic(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchInfoEconomic();
  }, [token, idUser, id, idCst]);


  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchEquipment = async () => {
      try {
        const response = await fetch(`${apiUrl}/equipment/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabEquipment(data.dataTab || []);
        if (!isEqual(dataTabEquipment, data)) {
          setDataTabEquipment(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchEquipment();
  }, [token, idUser, id, idCst]);


  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchFight = async () => {
      try {
        const response = await fetch(`${apiUrl}/fight/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabFight(data.dataTab || []);
        if (!isEqual(dataTabFight, data)) {
          setDataTabFight(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchFight();
  }, [token, idUser, id, idCst]);


  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchFightEnv = async () => {
      try {
        const response = await fetch(`${apiUrl}/fightEnv/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabFightEnv(data.dataTab || []);
        if (!isEqual(dataTabFightEnv, data)) {
          setDataTabFightEnv(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchFightEnv();
  }, [token, idUser, id, idCst]);

  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchFightPest = async () => {
      try {
        const response = await fetch(`${apiUrl}/fightPest/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabFightPest(data.dataTab || []);
        if (!isEqual(dataTabFightPest, data)) {
          setDataTabFightPest(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchFightPest();
  }, [token, idUser, id, idCst]);

  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchFightProcess = async () => {
      try {
        const response = await fetch(`${apiUrl}/fightProcess/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabFightProcess(data.dataTab || []);
        if (!isEqual(dataTabFightProcess, data)) {
          setDataTabFightProcess(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchFightProcess();
  }, [token, idUser, id, idCst]);

  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchFightBio = async () => {
      try {
        const response = await fetch(`${apiUrl}/fightBio/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabFightBio(data.dataTab || []);
        if (!isEqual(dataTabFightBio, data)) {
          setDataTabFightBio(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchFightBio();
  }, [token, idUser, id, idCst]);

  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchFightChemisty = async () => {
      try {
        const response = await fetch(`${apiUrl}/fightChemisty/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabFightChemisty(data.dataTab || []);
        if (!isEqual(dataTabFightChemisty, data)) {
          setDataTabFightChemisty(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchFightChemisty();
  }, [token, idUser, id, idCst]);

  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchMoreInfo = async () => {
      try {
        const response = await fetch(`${apiUrl}/moreInfo/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabMoreInfo(data.dataTab || []);
        if (!isEqual(dataTabMoreInfo, data)) {
          setDataTabMoreInfo(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchMoreInfo();
  }, [token, idUser, id, idCst]);


  useEffect(() => {
    const userIdToUse = id || idUser;

    const fetchInvestment = async () => {
      try {
        const response = await fetch(`${apiUrl}/investment/${userIdToUse}/${idCst}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabInvestment(data.dataTab || []);
        if (!isEqual(dataTabInvestment, data)) {
          setDataTabInvestment(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchInvestment();
  }, [token, idUser, id, idCst]);

  // --------------------------------------

  useEffect(() => {

    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }
  }, [navigate]);


  useEffect(() => {
    const userIdToUse = id || idUser;
    const fetchUserByCulture = async () => {
      try {
        const response = await fetch(`${apiUrl}/user/cst/${userIdToUse}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setIsCst(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserByCulture();
  }, [token, idUser, id, isCst]);

  // Fonction pour gérer les changements des cases à cocher dans dataTabEquipment
  const handleCheckboxChange = (rowIndex, columnKey, checked) => {
    const updatedData = dataTabEquipment.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, [columnKey]: { ...row[columnKey], checked } };
      }
      return row;
    });
    setDataTabEquipment(updatedData);
  };

  const addNewEquipmentRow = () => {
    // Modèle pour une nouvelle ligne avec une case à cocher dans chaque colonne
    const newRow = {
      col_1: "",
      col_2: { checked: false },
      col_3: { checked: false },
      col_4: { checked: false },
      col_5: { checked: false }
    };
    if (!Array.isArray(dataTabEquipment)) {
      setDataTabEquipment([newRow, newRow]);
      setNeedsSaveEquipment(true)
    } else {
      setDataTabEquipment([...dataTabEquipment, newRow]);
      setNeedsSaveEquipment(true)
    }
  };


  const addNewFeaturesRow = () => {
    const newRow = {
      col_1: "",
      col_2: "",
      col_3: "",
      col_4: "",
      col_5: { type: "dropdown", value: "", options: ["Décideur", "Opérateur", "Conseil", "Vente", "Prestation de Service"] },
      col_6: ""
    };
    if (!Array.isArray(dataTabFeatures)) {
      setDataTabFeatures([newRow, newRow]);
      setNeedsSaveFeatures(true)
    } else {
      setDataTabFeatures([...dataTabFeatures, newRow]);
      setNeedsSaveFeatures(true)
    }
  }

  const addNewProviderRow = () => {
    const newRow = {
      col_1: "",
      col_2: "",
      col_3: "",
      col_4: "",
    };
    if (!Array.isArray(dataTabProvider)) {
      setDataTabProvider([newRow, newRow]);
      setNeedsSaveProvider(true)
    } else {
      setDataTabProvider([...dataTabProvider, newRow]);
      setNeedsSaveProvider(true)
    }
  }



  return (
    <>
      <Navbar />
      <Header />
      <ArrowBack />
      <Title text="Questionnaire conseil stratégique phytosanitaire" />
      <DynamicTable onDataChange={handleDataChangeIdCt} dataTab={dataTabIdCt} columnNames={[]} title="Identification de l'exploitation agricole" titleStyle={{ backgroundColor: '', textAlign: 'center', padding: '10px' }} boldFirstThirdColumns editableAdmin/>
      <Title text="Caractéristique du système d'exploitation" />
      <DynamicTable onDataChange={handleDataChangeFeatures} showDeleteRowUser={true} dataTab={dataTabFeatures} columnNames={["Qualité", "Nom", "Prénom", "N°", "Type", "Date d'expiration"]} title="Personnel détenant le certiphyto " titleStyle={{ backgroundColor: '', padding: '10px' }} name="features"/>
      <button
        onClick={addNewFeaturesRow}
        className="bouton-personnalise"
      >
        Ajouter Personnel
      </button>
      <DynamicTable onDataChange={handleDataChangeProvider}  showDeleteRowUser={true} dataTab={dataTabProvider} columnNames={["Inventions concernés", "Entreprise", "Agrement", "CF"]} title="Prestataire de service intervenant sur l'exploitation en lien avec l'utilisation des Produits Phytosanitaires" titleStyle={{ backgroundColor: '', padding: '10px' }} name="provider"/>
      <button
        onClick={addNewProviderRow}
        className="bouton-personnalise"
      >
        Ajouter Prestataire
      </button>
      <DynamicTable onDataChange={handleDataChangeMoreInfo} dataTab={dataTabMoreInfo} columnNames={[]} title="" LockFirstThirdFifthSeventhRow/>
      <Title text="Spécifité pédo-climatique de l'exploitation" />
      <DynamicTable onDataChange={handleDataChangeSurface} dataTab={dataTabSurface} columnNames={[]} title="" LockFirstThirdColumns/>
      <DynamicTable onDataChange={handleDataChangeCrop} showDeleteRowUser={true} dataTab={dataTabCrop} columnNames={["Qualité", "Parcelles Concernées", "Précédents culturaux", "Culture", "Surface", "Variété"]} title="Assolement : (année N et N-1 si campagne en cours)" name="crop"/>
      <button
        onClick={addNewCropRow}
        className="bouton-personnalise"
      >
        Ajouter Assolement
      </button>
      <Title text="Situation économique de l'exploitation agricole" />
      <DynamicTable onDataChange={handleDataChangeInvestment} showCheckboxes={true} dataTab={dataTabInvestment} columnNames={[]} title="Capacité a faire de nouveaux investissements?" titleStyle={{ backgroundColor: '', padding: '10px' }} LockFirstThirdColumns/>

      <DynamicTable onDataChange={handleDataChangeInfoEconomic} dataTab={dataTabInfoEconomic} columnNames={[".", "Année N-1", "Année N"]} title="" boldAndLockFirstFourthRows/>
      <DynamicTable
        onDataChange={handleDataChangeEquipment}
        dataTab={dataTabEquipment}
        columnNames={["Nom", "Tracteur", "Travail du Sol", "Pulvérisation", "Autres"]}
        showCheckboxes={true}
        handleCheckboxChange={handleCheckboxChange}
        showDeleteRowUser={true}
        title="Liste du matériel disponible"
        titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }}
        name="equipment"
      />
      <button
        onClick={addNewEquipmentRow}
        className="bouton-personnalise"
      >
        Ajouter Matériel
      </button>
      <Title text="Méthodes de lutte intgérée utlisée sur l'exploitation" />
      <DynamicTable onDataChange={handleDataChangeFight} showCheckboxes={true} dataTab={dataTabFight} columnNames={[]} title="A- favoriser son autodéfense : " titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} LockFirstThirdColumns/>
      <DynamicTable onDataChange={handleDataChangeFightEnv} showCheckboxes={true} dataTab={dataTabFightEnv} columnNames={[]} title="B- Agir sur l'environnement de la plantes : " titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} LockFirstThirdColumns/>
      <DynamicTable onDataChange={handleDataChangeFightPest} showCheckboxes={true} dataTab={dataTabFightPest} columnNames={[]} title="C- Agir sur le bioagresseur en dehors de sa phase néfaste : " titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} LockFirstThirdColumns/>
      <DynamicTable onDataChange={handleDataChangeFightProcess} showCheckboxes={true} dataTab={dataTabFightProcess} columnNames={[]} title="D- Procédés physiques : " titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} LockFirstThirdColumns/>
      <DynamicTable onDataChange={handleDataChangeFightBio} showCheckboxes={true} dataTab={dataTabFightBio} columnNames={[]} title="E- Procédés Biologiques : " titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} editableAdmin LockFirstThirdColumns />
      <DynamicTable onDataChange={handleDataChangeFightChemisty} showCheckboxes={true} dataTab={dataTabFightChemisty} columnNames={[]} title="F- Procédé chimique : " titleStyle={{ backgroundColor: '', padding: '10px', textAlign: 'center' }} LockFirstThirdColumns/>
      <div style={{ margin: '30px 0', textAlign: 'center' }}>
        {isCst &&
        <button onClick={handleOpenDialog} className="bouton-personnalise-4">
          Valider le questionnaire
        </button>}
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirmer la validation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir valider le questionnaire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button onClick={handleConfirmValidation} color="primary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

      <div className="container mx-auto relative z-10">
        <div className="flex flex-col items-center justify-center min-h-screen">
        </div>
      </div>


    </>
  );
}


