import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Si vous utilisez Material-UI

const ArrowBack = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <div>
            <Button startIcon={<ArrowBackIcon />} onClick={goBack}>
                Retour
            </Button>
            {/* Le reste de votre composant ici */}
        </div>
    );
};

export default ArrowBack;
