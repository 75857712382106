import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DynamicTable from "../component/dynamicTable.tsx";
import Navbar from "../component/navbar.tsx";
import Title from "../component/title.tsx";
import Header from "../component/header.tsx";
import { Buffer } from "buffer";
import isEqual from 'lodash/isEqual';
import animationData from '../loading.json'; 
import ArrowBack from "../component/flecheBack.tsx";
import Spinner from "../component/spinner.tsx";

interface DataRow {
    col_1: string | { checked: boolean; };
    [key: string]: any; // Représente d'autres colonnes dynamiquement
  }


export default function PlotInventory() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [needsSave, setNeedsSave] = useState(false);
  const [dataTabPlotInventory, setDataTabPlotInventory] = useState<DataRow[]>([
    { col_1: { checked: false }, col_2: "Bloc / Ilôt / Lieu-dit / Nom", col_3: "Commune", col_4: "Références cadastrales", col_5: "Culture", col_6: "Variétés", col_7: "Porte-greffe", col_8: "Nombre de plants / Ha", col_9: "Surface", col_10: "Date de plantation", col_11: "Irrigation" },
    { col_1: "", col_2: "", col_3: "", col_4: "", col_5: "", col_6: "", col_7: "", col_8: "", col_9: "", col_10: "", col_11: "" }
  ]);
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;

  let idUser = null;
  let isAdmin = false;

  if (token) {
    const tokenBody = token.split('.')[1];
    if (tokenBody) {
      const decodedToken = JSON.parse(Buffer.from(tokenBody, 'base64').toString());
      idUser = decodedToken.id;
      isAdmin = decodedToken.admin;
    }
  }

  // ------------- POST ------------

  const saveDataToPlotInventory = async (newData) => {
    try {
      const userIdToUse = id || idUser;
      const response = await fetch(`${apiUrl}/plotInventory/post/${userIdToUse}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ idUser: userIdToUse, dataTab: newData })
      });
      if (!response.ok) throw new Error('Erreur lors de la sauvegarde du tableau');
    } catch (error) {
      setError(error.message);
    }
  };

  // ------------- HANDLE ------------

  const handleDataChangePlotInventory = useCallback((newData) => {
    
      setDataTabPlotInventory(newData);
      saveDataToPlotInventory(newData);
    
  }, [dataTabPlotInventory, saveDataToPlotInventory]);

  // --------------- GET --------------

  useEffect(() => {
    const userIdToUse = id || idUser;
    setIsLoading(true);
    const fetchPlotInventory = async () => {
      try {
        const response = await fetch(`${apiUrl}/plotInventory/${userIdToUse}}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données');
        }
        const data = await response.json();
        setDataTabPlotInventory(data.dataTab || []);
        if (!isEqual(dataTabPlotInventory, data)) {
          setDataTabPlotInventory(data.dataTab);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPlotInventory();
  }, [token, idUser, id]);

  

  // useEffect(() => {
  //   // Vérifiez d'abord si dataTabPlotInventory est défini et non vide
  //   if (dataTabPlotInventory && dataTabPlotInventory.length > 0) {
  //     if (dataTabPlotInventory[0]?.col_1?.checked) {
  //       // Activer la numérotation logique
  //       const updatedData = dataTabPlotInventory.map((row, index) => ({
  //         ...row,
  //         col_1: index === 0 ? row.col_1 : String(index), // Conserver la case à cocher pour la première ligne, numéroter les autres
  //       }));
  //       setDataTabPlotInventory(updatedData);
  //       setNeedsSave(true)

  //     } else {
  //       // Désactiver la numérotation logique
  //       const updatedData = dataTabPlotInventory.map((row, index) => ({
  //         ...row,
  //         col_1: index === 0 ? row.col_1 : "", // Effacer la numérotation tout en conservant la case à cocher pour la première ligne
  //       }));
  //       setDataTabPlotInventory(updatedData);
  //       setNeedsSave(true)

  //     }
  //   }
  // }, [dataTabPlotInventory?.[0]?.col_1?.checked]);
  
  useEffect(() => {
    if (needsSave) {
      // Procéder à la sauvegarde des données
      saveDataToPlotInventory(dataTabPlotInventory)
        .then(() => setNeedsSave(false)) // Réinitialiser l'indicateur après la sauvegarde
        .catch(error => {
          console.error(error);
          // Gérer l'erreur de sauvegarde si nécessaire
        });
    }
  }, [needsSave]);


  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
  }, [token, navigate]);

  const handlePrint = () => {
    window.print(); // Cette fonction déclenche l'impression du document
  };


  const addNewPlotInventoryRow = () => {
    const oldRow = { col_1: { checked: false }, col_2: "Bloc / Ilôt / Lieu-dit / Nom", col_3: "Commune", col_4: "Références cadastrales", col_5: "Culture", col_6: "Variétés", col_7: "Porte-greffe", col_8: "Nombre de plants / Ha", col_9: "Surface", col_10: "Date de plantation", col_11: "Irrigation" };
    const newRow = {
      col_1: "",
      col_2: "",
      col_3: "",
      col_4: "",
      col_5: "",
      col_6: "",
      col_7: "",
      col_8: "",
      col_9: "",
      col_10: "",
      col_11: "",

    };
    const newData = Array.isArray(dataTabPlotInventory) ? [...dataTabPlotInventory, newRow] : [oldRow, newRow, newRow];
    if (dataTabPlotInventory && dataTabPlotInventory[0]?.col_1?.checked) {
        newData.forEach((row, idx) => {
          row.col_1 = idx === 0 ? row.col_1 : String(idx);
        });
      }
      setDataTabPlotInventory(newData);
    if (!dataTabPlotInventory) {
      setDataTabPlotInventory([oldRow, newRow, newRow]);
      setNeedsSave(true)
    } else {
      setDataTabPlotInventory([...dataTabPlotInventory, newRow]);
      setNeedsSave(true)
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      <style>
        {`
          @media print {
            .print-hide, .MuiButton-root, .user-name-button, .bouton-personnalise, .bouton-personnalise-2, .navbar, .bouton-personnalise-4, .bouton-personnalise-3 {
              display: none !important; /* Cacher les boutons et autres éléments non désirés */
            }
          
            .dynamic-table, .dynamic-table-row {
              page-break-inside: avoid; /* Essayer d'éviter la coupure des tableaux */
            }

            .dynamic-table, .commentary {
              margin-bottom: 50px; /* Ajoutez plus d'espacement en bas de chaque tableau/commentaire */
            }

            .date-input-view {
              display: none; /* Masque l'input de date en mode impression */
            }
          
            .date-text-view {
              display: block !important; /* Affiche le texte de la date en mode impression */
              border: none; /* Supprime tout cadre qui pourrait être appliqué */
              background: transparent; /* Assure un fond transparent */
              padding: 0; /* Supprime le padding pour éviter tout espace inutile */
              margin: 0 auto; /* Centrage horizontal */
              font-size: 18px; /* Assure que la taille de la police est appropriée */
            }
            .dynamic-table {
              font-size: 10px; /* Ajustez selon le besoin */
              transform: scale(0.99); /* Ajustez l'échelle selon le besoin */
              transform-origin: top left; /* Définit l'origine de la transformation */
            }
          }
          
          /* Styles par défaut (pas en mode impression) */
          .date-input-view {
            display: block; /* Affiche l'input de date normalement */
          }
          
          .date-text-view {
            display: none; /* Masque le texte de la date normalement */
          }

          #tableauAdapte {
            font-size: 10px; /* Réduction de la taille de la police */
            transform: scale(0.8); /* Ajustement de la taille */
            transform-origin: top left; /* Origine de la transformation */
            /* Ajoutez d'autres styles d'adaptation ici */
          }
          
          }
        `}
      </style>

      <Navbar />
      <Header />
      <ArrowBack />
      {isLoading && <Spinner />}
      <div className="relative">
        <Title text="Inventaire parcelle : " />
        <div style={{ overflowX: 'auto', marginBottom: '50px' }}>
          <DynamicTable
            onDataChange={handleDataChangePlotInventory}
            dontDeleteFirstRow = {true}
            dataTab={dataTabPlotInventory}
            title=""
            columnNames={["Traçabilité automatique (cocher si pas de traçabilité)"]}
            showCheckboxesInventory={true}
            name="plotInventory"
            LockFirstRow
          />
        </div>
        <button
          onClick={addNewPlotInventoryRow}
          className="bouton-personnalise"
        >
          Ajouter ligne
        </button>
      </div>
      <button
        onClick={handlePrint}
        className="bouton-personnalise-2"
      >
        Imprimer
      </button>
    </>
  );
}


