import React from 'react';

function Logo() {
  // Styles en ligne pour l'image de l'en-tête
  const headerImageStyle = {
    width: '15%',   // Réduire la largeur à 50% de la largeur du conteneur
    height: '10%', // Ajuster automatiquement la hauteur pour conserver le ratio
    display: 'block', // Assurez que l'image est un bloc pour centrer
    marginLeft: 'auto', // Centrer l'image horizontalement
    marginRight: 'auto'
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <img src="/logo.png" alt="En-tête" style={headerImageStyle} />
    </div>
  );
}

export default Logo;
